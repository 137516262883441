import { Transition } from "@headlessui/react";
import React, { ReactNode } from "react";
// import Transition from "./Transition";
import { IconX } from "./Icons/IconX";

type TTitle = {
  title: string;
  alignment?: "text-left" | "text-center" | "text-right";
};

interface IProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: TTitle;
  width?: number;
  showCloseIcon?: boolean;
}

export default function Modal({
  open,
  title = { title: "", alignment: "text-left" },
  children,
  width,
  showCloseIcon,
  onClose
}: IProps) {
  return (
    <div
      className={`${
        open
          ? "mx-auto fixed px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center max-w-lg"
          : ""
      } z-10 inset-x-0`}
      style={{ width: `${width}px` }}
    >
      <Transition
        show={open}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className={`flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0`}
          onClick={onClose}
        >
          <div className="fixed inset-0 bg-gray-500 opacity-50" />
        </div>
      </Transition>
      <Transition
        show={open}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="px-4 pt-5 pb-4 overflow-hidden transition-all transform bg-white rounded-lg shadow-xl sm:w-full sm:p-6"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
          style={{ width: `${width}px` }}
        >
          {showCloseIcon && (
            <div className="flex justify-end w-full">
              <IconX
                className="text-gray-400 cursor-pointer"
                onClick={onClose}
              />
            </div>
          )}
          <div className="w-full text-left">
            {title && (
              <h3
                className={`text-xl font-medium leading-6 text-gray-900 ${title.alignment}`}
                id="modal-headline"
              >
                {title.title}
              </h3>
            )}
            <div className="w-full mt-2">{children}</div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
