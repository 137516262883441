import React from "react";
import Tippy from "@tippyjs/react";
import { IconExclamation } from "src/components/Icons/IconExclamation";
import { IconTrendingUp } from "src/components/Icons/IconTrendingUp";
import { IconCheck } from "src/components/Icons/IconCheck";

interface IProps {
  visitorCount: number;
  targetSampleSize?: number | undefined | null;
}

export default function TestHealthPod({
  visitorCount,
  targetSampleSize
}: IProps) {
  const targetSize: number = targetSampleSize || 500;

  const halfSize = targetSize / 2;

  if (visitorCount < halfSize) {
    return (
      <Tippy
        content={`${targetSize} visitors is the minimum before you can be confident in your results. Remember to check your p-value as well.`}
        placement="right"
        maxWidth={200}
        className="text-center"
      >
        <div className="p-1 bg-red-100 rounded-full">
          <IconExclamation size={24} className="text-red-500" />
        </div>
      </Tippy>
    );
  } else if (visitorCount >= halfSize && visitorCount <= targetSize - 1) {
    return (
      <Tippy
        content="Getting better! You may be able to draw a conclusion from this data, but you should probably wait a little longer. Remember to check your p-value as well."
        placement="right"
        maxWidth={200}
        className="text-center"
      >
        <div className="p-1 bg-yellow-100 rounded-full">
          <IconTrendingUp size={24} className="text-yellow-500" />
        </div>
      </Tippy>
    );
  } else {
    return (
      <Tippy
        content="Great! There are enough visitors to be confident that the test is accurate. Remember to check your p-value as well."
        placement="right"
        maxWidth={200}
        className="text-center"
      >
        <div className="p-1 bg-green-100 rounded-full">
          <IconCheck size={24} className="text-green-500" />
        </div>
      </Tippy>
    );
  }
}
