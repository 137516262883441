import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { Fragment, useState } from "react";
import { Form } from "react-final-form";
import Button from "src/components/Button";
import Errors from "src/components/Errors";
import {
  IBillingSubscriptionAttributes,
  useCreateBillingSubscription
} from "src/components/graphql";
import { useNotification } from "src/contexts/NotificationContext";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

interface IProps {
  onSuccess: () => void;
}

export default function PaymentSetupForm({ onSuccess }: IProps) {
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const { setNotificationOpen, setCurrentNotification } = useNotification();

  const [createBillingSubscriptionMutation] = useCreateBillingSubscription();

  const createBillingSubscription = async (
    billingSubscription: IBillingSubscriptionAttributes
  ) => {
    const result = await createBillingSubscriptionMutation({
      variables: {
        billingSubscription
      },
      refetchQueries: ["FindCurrentUser"]
    });

    if (result.data?.createBillingSubscription?.stripeSubscription) {
      const stripeSubscription =
        result.data?.createBillingSubscription?.stripeSubscription;

      handlePaymentThatRequiresCustomerAction({
        stripeSubscription,
        priceId: result.data?.createBillingSubscription?.stripePriceId,
        paymentMethodId:
          result.data?.createBillingSubscription?.billingSubscription
            ?.stripePaymentMethodId
      });
    }
  };

  // This is for 3D secure payment stuff that I don't fully understand
  function handlePaymentThatRequiresCustomerAction({
    stripeSubscription,
    priceId,
    paymentMethodId
  }: any) {
    let setupIntent = stripeSubscription.pending_setup_intent;

    // Do 3D secure stuff if the setupIntent requiresAction
    // As far as I can tell, the setupIntent returns an ID and not an object like the docs say
    if (setupIntent && setupIntent.status === "requires_action") {
      return stripe!
        .confirmCardSetup(setupIntent.client_secret, {
          payment_method: paymentMethodId
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            setFormErrors([result.error.message!]);
            throw result;
          } else {
            if (result.setupIntent?.status === "succeeded") {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to setup_intent.succeeded.
              return {
                priceId: priceId,
                subscription: stripeSubscription,
                paymentMethodId: paymentMethodId
              };
            }
          }
        });
    } else {
      onSuccess();

      setNotificationOpen(true);
      setCurrentNotification({
        open: true,
        version: "success",
        title: "Thanks for using Preferr!",
        message: "Your subscription is now active."
      });
    }
  }

  const createPaymentMethod = async () => {
    const cardElement = elements!.getElement(CardElement)!;

    const paymentMethodResult = await stripe!.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    if (paymentMethodResult.error) {
      setFormErrors([paymentMethodResult.error.message!]);
    } else {
      await createBillingSubscription({
        stripePaymentMethodId: paymentMethodResult.paymentMethod!.id
      });
    }
  };

  return (
    <Fragment>
      {formErrors.length > 0 && <Errors errors={formErrors} className="mb-1" />}
      <Form
        onSubmit={createPaymentMethod}
        render={({ handleSubmit, submitting, pristine }) => (
          <form onSubmit={handleSubmit}>
            <div className="px-4 py-4 mb-4 rounded bg-gray-50">
              <CardElement
                onChange={(evt) => {
                  if (evt.complete) {
                    setSubmitEnabled(true);
                  }
                }}
                options={{
                  style: {
                    base: {
                      color: "#32325d",
                      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                      fontSmoothing: "antialiased",
                      fontSize: "16px",
                      "::placeholder": {
                        color: "#aab7c4"
                      }
                    },
                    invalid: {
                      color: "#fa755a",
                      iconColor: "#fa755a"
                    }
                  }
                }}
              />
            </div>
            <Button
              version="primary"
              type="submit"
              disabled={submitting || !submitEnabled}
              fullWidth
              className="mt-2"
              loading={submitting}
            >
              Subscribe
            </Button>
          </form>
        )}
      />
    </Fragment>
  );
}
