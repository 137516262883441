import React from "react";
import { TAction } from "src/definitions/TAction";
import Button, { TButtonVersion } from "./Button";
import { IconCheckCircle } from "./Icons/IconCheckCircle";
import { IconExclamation } from "./Icons/IconExclamation";
import { IconInfo } from "./Icons/IconInfo";
import { IconX } from "./Icons/IconX";
import { IconXCircle } from "./Icons/IconXCircle";

interface IProps {
  version?: "success" | "warning" | "info" | "error" | "plain";
  onDismiss?: () => void;
  title?: string;
  message?: string;
  className?: string;
  action?: TAction;
}

export default function Alert({
  version = "info",
  onDismiss,
  title,
  message,
  className,
  action
}: IProps) {
  let calculatedClassNames = "";
  let calculatedIcon;
  let calculatedTitleColor;
  let actionButtonVersion: TButtonVersion = "plain";

  switch (version) {
    case "success":
      calculatedIcon = <IconCheckCircle className="currentcolor" />;
      calculatedClassNames = calculatedClassNames.concat(
        " text-green-500 bg-green-50"
      );
      calculatedTitleColor = " text-green-800 ";
      actionButtonVersion = "success";
      break;
    case "info":
      calculatedIcon = <IconInfo className="currentcolor" />;
      calculatedClassNames = calculatedClassNames.concat(
        " text-blue-500 bg-blue-50"
      );
      calculatedTitleColor = " text-blue-800 ";
      actionButtonVersion = "primary";
      break;
    case "plain":
      calculatedClassNames = calculatedClassNames.concat(
        " text-gray-700 bg-gray-50"
      );
      calculatedTitleColor = " text-green-gray ";
      actionButtonVersion = "plain";
      break;
    case "error":
      calculatedIcon = <IconXCircle className="currentcolor" />;
      calculatedClassNames = calculatedClassNames.concat(
        " text-red-500 bg-red-50"
      );
      calculatedTitleColor = " text-green-red ";
      actionButtonVersion = "danger";
      break;
    case "warning":
      calculatedIcon = <IconExclamation className="currentcolor" />;
      calculatedClassNames = calculatedClassNames.concat(
        " text-yellow-500 bg-yellow-50"
      );
      calculatedTitleColor = " text-yellow-800 ";
      actionButtonVersion = "warning";
      break;
    default:
      break;
  }

  return (
    <div
      className={`flex justify-between rounded-md p-4 ${calculatedClassNames} ${className}`}
    >
      <div className="flex">
        <div className="flex-shrink-0 flex items-center mr-3">
          {calculatedIcon}
        </div>
        <div className="flex flex-col justify-center">
          <p
            className={`text-sm leading-5 font-medium ${calculatedTitleColor}`}
          >
            {title}
          </p>
          {onDismiss && (
            <div className="ml-auto pl-3">
              <div className="-mx-1.5 -my-1.5">
                <button
                  onClick={onDismiss}
                  className="inline-flex rounded-md p-1.5 text-green-500 hover:bg-green-100 focus:outline-none focus:bg-green-100 transition ease-in-out duration-150"
                >
                  <IconX />
                </button>
              </div>
            </div>
          )}
          {message && (
            <div className="mt-2">
              <p>{message}</p>
            </div>
          )}
        </div>
      </div>
      {action && (
        <Button onClick={action.onAction} version={actionButtonVersion}>
          {action.content}
        </Button>
      )}
    </div>
  );
}
