import React, { ReactNode } from "react";
import { TAction } from "src/definitions/TAction";
import Badge from "./Badge";

type TFooterAction = {
  placement: "left" | "right" | "center";
  action: TAction;
};

interface IProps {
  children?: ReactNode;
  className?: string;
  title?: string;
  titleBadge?: string;
  onTitleClick?: () => void;
  titleAction?: TAction;
  description?: string;
  footerAction?: TFooterAction;
}

export default function Card({
  children,
  className,
  title,
  titleBadge,
  onTitleClick,
  description,
  titleAction,
  footerAction
}: IProps) {
  const basic = !title && !description && !titleAction;

  const footerActionPlacement = () => {
    switch (footerAction?.placement) {
      case "left":
        return "justify-start";
      case "right":
        return "justify-end";
      case "center":
        return "justify-center";
    }
  };

  return (
    <div
      className={`bg-white overflow-hidden shadow sm:rounded-lg ${className}`}
    >
      {!basic && (
        <div className="px-6 py-4">
          <div className="flex justify-between items-center">
            {title && (
              <div className="flex items-center mb-2">
                <div
                  className={`text-2xl font-semibold mr-2 text-gray-900 ${
                    onTitleClick ? "cursor-pointer" : ""
                  }`}
                  onClick={onTitleClick}
                >
                  {title}
                </div>
                {titleBadge && (
                  <Badge
                    text={titleBadge}
                    className="bg-blue-100 text-blue-500"
                  />
                )}
              </div>
            )}
            {titleAction && (
              <div
                onClick={() => titleAction.onAction()}
                className="text-sm cursor-pointer mb-2"
              >
                {titleAction.content}
              </div>
            )}
          </div>
          {!!description && (
            <p className="text-gray-700 text-base">{description}</p>
          )}
        </div>
      )}
      {children && <div className="pt-4 px-6">{children}</div>}
      {footerAction && (
        <div className={`flex items-center ${footerActionPlacement}`}>
          {footerAction.action}
        </div>
      )}
    </div>
  );
}
