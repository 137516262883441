import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { IconChevronLeft } from "src/components/Icons/IconChevronLeft";
import Do from "./Do";
import Dont from "./Dont";

export default function DosDonts() {
  return (
    <Fragment>
      <h4 className="text-3xl mb-8">Do's and Don'ts</h4>
      <Do title="Do give your tests enough time">
        <p className="mb-4">
          An A/B test requires a a large number of samples to derive a
          meaningful conclusion, so be patient and give your tests some time to
          gather good data.
        </p>
      </Do>
      <Dont title="Don't change your child components">
        <p className="mb-4">
          Changing a child component after it's already been rendered to some of
          your visitors would significantly impact the meaning and validity of
          the data you receive.
        </p>
        <p className="mb-4">
          If you're making a small change to one or both of the children, it may
          be okay, but it's best to set it and forget it.
        </p>
      </Dont>
      <Do
        title="Do pay attention
          to your p-values"
      >
        <p className="mb-4">
          p-values are very important when evaluating your A/B tests. As the
          p-value approaches zero, you can be more confident that your test has
          statistically significant results rather than results that are random.
        </p>
        <p className="mb-4">
          Any p-value less than 0.05 can be considered statistically significant
          in most cases.
        </p>
      </Do>
      <Dont title="Don't assume that a low p-value confirms your hypothesis">
        <p className="mb-4">
          A low p-value doesn't necessarily mean that your experiment has been a
          success and that your challenger variant is better than your control.
        </p>
        <p className="mb-4">
          It's entirely possible that the experiment has confirmed the opposite
          and your challenger variant is worse than the control!
        </p>
        <p className="mb-4">
          While this may seem disappointing, remember that it just means your
          control is truly better than the challenger you tested, so you got it
          right the first time.
        </p>
      </Dont>

      <div className="flex justify-between items-center">
        <Link
          to="/documentation/identifying-visitors"
          className="bg-blue-100 py-2 px-4 rounded-lg"
        >
          <div className="flex items-center text-blue-900">
            <IconChevronLeft className="mr-2" />
            Identifying visitors
          </div>
        </Link>
      </div>
    </Fragment>
  );
}
