import { ApolloProvider } from "@apollo/client";
import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import apolloClient from "./apollo";
import { AuthProvider } from "./contexts/AuthContext";
import { NotificationProvider } from "./contexts/NotificationContext";
import Routes from "./Routes";
import "./styles/animations.css";
import "./styles/tailwind.css";

const App: React.FC = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <RecoilRoot>
        <AuthProvider>
          <NotificationProvider>
            <Router>
              <Routes />
            </Router>
          </NotificationProvider>
        </AuthProvider>
      </RecoilRoot>
    </ApolloProvider>
  );
};

export default App;
