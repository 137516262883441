import React, { useState, Fragment } from "react";

interface IProps {
  checked?: boolean;
  label?: string;
  helpText?: string;
  onChange?: (evt: any) => void;
}

export default function Toggle({ checked, label, helpText, onChange }: IProps) {
  const [isChecked, setIsChecked] = useState(checked);

  return (
    <Fragment>
      <label
        className={`block text-sm font-medium leading-5 text-gray-700 ${
          !label && "sr-only"
        }`}
      >
        {label}
      </label>
      <span
        role="checkbox"
        tabIndex={0}
        aria-checked="false"
        className={` ${
          isChecked ? "bg-blue-600" : "bg-gray-200"
        } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
        onClick={(e) => {
          setIsChecked(!isChecked);
          onChange && onChange(e);
        }}
      >
        <span
          aria-hidden="true"
          className={`${
            isChecked ? "translate-x-5" : "translate-x-0"
          } translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
        />
      </span>
      {helpText && <p className="mt-1 text-sm text-gray-500">{helpText}</p>}
    </Fragment>
  );
}

// import React, { useState, Fragment, useEffect } from "react";
// import { FieldRenderProps } from "react-final-form";

// interface IProps {
//   label?: string;
//   helpText?: string;
// }

// type TProps = IProps & FieldRenderProps<boolean | string, HTMLInputElement>;

// export default function Toggle(props: TProps) {
//   const { label, input, helpText } = props;
//   const [isChecked, setIsChecked] = useState(input.checked);

//   return (
//     <Fragment>
//       <label
//         className={`text-sm font-medium leading-5 text-gray-700 cursor-pointer flex items-center ${
//           !label && "sr-only"
//         }`}
//         onClick={() => setIsChecked(!isChecked)}
//       >
//         <span
//           className={` ${
//             isChecked ? "bg-blue-600" : "bg-gray-200"
//           } relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline`}
//         >
//           <span
//             aria-hidden="true"
//             className={`${
//               isChecked ? "translate-x-5" : "translate-x-0"
//             } translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200`}
//           />
//         </span>
//         <span className="ml-2">{label}</span>
//       </label>
//       {helpText && <p className="mt-1 text-sm text-gray-500">{helpText}</p>}
//     </Fragment>
//   );
// }
