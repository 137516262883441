import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "src/components/Button";
import { useListAbTestsQuery } from "src/components/graphql";
import { IconAddDocument } from "src/components/Icons/IconAddDocument";
import { IconOpenBook } from "src/components/Icons/IconOpenBook";
import Loader from "src/components/Loader";
import Segment from "src/components/Segment";
import SideSheet from "src/components/SideSheet";
import Url from "src/utils/Url";
import AbTestCard from "./AbTestCard";
import CreateAbTestForm from "./CreateAbTestForm";

export default function AbTests() {
  const [showAbTestForm, setShowAbTestForm] = useState(false);
  const [includeArchived, setIncludeArchived] = useState(false);
  const history = useHistory();

  const { loading, data } = useListAbTestsQuery({
    variables: {
      archived: includeArchived
    },
    fetchPolicy: "cache-and-network"
  });

  if (loading && !data) {
    return <Loader fullScreen />;
  }

  const abTests = data?.abTests.edges.map((edge) => edge.node);

  const hasAbTests = !!abTests?.length;

  const abTestsList = abTests?.map((abt) => (
    <div className="px-2" key={abt.id}>
      <AbTestCard abTest={abt} />
    </div>
  ));

  return (
    <Fragment>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        {hasAbTests ? (
          <Fragment>
            <div className="flex items-center justify-between mb-8">
              <Button onClick={() => setShowAbTestForm(true)}>
                New A/B Test
              </Button>
              <Button
                version={"plain"}
                onClick={() => setIncludeArchived(!includeArchived)}
                className="flex items-center justify-between mr-4"
              >
                Include Archived Tests{" "}
                <div className={`font-bold ml-2 text-gray-600`}>
                  {includeArchived ? "Yes" : "No"}
                </div>
              </Button>
            </div>
            <div className="grid gap-4 md:grid-cols-2 sm:grid-cols-1">
              {abTestsList}
            </div>
          </Fragment>
        ) : (
          <div className="grid grid-cols-2 gap-8">
            <Segment>
              <div className="flex">
                <IconOpenBook size={40} className="mr-4 text-gray-300" />
                <div>
                  <div className="mb-2 text-lg font-medium text-gray-800">
                    Learn the ropes
                  </div>
                  <div>
                    See how Preferr works and familiarize yourself with a few
                    core concepts before you get started.
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end mt-4">
                <Button
                  onClick={() =>
                    history.push(Url.documentationHowItWorksPath())
                  }
                >
                  Read the docs
                </Button>
              </div>
            </Segment>
            <Segment>
              <div className="flex">
                <IconAddDocument size={40} className="mr-4 text-gray-300" />
                <div>
                  <div className="mb-2 text-lg font-medium text-gray-800">
                    Create your first test
                  </div>
                  <div>
                    Already know what you're doing? Jump in and set up your
                    first A/B test.
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-end mt-4">
                <Button version="plain" onClick={() => setShowAbTestForm(true)}>
                  New A/B Test
                </Button>
              </div>
            </Segment>
          </div>
        )}
      </div>
      <SideSheet
        open={showAbTestForm}
        title="New A/B Test"
        onClose={() => setShowAbTestForm(false)}
      >
        <CreateAbTestForm onSuccess={() => setShowAbTestForm(false)} />
      </SideSheet>
    </Fragment>
  );
}
