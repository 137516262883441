import { gql } from "@apollo/client";
import * as ApolloReactCommon from "@apollo/client";
import * as ApolloReactHooks from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Represents date/time in iso8601 format */
  DateTime: string;
  /** Represents untyped JSON */
  JSON: any;
};

export type IAbTest = {
  __typename: "AbTest";
  activeThisMonth: Scalars["Boolean"];
  apiId: Scalars["String"];
  archived: Scalars["Boolean"];
  clickLift: Scalars["Float"];
  clickLiftPercentage: Scalars["Float"];
  conversionLift: Scalars["Float"];
  conversionLiftPercentage: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  discardedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  pValue: Scalars["Float"];
  pValueDescription: Scalars["String"];
  persistChild: Scalars["Boolean"];
  recordedUrl?: Maybe<Scalars["String"]>;
  sampleSize: Scalars["Int"];
  snippet: Scalars["String"];
  tags: Array<Scalars["String"]>;
  targetSampleSize: Scalars["Int"];
  totalImpressions: Scalars["Int"];
  totalVisitors: Scalars["Int"];
  uniqueClickLift: Scalars["Float"];
  uniqueClickLiftPercentage: Scalars["Float"];
  uniqueConversionLift: Scalars["Float"];
  uniqueConversionLiftPercentage: Scalars["Float"];
  variants: Array<IAbTestVariant>;
  winner: Scalars["String"];
};

/** Attributes for creating or updating an AbTest */
export type IAbTestAttributes = {
  name?: Maybe<Scalars["String"]>;
  targetSampleSize?: Maybe<Scalars["Int"]>;
};

/** The connection type for AbTest. */
export type IAbTestConnection = {
  __typename: "AbTestConnection";
  /** A list of edges. */
  edges: Array<IAbTestEdge>;
  /** A list of nodes. */
  nodes: Array<IAbTest>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IAbTestEdge = {
  __typename: "AbTestEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: IAbTest;
};

export type IAbTestVariant = {
  __typename: "AbTestVariant";
  abTestApiId: Scalars["String"];
  abTestId: Scalars["ID"];
  apiId: Scalars["String"];
  bounceRate: Scalars["Float"];
  bounceRatePercentage: Scalars["Float"];
  clickCount: Scalars["Int"];
  clickRate: Scalars["Float"];
  clickRatePercentage: Scalars["Float"];
  conversionCount: Scalars["Int"];
  conversionRate: Scalars["Float"];
  conversionRatePercentage: Scalars["Float"];
  externalStyleSheets: Array<Scalars["String"]>;
  id: Scalars["ID"];
  impressionCount: Scalars["Int"];
  markup?: Maybe<Scalars["String"]>;
  styles?: Maybe<Scalars["String"]>;
  uniqueClickCount: Scalars["Int"];
  uniqueClickRate: Scalars["Float"];
  uniqueClickRatePercentage: Scalars["Float"];
  uniqueConversionCount: Scalars["Int"];
  uniqueConversionRate: Scalars["Float"];
  uniqueConversionRatePercentage: Scalars["Float"];
  uniqueVisitorsCount: Scalars["Int"];
  variantDesignation: Scalars["String"];
  variantLetter: Scalars["String"];
  visitors: Array<IVisitor>;
};

/** Autogenerated input type of AcceptInvitation */
export type IAcceptInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
  token: Scalars["String"];
};

/** Autogenerated return type of AcceptInvitation */
export type IAcceptInvitationPayload = {
  __typename: "AcceptInvitationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  user?: Maybe<IUser>;
};

/** Autogenerated input type of ArchiveAbTest */
export type IArchiveAbTestInput = {
  abTestId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of ArchiveAbTest */
export type IArchiveAbTestPayload = {
  __typename: "ArchiveAbTestPayload";
  abTest?: Maybe<IAbTest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

export type IBillingSubscription = {
  __typename: "BillingSubscription";
  createdAt: Scalars["DateTime"];
  creditCardBrand: Scalars["String"];
  creditCardLast4: Scalars["String"];
  currentPeriodEnd: Scalars["DateTime"];
  currentPeriodStart: Scalars["DateTime"];
  id: Scalars["ID"];
  organizationId: Scalars["ID"];
  planName?: Maybe<Scalars["String"]>;
  status: Scalars["String"];
  stripeId: Scalars["String"];
  stripePaymentMethodId: Scalars["String"];
  upcomingInvoiceTotal: Scalars["Float"];
};

/** Attributes for creating or updating a billing subscription */
export type IBillingSubscriptionAttributes = {
  stripePaymentMethodId: Scalars["String"];
};

export type IConversion = {
  __typename: "Conversion";
  abTestVariantId: Scalars["ID"];
  id: Scalars["ID"];
  visitorId: Scalars["ID"];
};

/** The connection type for Conversion. */
export type IConversionConnection = {
  __typename: "ConversionConnection";
  /** A list of edges. */
  edges: Array<IConversionEdge>;
  /** A list of nodes. */
  nodes: Array<IConversion>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IConversionEdge = {
  __typename: "ConversionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: IConversion;
};

/** Autogenerated input type of CreateAbTest */
export type ICreateAbTestInput = {
  abTest: IAbTestAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of CreateAbTest */
export type ICreateAbTestPayload = {
  __typename: "CreateAbTestPayload";
  abTest?: Maybe<IAbTest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateBillingSubscription */
export type ICreateBillingSubscriptionInput = {
  billingSubscription: IBillingSubscriptionAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of CreateBillingSubscription */
export type ICreateBillingSubscriptionPayload = {
  __typename: "CreateBillingSubscriptionPayload";
  billingSubscription?: Maybe<IBillingSubscription>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  stripePriceId: Scalars["String"];
  stripeSubscription: Scalars["JSON"];
  success: Scalars["Boolean"];
};

/** Autogenerated input type of CreateUser */
export type ICreateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  user: IUserAttributes;
};

/** Autogenerated return type of CreateUser */
export type ICreateUserPayload = {
  __typename: "CreateUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  user?: Maybe<IUser>;
};

/** Autogenerated input type of DeleteAbTest */
export type IDeleteAbTestInput = {
  abTestId: Scalars["ID"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of DeleteAbTest */
export type IDeleteAbTestPayload = {
  __typename: "DeleteAbTestPayload";
  abTest?: Maybe<IAbTest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of DeleteUser */
export type IDeleteUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

/** Autogenerated return type of DeleteUser */
export type IDeleteUserPayload = {
  __typename: "DeleteUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  user?: Maybe<IUser>;
};

export type IMutation = {
  __typename: "Mutation";
  /** Accepts an invitation for an invited user */
  acceptInvitation?: Maybe<IAcceptInvitationPayload>;
  archiveAbTest?: Maybe<IArchiveAbTestPayload>;
  createAbTest?: Maybe<ICreateAbTestPayload>;
  /** Creates a billing subscription */
  createBillingSubscription?: Maybe<ICreateBillingSubscriptionPayload>;
  /** Creates a user */
  createUser?: Maybe<ICreateUserPayload>;
  deleteAbTest?: Maybe<IDeleteAbTestPayload>;
  deleteUser?: Maybe<IDeleteUserPayload>;
  /** Resends an invitation for an invited user */
  resendInvitation?: Maybe<IResendInvitationPayload>;
  /** Scrubs visitor data so that the visitor can no longer be identified */
  scrubVisitor?: Maybe<IScrubVisitorPayload>;
  updateAbTest?: Maybe<IUpdateAbTestPayload>;
  /** Updates an organization */
  updateOrganization?: Maybe<IUpdateOrganizationPayload>;
  /** Updates the payment method used for billing subscriptions on Stripe */
  updatePaymentMethod?: Maybe<IUpdatePaymentMethodPayload>;
  /** Updates a user */
  updateUser?: Maybe<IUpdateUserPayload>;
};

export type IMutationacceptInvitationArgs = {
  input: IAcceptInvitationInput;
};

export type IMutationarchiveAbTestArgs = {
  input: IArchiveAbTestInput;
};

export type IMutationcreateAbTestArgs = {
  input: ICreateAbTestInput;
};

export type IMutationcreateBillingSubscriptionArgs = {
  input: ICreateBillingSubscriptionInput;
};

export type IMutationcreateUserArgs = {
  input: ICreateUserInput;
};

export type IMutationdeleteAbTestArgs = {
  input: IDeleteAbTestInput;
};

export type IMutationdeleteUserArgs = {
  input: IDeleteUserInput;
};

export type IMutationresendInvitationArgs = {
  input: IResendInvitationInput;
};

export type IMutationscrubVisitorArgs = {
  input: IScrubVisitorInput;
};

export type IMutationupdateAbTestArgs = {
  input: IUpdateAbTestInput;
};

export type IMutationupdateOrganizationArgs = {
  input: IUpdateOrganizationInput;
};

export type IMutationupdatePaymentMethodArgs = {
  input: IUpdatePaymentMethodInput;
};

export type IMutationupdateUserArgs = {
  input: IUpdateUserInput;
};

export type IOrganization = {
  __typename: "Organization";
  activeAbTestsCount: Scalars["Int"];
  apiKey: Scalars["String"];
  billingEmail?: Maybe<Scalars["String"]>;
  billingSetup: Scalars["Boolean"];
  billingSubscription?: Maybe<IBillingSubscription>;
  earlyAdopter: Scalars["Boolean"];
  id: Scalars["ID"];
  name: Scalars["String"];
  outOfTrial: Scalars["Boolean"];
  trialDaysRemaining: Scalars["Int"];
  trialEndsOn: Scalars["DateTime"];
};

/** Information about pagination in a connection. */
export type IPageInfo = {
  __typename: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]>;
};

/** Root schema */
export type IQuery = {
  __typename: "Query";
  findAbTest?: Maybe<IAbTest>;
  findAbTestVariant?: Maybe<IAbTestVariant>;
  findCurrentUser: IUser;
  findOrganization: IOrganization;
  listAbTests: IAbTestConnection;
  listUsers: Array<IUser>;
  listVisitorConversions: IConversionConnection;
  listVisitors: IVisitorConnection;
};

/** Root schema */
export type IQueryfindAbTestArgs = {
  apiId: Scalars["String"];
};

/** Root schema */
export type IQueryfindAbTestVariantArgs = {
  apiId: Scalars["String"];
};

/** Root schema */
export type IQueryfindOrganizationArgs = {
  id: Scalars["ID"];
};

/** Root schema */
export type IQuerylistAbTestsArgs = {
  after?: Maybe<Scalars["String"]>;
  archived?: Maybe<Scalars["Boolean"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
};

/** Root schema */
export type IQuerylistVisitorConversionsArgs = {
  abTestVariantId?: Maybe<Scalars["ID"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  visitorApiId: Scalars["String"];
};

/** Root schema */
export type IQuerylistVisitorsArgs = {
  abTestVariantId?: Maybe<Scalars["ID"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  last?: Maybe<Scalars["Int"]>;
  name?: Maybe<Scalars["String"]>;
  q?: Maybe<Scalars["String"]>;
};

/** Autogenerated input type of ResendInvitation */
export type IResendInvitationInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  userId: Scalars["ID"];
};

/** Autogenerated return type of ResendInvitation */
export type IResendInvitationPayload = {
  __typename: "ResendInvitationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  user?: Maybe<IUser>;
};

/** Autogenerated input type of ScrubVisitor */
export type IScrubVisitorInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  visitorId: Scalars["ID"];
};

/** Autogenerated return type of ScrubVisitor */
export type IScrubVisitorPayload = {
  __typename: "ScrubVisitorPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  visitor?: Maybe<IVisitor>;
};

/** Autogenerated input type of UpdateAbTest */
export type IUpdateAbTestInput = {
  abTest: IAbTestAttributes;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
};

/** Autogenerated return type of UpdateAbTest */
export type IUpdateAbTestPayload = {
  __typename: "UpdateAbTestPayload";
  abTest?: Maybe<IAbTest>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdateOrganization */
export type IUpdateOrganizationInput = {
  billingEmail: Scalars["String"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of UpdateOrganization */
export type IUpdateOrganizationPayload = {
  __typename: "UpdateOrganizationPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  organization?: Maybe<IOrganization>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdatePaymentMethod */
export type IUpdatePaymentMethodInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  stripePaymentMethodId: Scalars["String"];
};

/** Autogenerated return type of UpdatePaymentMethod */
export type IUpdatePaymentMethodPayload = {
  __typename: "UpdatePaymentMethodPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
};

/** Autogenerated input type of UpdateUser */
export type IUpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  user: IUserAttributes;
};

/** Autogenerated return type of UpdateUser */
export type IUpdateUserPayload = {
  __typename: "UpdateUserPayload";
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars["String"]>;
  errors: Array<Scalars["String"]>;
  success: Scalars["Boolean"];
  user?: Maybe<IUser>;
};

export type IUser = {
  __typename: "User";
  apiKey: Scalars["String"];
  authenticationToken: Scalars["String"];
  email: Scalars["String"];
  id: Scalars["ID"];
  invited: Scalars["Boolean"];
  invitedAt?: Maybe<Scalars["DateTime"]>;
  name?: Maybe<Scalars["String"]>;
  nameOrEmail: Scalars["String"];
  organization: IOrganization;
  role: Scalars["String"];
};

export type IUserAttributes = {
  email?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  role?: Maybe<Scalars["String"]>;
};

export type IVisitor = {
  __typename: "Visitor";
  apiId: Scalars["String"];
  clickCount: Scalars["Int"];
  conversionCount: Scalars["Int"];
  email?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  impressionCount: Scalars["Int"];
  lastVisitedDate?: Maybe<Scalars["DateTime"]>;
  meta: Scalars["JSON"];
  name?: Maybe<Scalars["String"]>;
  scrubbed: Scalars["Boolean"];
  uid?: Maybe<Scalars["String"]>;
};

/** The connection type for Visitor. */
export type IVisitorConnection = {
  __typename: "VisitorConnection";
  /** A list of edges. */
  edges: Array<IVisitorEdge>;
  /** A list of nodes. */
  nodes: Array<IVisitor>;
  /** Information to aid in pagination. */
  pageInfo: IPageInfo;
  totalCount: Scalars["Int"];
};

/** An edge in a connection. */
export type IVisitorEdge = {
  __typename: "VisitorEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"];
  /** The item at the end of the edge. */
  node: IVisitor;
};

export type IAbTestFragment = { __typename: "AbTest" } & Pick<
  IAbTest,
  | "id"
  | "apiId"
  | "createdAt"
  | "snippet"
  | "tags"
  | "name"
  | "persistChild"
  | "totalVisitors"
  | "totalImpressions"
  | "conversionLift"
  | "conversionLiftPercentage"
  | "clickLift"
  | "clickLiftPercentage"
  | "uniqueConversionLift"
  | "uniqueConversionLiftPercentage"
  | "uniqueClickLift"
  | "uniqueClickLiftPercentage"
  | "activeThisMonth"
  | "winner"
  | "recordedUrl"
  | "pValue"
  | "pValueDescription"
  | "archived"
  | "discardedAt"
  | "targetSampleSize"
> & {
    variants: Array<{ __typename: "AbTestVariant" } & IAbTestVariantFragment>;
  };

export type IAbTestVariantFragment = { __typename: "AbTestVariant" } & Pick<
  IAbTestVariant,
  | "id"
  | "apiId"
  | "abTestId"
  | "abTestApiId"
  | "variantLetter"
  | "variantDesignation"
  | "markup"
  | "styles"
  | "externalStyleSheets"
  | "clickCount"
  | "uniqueClickCount"
  | "uniqueVisitorsCount"
  | "impressionCount"
  | "conversionCount"
  | "uniqueConversionCount"
  | "clickRate"
  | "clickRatePercentage"
  | "uniqueClickRate"
  | "uniqueClickRatePercentage"
  | "conversionRate"
  | "conversionRatePercentage"
  | "uniqueConversionRate"
  | "uniqueConversionRatePercentage"
  | "bounceRate"
  | "bounceRatePercentage"
>;

export type IBillingSubscriptionFragment = {
  __typename: "BillingSubscription";
} & Pick<
  IBillingSubscription,
  | "id"
  | "organizationId"
  | "stripeId"
  | "stripePaymentMethodId"
  | "status"
  | "currentPeriodStart"
  | "currentPeriodEnd"
  | "creditCardBrand"
  | "creditCardLast4"
  | "planName"
  | "upcomingInvoiceTotal"
>;

export type IConversionFragment = { __typename: "Conversion" } & Pick<
  IConversion,
  "id" | "abTestVariantId" | "visitorId"
>;

export type IOrganizationFragment = { __typename: "Organization" } & Pick<
  IOrganization,
  | "id"
  | "name"
  | "apiKey"
  | "activeAbTestsCount"
  | "earlyAdopter"
  | "billingEmail"
  | "billingSetup"
  | "trialEndsOn"
  | "trialDaysRemaining"
  | "outOfTrial"
> & {
    billingSubscription?: Maybe<
      { __typename: "BillingSubscription" } & IBillingSubscriptionFragment
    >;
  };

export type IUserFragment = { __typename: "User" } & Pick<
  IUser,
  | "id"
  | "email"
  | "name"
  | "nameOrEmail"
  | "role"
  | "authenticationToken"
  | "apiKey"
  | "invited"
  | "invitedAt"
> & { organization: { __typename: "Organization" } & IOrganizationFragment };

export type IVisitorFragment = { __typename: "Visitor" } & Pick<
  IVisitor,
  | "id"
  | "apiId"
  | "uid"
  | "name"
  | "email"
  | "meta"
  | "lastVisitedDate"
  | "impressionCount"
  | "conversionCount"
  | "clickCount"
  | "scrubbed"
>;

export type IAcceptInvitationMutationVariables = Exact<{
  token: Scalars["String"];
  password: Scalars["String"];
}>;

export type IAcceptInvitationMutation = { __typename: "Mutation" } & {
  acceptInvitation?: Maybe<
    { __typename: "AcceptInvitationPayload" } & Pick<
      IAcceptInvitationPayload,
      "success" | "errors"
    > & {
        user?: Maybe<
          { __typename: "User" } & Pick<
            IUser,
            "id" | "email" | "authenticationToken"
          >
        >;
      }
  >;
};

export type IArchiveAbTestMutationVariables = Exact<{
  abTestId: Scalars["ID"];
}>;

export type IArchiveAbTestMutation = { __typename: "Mutation" } & {
  archiveAbTest?: Maybe<
    { __typename: "ArchiveAbTestPayload" } & Pick<
      IArchiveAbTestPayload,
      "success" | "errors"
    > & {
        abTest?: Maybe<
          { __typename: "AbTest" } & Pick<
            IAbTest,
            "id" | "apiId" | "snippet" | "tags"
          >
        >;
      }
  >;
};

export type ICreateAbTestMutationVariables = Exact<{
  abTest: IAbTestAttributes;
}>;

export type ICreateAbTestMutation = { __typename: "Mutation" } & {
  createAbTest?: Maybe<
    { __typename: "CreateAbTestPayload" } & Pick<
      ICreateAbTestPayload,
      "success" | "errors"
    > & {
        abTest?: Maybe<
          { __typename: "AbTest" } & Pick<
            IAbTest,
            "id" | "apiId" | "snippet" | "tags"
          >
        >;
      }
  >;
};

export type ICreateBillingSubscriptionMutationVariables = Exact<{
  billingSubscription: IBillingSubscriptionAttributes;
}>;

export type ICreateBillingSubscriptionMutation = { __typename: "Mutation" } & {
  createBillingSubscription?: Maybe<
    { __typename: "CreateBillingSubscriptionPayload" } & Pick<
      ICreateBillingSubscriptionPayload,
      "success" | "errors" | "stripeSubscription" | "stripePriceId"
    > & {
        billingSubscription?: Maybe<
          { __typename: "BillingSubscription" } & IBillingSubscriptionFragment
        >;
      }
  >;
};

export type ICreateUserMutationVariables = Exact<{
  user: IUserAttributes;
}>;

export type ICreateUserMutation = { __typename: "Mutation" } & {
  createUser?: Maybe<
    { __typename: "CreateUserPayload" } & Pick<
      ICreateUserPayload,
      "success" | "errors"
    > & { user?: Maybe<{ __typename: "User" } & IUserFragment> }
  >;
};

export type IDeleteAbTestMutationVariables = Exact<{
  abTestId: Scalars["ID"];
}>;

export type IDeleteAbTestMutation = { __typename: "Mutation" } & {
  deleteAbTest?: Maybe<
    { __typename: "DeleteAbTestPayload" } & Pick<
      IDeleteAbTestPayload,
      "success" | "errors"
    > & {
        abTest?: Maybe<
          { __typename: "AbTest" } & Pick<
            IAbTest,
            "id" | "apiId" | "snippet" | "tags"
          >
        >;
      }
  >;
};

export type IDeleteUserMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type IDeleteUserMutation = { __typename: "Mutation" } & {
  deleteUser?: Maybe<
    { __typename: "DeleteUserPayload" } & Pick<
      IDeleteUserPayload,
      "success" | "errors"
    > & { user?: Maybe<{ __typename: "User" } & Pick<IUser, "id" | "email">> }
  >;
};

export type IResendInvitationMutationVariables = Exact<{
  userId: Scalars["ID"];
}>;

export type IResendInvitationMutation = { __typename: "Mutation" } & {
  resendInvitation?: Maybe<
    { __typename: "ResendInvitationPayload" } & Pick<
      IResendInvitationPayload,
      "success" | "errors"
    > & { user?: Maybe<{ __typename: "User" } & IUserFragment> }
  >;
};

export type IScrubVisitorMutationVariables = Exact<{
  visitorId: Scalars["ID"];
}>;

export type IScrubVisitorMutation = { __typename: "Mutation" } & {
  scrubVisitor?: Maybe<
    { __typename: "ScrubVisitorPayload" } & Pick<
      IScrubVisitorPayload,
      "success" | "errors"
    > & { visitor?: Maybe<{ __typename: "Visitor" } & IVisitorFragment> }
  >;
};

export type IUpdateAbTestMutationVariables = Exact<{
  id: Scalars["ID"];
  abTest: IAbTestAttributes;
}>;

export type IUpdateAbTestMutation = { __typename: "Mutation" } & {
  updateAbTest?: Maybe<
    { __typename: "UpdateAbTestPayload" } & Pick<
      IUpdateAbTestPayload,
      "success" | "errors"
    > & { abTest?: Maybe<{ __typename: "AbTest" } & IAbTestFragment> }
  >;
};

export type IUpdateOrganizationMutationVariables = Exact<{
  billingEmail: Scalars["String"];
}>;

export type IUpdateOrganizationMutation = { __typename: "Mutation" } & {
  updateOrganization?: Maybe<
    { __typename: "UpdateOrganizationPayload" } & Pick<
      IUpdateOrganizationPayload,
      "success" | "errors"
    > & {
        organization?: Maybe<
          { __typename: "Organization" } & IOrganizationFragment
        >;
      }
  >;
};

export type IUpdatePaymentMethodMutationVariables = Exact<{
  stripePaymentMethodId: Scalars["String"];
}>;

export type IUpdatePaymentMethodMutation = { __typename: "Mutation" } & {
  updatePaymentMethod?: Maybe<
    { __typename: "UpdatePaymentMethodPayload" } & Pick<
      IUpdatePaymentMethodPayload,
      "success" | "errors"
    >
  >;
};

export type IUpdateUserMutationVariables = Exact<{
  id: Scalars["ID"];
  user: IUserAttributes;
}>;

export type IUpdateUserMutation = { __typename: "Mutation" } & {
  updateUser?: Maybe<
    { __typename: "UpdateUserPayload" } & Pick<
      IUpdateUserPayload,
      "success" | "errors"
    > & { user?: Maybe<{ __typename: "User" } & IUserFragment> }
  >;
};

export type IFindAbTestQueryVariables = Exact<{
  apiId: Scalars["String"];
}>;

export type IFindAbTestQuery = { __typename: "Query" } & {
  abTest?: Maybe<{ __typename: "AbTest" } & IAbTestFragment>;
};

export type IFindAbTestVariantQueryVariables = Exact<{
  apiId: Scalars["String"];
}>;

export type IFindAbTestVariantQuery = { __typename: "Query" } & {
  abTestVariant?: Maybe<
    { __typename: "AbTestVariant" } & IAbTestVariantFragment
  >;
};

export type IFindCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type IFindCurrentUserQuery = { __typename: "Query" } & {
  currentUser: { __typename: "User" } & IUserFragment;
};

export type IFindOrganizationQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type IFindOrganizationQuery = { __typename: "Query" } & {
  organization: { __typename: "Organization" } & IOrganizationFragment;
};

export type IListAbTestsQueryVariables = Exact<{
  archived?: Maybe<Scalars["Boolean"]>;
}>;

export type IListAbTestsQuery = { __typename: "Query" } & {
  abTests: { __typename: "AbTestConnection" } & Pick<
    IAbTestConnection,
    "totalCount"
  > & {
      pageInfo: { __typename: "PageInfo" } & Pick<
        IPageInfo,
        "startCursor" | "endCursor" | "hasNextPage"
      >;
      edges: Array<
        { __typename: "AbTestEdge" } & {
          node: { __typename: "AbTest" } & Pick<
            IAbTest,
            | "id"
            | "apiId"
            | "createdAt"
            | "snippet"
            | "tags"
            | "name"
            | "persistChild"
            | "totalVisitors"
            | "totalImpressions"
            | "conversionLift"
            | "conversionLiftPercentage"
            | "clickLift"
            | "clickLiftPercentage"
            | "uniqueConversionLift"
            | "uniqueConversionLiftPercentage"
            | "uniqueClickLift"
            | "uniqueClickLiftPercentage"
            | "activeThisMonth"
            | "recordedUrl"
            | "winner"
            | "archived"
            | "discardedAt"
            | "targetSampleSize"
          >;
        }
      >;
    };
};

export type IListUsersQueryVariables = Exact<{ [key: string]: never }>;

export type IListUsersQuery = { __typename: "Query" } & {
  users: Array<
    { __typename: "User" } & Pick<
      IUser,
      | "id"
      | "email"
      | "name"
      | "nameOrEmail"
      | "role"
      | "authenticationToken"
      | "apiKey"
      | "invited"
      | "invitedAt"
    >
  >;
};

export type IListVisitorConversionsQueryVariables = Exact<{
  abTestVariantId?: Maybe<Scalars["ID"]>;
  visitorApiId: Scalars["String"];
}>;

export type IListVisitorConversionsQuery = { __typename: "Query" } & {
  conversions: { __typename: "ConversionConnection" } & Pick<
    IConversionConnection,
    "totalCount"
  > & { nodes: Array<{ __typename: "Conversion" } & IConversionFragment> };
};

export type IListVisitorsQueryVariables = Exact<{
  abTestVariantId?: Maybe<Scalars["ID"]>;
  q?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  first?: Maybe<Scalars["Int"]>;
  after?: Maybe<Scalars["String"]>;
  before?: Maybe<Scalars["String"]>;
}>;

export type IListVisitorsQuery = { __typename: "Query" } & {
  visitors: { __typename: "VisitorConnection" } & Pick<
    IVisitorConnection,
    "totalCount"
  > & {
      pageInfo: { __typename: "PageInfo" } & Pick<
        IPageInfo,
        "startCursor" | "endCursor" | "hasPreviousPage" | "hasNextPage"
      >;
      edges: Array<
        { __typename: "VisitorEdge" } & {
          node: { __typename: "Visitor" } & IVisitorFragment;
        }
      >;
    };
};

export const AbTestVariantFragmentDoc = gql`
  fragment AbTestVariantFragment on AbTestVariant {
    id
    apiId
    abTestId
    abTestApiId
    variantLetter
    variantDesignation
    markup
    styles
    externalStyleSheets
    clickCount
    uniqueClickCount
    uniqueVisitorsCount
    impressionCount
    conversionCount
    uniqueConversionCount
    clickRate
    clickRatePercentage
    uniqueClickRate
    uniqueClickRatePercentage
    conversionRate
    conversionRatePercentage
    uniqueConversionRate
    uniqueConversionRatePercentage
    bounceRate
    bounceRatePercentage
  }
`;
export const AbTestFragmentDoc = gql`
  fragment AbTestFragment on AbTest {
    id
    apiId
    createdAt
    snippet
    tags
    name
    persistChild
    totalVisitors
    totalImpressions
    conversionLift
    conversionLiftPercentage
    clickLift
    clickLiftPercentage
    uniqueConversionLift
    uniqueConversionLiftPercentage
    uniqueClickLift
    uniqueClickLiftPercentage
    activeThisMonth
    winner
    recordedUrl
    pValue
    pValueDescription
    archived
    discardedAt
    targetSampleSize
    variants {
      ...AbTestVariantFragment
    }
  }
  ${AbTestVariantFragmentDoc}
`;
export const ConversionFragmentDoc = gql`
  fragment ConversionFragment on Conversion {
    id
    abTestVariantId
    visitorId
  }
`;
export const BillingSubscriptionFragmentDoc = gql`
  fragment BillingSubscriptionFragment on BillingSubscription {
    id
    organizationId
    stripeId
    stripePaymentMethodId
    status
    currentPeriodStart
    currentPeriodEnd
    creditCardBrand
    creditCardLast4
    planName
    upcomingInvoiceTotal
  }
`;
export const OrganizationFragmentDoc = gql`
  fragment OrganizationFragment on Organization {
    id
    name
    apiKey
    activeAbTestsCount
    earlyAdopter
    billingEmail
    billingSetup
    trialEndsOn
    trialDaysRemaining
    outOfTrial
    billingSubscription {
      ...BillingSubscriptionFragment
    }
  }
  ${BillingSubscriptionFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment UserFragment on User {
    id
    email
    name
    nameOrEmail
    role
    authenticationToken
    apiKey
    invited
    invitedAt
    organization {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragmentDoc}
`;
export const VisitorFragmentDoc = gql`
  fragment VisitorFragment on Visitor {
    id
    apiId
    uid
    name
    email
    meta
    lastVisitedDate
    impressionCount
    conversionCount
    clickCount
    scrubbed
  }
`;
export const AcceptInvitationDocument = gql`
  mutation AcceptInvitation($token: String!, $password: String!) {
    acceptInvitation(input: { token: $token, password: $password }) {
      success
      errors
      user {
        id
        email
        authenticationToken
      }
    }
  }
`;
export type IAcceptInvitationMutationFn = ApolloReactCommon.MutationFunction<
  IAcceptInvitationMutation,
  IAcceptInvitationMutationVariables
>;

/**
 * __useAcceptInvitationMutation__
 *
 * To run a mutation, you first call `useAcceptInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInvitationMutation, { data, loading, error }] = useAcceptInvitationMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useAcceptInvitationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IAcceptInvitationMutation,
    IAcceptInvitationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IAcceptInvitationMutation,
    IAcceptInvitationMutationVariables
  >(AcceptInvitationDocument, baseOptions);
}
export type AcceptInvitationMutationHookResult = ReturnType<
  typeof useAcceptInvitationMutation
>;
export type AcceptInvitationMutationResult =
  ApolloReactCommon.MutationResult<IAcceptInvitationMutation>;
export type AcceptInvitationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IAcceptInvitationMutation,
    IAcceptInvitationMutationVariables
  >;
export const ArchiveAbTestDocument = gql`
  mutation ArchiveAbTest($abTestId: ID!) {
    archiveAbTest(input: { abTestId: $abTestId }) {
      success
      errors
      abTest {
        id
        apiId
        snippet
        tags
      }
    }
  }
`;
export type IArchiveAbTestMutationFn = ApolloReactCommon.MutationFunction<
  IArchiveAbTestMutation,
  IArchiveAbTestMutationVariables
>;

/**
 * __useArchiveAbTestMutation__
 *
 * To run a mutation, you first call `useArchiveAbTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAbTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAbTestMutation, { data, loading, error }] = useArchiveAbTestMutation({
 *   variables: {
 *      abTestId: // value for 'abTestId'
 *   },
 * });
 */
export function useArchiveAbTestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IArchiveAbTestMutation,
    IArchiveAbTestMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IArchiveAbTestMutation,
    IArchiveAbTestMutationVariables
  >(ArchiveAbTestDocument, baseOptions);
}
export type ArchiveAbTestMutationHookResult = ReturnType<
  typeof useArchiveAbTestMutation
>;
export type ArchiveAbTestMutationResult =
  ApolloReactCommon.MutationResult<IArchiveAbTestMutation>;
export type ArchiveAbTestMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IArchiveAbTestMutation,
    IArchiveAbTestMutationVariables
  >;
export const CreateAbTestDocument = gql`
  mutation CreateAbTest($abTest: AbTestAttributes!) {
    createAbTest(input: { abTest: $abTest }) {
      success
      errors
      abTest {
        id
        apiId
        snippet
        tags
      }
    }
  }
`;
export type ICreateAbTestMutationFn = ApolloReactCommon.MutationFunction<
  ICreateAbTestMutation,
  ICreateAbTestMutationVariables
>;

/**
 * __useCreateAbTestMutation__
 *
 * To run a mutation, you first call `useCreateAbTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAbTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAbTestMutation, { data, loading, error }] = useCreateAbTestMutation({
 *   variables: {
 *      abTest: // value for 'abTest'
 *   },
 * });
 */
export function useCreateAbTestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreateAbTestMutation,
    ICreateAbTestMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ICreateAbTestMutation,
    ICreateAbTestMutationVariables
  >(CreateAbTestDocument, baseOptions);
}
export type CreateAbTestMutationHookResult = ReturnType<
  typeof useCreateAbTestMutation
>;
export type CreateAbTestMutationResult =
  ApolloReactCommon.MutationResult<ICreateAbTestMutation>;
export type CreateAbTestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ICreateAbTestMutation,
  ICreateAbTestMutationVariables
>;
export const CreateBillingSubscriptionDocument = gql`
  mutation CreateBillingSubscription(
    $billingSubscription: BillingSubscriptionAttributes!
  ) {
    createBillingSubscription(
      input: { billingSubscription: $billingSubscription }
    ) {
      success
      errors
      stripeSubscription
      stripePriceId
      billingSubscription {
        ...BillingSubscriptionFragment
      }
    }
  }
  ${BillingSubscriptionFragmentDoc}
`;
export type ICreateBillingSubscriptionMutationFn =
  ApolloReactCommon.MutationFunction<
    ICreateBillingSubscriptionMutation,
    ICreateBillingSubscriptionMutationVariables
  >;

/**
 * __useCreateBillingSubscription__
 *
 * To run a mutation, you first call `useCreateBillingSubscription` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillingSubscription` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillingSubscription, { data, loading, error }] = useCreateBillingSubscription({
 *   variables: {
 *      billingSubscription: // value for 'billingSubscription'
 *   },
 * });
 */
export function useCreateBillingSubscription(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreateBillingSubscriptionMutation,
    ICreateBillingSubscriptionMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ICreateBillingSubscriptionMutation,
    ICreateBillingSubscriptionMutationVariables
  >(CreateBillingSubscriptionDocument, baseOptions);
}
export type CreateBillingSubscriptionHookResult = ReturnType<
  typeof useCreateBillingSubscription
>;
export type CreateBillingSubscriptionMutationResult =
  ApolloReactCommon.MutationResult<ICreateBillingSubscriptionMutation>;
export type CreateBillingSubscriptionMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    ICreateBillingSubscriptionMutation,
    ICreateBillingSubscriptionMutationVariables
  >;
export const CreateUserDocument = gql`
  mutation CreateUser($user: UserAttributes!) {
    createUser(input: { user: $user }) {
      success
      errors
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragmentDoc}
`;
export type ICreateUserMutationFn = ApolloReactCommon.MutationFunction<
  ICreateUserMutation,
  ICreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ICreateUserMutation,
    ICreateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    ICreateUserMutation,
    ICreateUserMutationVariables
  >(CreateUserDocument, baseOptions);
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  ApolloReactCommon.MutationResult<ICreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ICreateUserMutation,
  ICreateUserMutationVariables
>;
export const DeleteAbTestDocument = gql`
  mutation DeleteAbTest($abTestId: ID!) {
    deleteAbTest(input: { abTestId: $abTestId }) {
      success
      errors
      abTest {
        id
        apiId
        snippet
        tags
      }
    }
  }
`;
export type IDeleteAbTestMutationFn = ApolloReactCommon.MutationFunction<
  IDeleteAbTestMutation,
  IDeleteAbTestMutationVariables
>;

/**
 * __useDeleteAbTestMutation__
 *
 * To run a mutation, you first call `useDeleteAbTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAbTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAbTestMutation, { data, loading, error }] = useDeleteAbTestMutation({
 *   variables: {
 *      abTestId: // value for 'abTestId'
 *   },
 * });
 */
export function useDeleteAbTestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IDeleteAbTestMutation,
    IDeleteAbTestMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IDeleteAbTestMutation,
    IDeleteAbTestMutationVariables
  >(DeleteAbTestDocument, baseOptions);
}
export type DeleteAbTestMutationHookResult = ReturnType<
  typeof useDeleteAbTestMutation
>;
export type DeleteAbTestMutationResult =
  ApolloReactCommon.MutationResult<IDeleteAbTestMutation>;
export type DeleteAbTestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  IDeleteAbTestMutation,
  IDeleteAbTestMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($userId: ID!) {
    deleteUser(input: { userId: $userId }) {
      success
      errors
      user {
        id
        email
      }
    }
  }
`;
export type IDeleteUserMutationFn = ApolloReactCommon.MutationFunction<
  IDeleteUserMutation,
  IDeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IDeleteUserMutation,
    IDeleteUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IDeleteUserMutation,
    IDeleteUserMutationVariables
  >(DeleteUserDocument, baseOptions);
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  ApolloReactCommon.MutationResult<IDeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  IDeleteUserMutation,
  IDeleteUserMutationVariables
>;
export const ResendInvitationDocument = gql`
  mutation ResendInvitation($userId: ID!) {
    resendInvitation(input: { userId: $userId }) {
      success
      errors
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragmentDoc}
`;
export type IResendInvitationMutationFn = ApolloReactCommon.MutationFunction<
  IResendInvitationMutation,
  IResendInvitationMutationVariables
>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendInvitationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IResendInvitationMutation,
    IResendInvitationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IResendInvitationMutation,
    IResendInvitationMutationVariables
  >(ResendInvitationDocument, baseOptions);
}
export type ResendInvitationMutationHookResult = ReturnType<
  typeof useResendInvitationMutation
>;
export type ResendInvitationMutationResult =
  ApolloReactCommon.MutationResult<IResendInvitationMutation>;
export type ResendInvitationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IResendInvitationMutation,
    IResendInvitationMutationVariables
  >;
export const ScrubVisitorDocument = gql`
  mutation ScrubVisitor($visitorId: ID!) {
    scrubVisitor(input: { visitorId: $visitorId }) {
      success
      errors
      visitor {
        ...VisitorFragment
      }
    }
  }
  ${VisitorFragmentDoc}
`;
export type IScrubVisitorMutationFn = ApolloReactCommon.MutationFunction<
  IScrubVisitorMutation,
  IScrubVisitorMutationVariables
>;

/**
 * __useScrubVisitorMutation__
 *
 * To run a mutation, you first call `useScrubVisitorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScrubVisitorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scrubVisitorMutation, { data, loading, error }] = useScrubVisitorMutation({
 *   variables: {
 *      visitorId: // value for 'visitorId'
 *   },
 * });
 */
export function useScrubVisitorMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IScrubVisitorMutation,
    IScrubVisitorMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IScrubVisitorMutation,
    IScrubVisitorMutationVariables
  >(ScrubVisitorDocument, baseOptions);
}
export type ScrubVisitorMutationHookResult = ReturnType<
  typeof useScrubVisitorMutation
>;
export type ScrubVisitorMutationResult =
  ApolloReactCommon.MutationResult<IScrubVisitorMutation>;
export type ScrubVisitorMutationOptions = ApolloReactCommon.BaseMutationOptions<
  IScrubVisitorMutation,
  IScrubVisitorMutationVariables
>;
export const UpdateAbTestDocument = gql`
  mutation UpdateAbTest($id: ID!, $abTest: AbTestAttributes!) {
    updateAbTest(input: { id: $id, abTest: $abTest }) {
      success
      errors
      abTest {
        ...AbTestFragment
      }
    }
  }
  ${AbTestFragmentDoc}
`;
export type IUpdateAbTestMutationFn = ApolloReactCommon.MutationFunction<
  IUpdateAbTestMutation,
  IUpdateAbTestMutationVariables
>;

/**
 * __useUpdateAbTestMutation__
 *
 * To run a mutation, you first call `useUpdateAbTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAbTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAbTestMutation, { data, loading, error }] = useUpdateAbTestMutation({
 *   variables: {
 *      id: // value for 'id'
 *      abTest: // value for 'abTest'
 *   },
 * });
 */
export function useUpdateAbTestMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IUpdateAbTestMutation,
    IUpdateAbTestMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IUpdateAbTestMutation,
    IUpdateAbTestMutationVariables
  >(UpdateAbTestDocument, baseOptions);
}
export type UpdateAbTestMutationHookResult = ReturnType<
  typeof useUpdateAbTestMutation
>;
export type UpdateAbTestMutationResult =
  ApolloReactCommon.MutationResult<IUpdateAbTestMutation>;
export type UpdateAbTestMutationOptions = ApolloReactCommon.BaseMutationOptions<
  IUpdateAbTestMutation,
  IUpdateAbTestMutationVariables
>;
export const UpdateOrganizationDocument = gql`
  mutation UpdateOrganization($billingEmail: String!) {
    updateOrganization(input: { billingEmail: $billingEmail }) {
      success
      errors
      organization {
        ...OrganizationFragment
      }
    }
  }
  ${OrganizationFragmentDoc}
`;
export type IUpdateOrganizationMutationFn = ApolloReactCommon.MutationFunction<
  IUpdateOrganizationMutation,
  IUpdateOrganizationMutationVariables
>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      billingEmail: // value for 'billingEmail'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IUpdateOrganizationMutation,
    IUpdateOrganizationMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IUpdateOrganizationMutation,
    IUpdateOrganizationMutationVariables
  >(UpdateOrganizationDocument, baseOptions);
}
export type UpdateOrganizationMutationHookResult = ReturnType<
  typeof useUpdateOrganizationMutation
>;
export type UpdateOrganizationMutationResult =
  ApolloReactCommon.MutationResult<IUpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IUpdateOrganizationMutation,
    IUpdateOrganizationMutationVariables
  >;
export const UpdatePaymentMethodDocument = gql`
  mutation UpdatePaymentMethod($stripePaymentMethodId: String!) {
    updatePaymentMethod(
      input: { stripePaymentMethodId: $stripePaymentMethodId }
    ) {
      success
      errors
    }
  }
`;
export type IUpdatePaymentMethodMutationFn = ApolloReactCommon.MutationFunction<
  IUpdatePaymentMethodMutation,
  IUpdatePaymentMethodMutationVariables
>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      stripePaymentMethodId: // value for 'stripePaymentMethodId'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IUpdatePaymentMethodMutation,
    IUpdatePaymentMethodMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IUpdatePaymentMethodMutation,
    IUpdatePaymentMethodMutationVariables
  >(UpdatePaymentMethodDocument, baseOptions);
}
export type UpdatePaymentMethodMutationHookResult = ReturnType<
  typeof useUpdatePaymentMethodMutation
>;
export type UpdatePaymentMethodMutationResult =
  ApolloReactCommon.MutationResult<IUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions =
  ApolloReactCommon.BaseMutationOptions<
    IUpdatePaymentMethodMutation,
    IUpdatePaymentMethodMutationVariables
  >;
export const UpdateUserDocument = gql`
  mutation UpdateUser($id: ID!, $user: UserAttributes!) {
    updateUser(input: { id: $id, user: $user }) {
      success
      errors
      user {
        ...UserFragment
      }
    }
  }
  ${UserFragmentDoc}
`;
export type IUpdateUserMutationFn = ApolloReactCommon.MutationFunction<
  IUpdateUserMutation,
  IUpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    IUpdateUserMutation,
    IUpdateUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    IUpdateUserMutation,
    IUpdateUserMutationVariables
  >(UpdateUserDocument, baseOptions);
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  ApolloReactCommon.MutationResult<IUpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  IUpdateUserMutation,
  IUpdateUserMutationVariables
>;
export const FindAbTestDocument = gql`
  query FindAbTest($apiId: String!) {
    abTest: findAbTest(apiId: $apiId) {
      ...AbTestFragment
    }
  }
  ${AbTestFragmentDoc}
`;

/**
 * __useFindAbTestQuery__
 *
 * To run a query within a React component, call `useFindAbTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAbTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAbTestQuery({
 *   variables: {
 *      apiId: // value for 'apiId'
 *   },
 * });
 */
export function useFindAbTestQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFindAbTestQuery,
    IFindAbTestQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<IFindAbTestQuery, IFindAbTestQueryVariables>(
    FindAbTestDocument,
    baseOptions
  );
}
export function useFindAbTestLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindAbTestQuery,
    IFindAbTestQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IFindAbTestQuery,
    IFindAbTestQueryVariables
  >(FindAbTestDocument, baseOptions);
}
export type FindAbTestQueryHookResult = ReturnType<typeof useFindAbTestQuery>;
export type FindAbTestLazyQueryHookResult = ReturnType<
  typeof useFindAbTestLazyQuery
>;
export type FindAbTestQueryResult = ApolloReactCommon.QueryResult<
  IFindAbTestQuery,
  IFindAbTestQueryVariables
>;
export const FindAbTestVariantDocument = gql`
  query FindAbTestVariant($apiId: String!) {
    abTestVariant: findAbTestVariant(apiId: $apiId) {
      ...AbTestVariantFragment
    }
  }
  ${AbTestVariantFragmentDoc}
`;

/**
 * __useFindAbTestVariantQuery__
 *
 * To run a query within a React component, call `useFindAbTestVariantQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAbTestVariantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAbTestVariantQuery({
 *   variables: {
 *      apiId: // value for 'apiId'
 *   },
 * });
 */
export function useFindAbTestVariantQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFindAbTestVariantQuery,
    IFindAbTestVariantQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IFindAbTestVariantQuery,
    IFindAbTestVariantQueryVariables
  >(FindAbTestVariantDocument, baseOptions);
}
export function useFindAbTestVariantLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindAbTestVariantQuery,
    IFindAbTestVariantQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IFindAbTestVariantQuery,
    IFindAbTestVariantQueryVariables
  >(FindAbTestVariantDocument, baseOptions);
}
export type FindAbTestVariantQueryHookResult = ReturnType<
  typeof useFindAbTestVariantQuery
>;
export type FindAbTestVariantLazyQueryHookResult = ReturnType<
  typeof useFindAbTestVariantLazyQuery
>;
export type FindAbTestVariantQueryResult = ApolloReactCommon.QueryResult<
  IFindAbTestVariantQuery,
  IFindAbTestVariantQueryVariables
>;
export const FindCurrentUserDocument = gql`
  query FindCurrentUser {
    currentUser: findCurrentUser {
      ...UserFragment
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useFindCurrentUserQuery__
 *
 * To run a query within a React component, call `useFindCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useFindCurrentUserQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IFindCurrentUserQuery,
    IFindCurrentUserQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IFindCurrentUserQuery,
    IFindCurrentUserQueryVariables
  >(FindCurrentUserDocument, baseOptions);
}
export function useFindCurrentUserLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindCurrentUserQuery,
    IFindCurrentUserQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IFindCurrentUserQuery,
    IFindCurrentUserQueryVariables
  >(FindCurrentUserDocument, baseOptions);
}
export type FindCurrentUserQueryHookResult = ReturnType<
  typeof useFindCurrentUserQuery
>;
export type FindCurrentUserLazyQueryHookResult = ReturnType<
  typeof useFindCurrentUserLazyQuery
>;
export type FindCurrentUserQueryResult = ApolloReactCommon.QueryResult<
  IFindCurrentUserQuery,
  IFindCurrentUserQueryVariables
>;
export const FindOrganizationDocument = gql`
  query FindOrganization($id: ID!) {
    organization: findOrganization(id: $id) {
      ...OrganizationFragment
    }
  }
  ${OrganizationFragmentDoc}
`;

/**
 * __useFindOrganizationQuery__
 *
 * To run a query within a React component, call `useFindOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindOrganizationQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IFindOrganizationQuery,
    IFindOrganizationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IFindOrganizationQuery,
    IFindOrganizationQueryVariables
  >(FindOrganizationDocument, baseOptions);
}
export function useFindOrganizationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IFindOrganizationQuery,
    IFindOrganizationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IFindOrganizationQuery,
    IFindOrganizationQueryVariables
  >(FindOrganizationDocument, baseOptions);
}
export type FindOrganizationQueryHookResult = ReturnType<
  typeof useFindOrganizationQuery
>;
export type FindOrganizationLazyQueryHookResult = ReturnType<
  typeof useFindOrganizationLazyQuery
>;
export type FindOrganizationQueryResult = ApolloReactCommon.QueryResult<
  IFindOrganizationQuery,
  IFindOrganizationQueryVariables
>;
export const ListAbTestsDocument = gql`
  query ListAbTests($archived: Boolean) {
    abTests: listAbTests(archived: $archived) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        node {
          id
          apiId
          createdAt
          snippet
          tags
          name
          persistChild
          totalVisitors
          totalImpressions
          conversionLift
          conversionLiftPercentage
          clickLift
          clickLiftPercentage
          uniqueConversionLift
          uniqueConversionLiftPercentage
          uniqueClickLift
          uniqueClickLiftPercentage
          activeThisMonth
          recordedUrl
          winner
          archived
          discardedAt
          targetSampleSize
        }
      }
    }
  }
`;

/**
 * __useListAbTestsQuery__
 *
 * To run a query within a React component, call `useListAbTestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAbTestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAbTestsQuery({
 *   variables: {
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useListAbTestsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListAbTestsQuery,
    IListAbTestsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListAbTestsQuery,
    IListAbTestsQueryVariables
  >(ListAbTestsDocument, baseOptions);
}
export function useListAbTestsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListAbTestsQuery,
    IListAbTestsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListAbTestsQuery,
    IListAbTestsQueryVariables
  >(ListAbTestsDocument, baseOptions);
}
export type ListAbTestsQueryHookResult = ReturnType<typeof useListAbTestsQuery>;
export type ListAbTestsLazyQueryHookResult = ReturnType<
  typeof useListAbTestsLazyQuery
>;
export type ListAbTestsQueryResult = ApolloReactCommon.QueryResult<
  IListAbTestsQuery,
  IListAbTestsQueryVariables
>;
export const ListUsersDocument = gql`
  query ListUsers {
    users: listUsers {
      id
      email
      name
      nameOrEmail
      role
      authenticationToken
      apiKey
      invited
      invitedAt
    }
  }
`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useListUsersQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListUsersQuery,
    IListUsersQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<IListUsersQuery, IListUsersQueryVariables>(
    ListUsersDocument,
    baseOptions
  );
}
export function useListUsersLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListUsersQuery,
    IListUsersQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListUsersQuery,
    IListUsersQueryVariables
  >(ListUsersDocument, baseOptions);
}
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<
  typeof useListUsersLazyQuery
>;
export type ListUsersQueryResult = ApolloReactCommon.QueryResult<
  IListUsersQuery,
  IListUsersQueryVariables
>;
export const ListVisitorConversionsDocument = gql`
  query ListVisitorConversions($abTestVariantId: ID, $visitorApiId: String!) {
    conversions: listVisitorConversions(
      abTestVariantId: $abTestVariantId
      visitorApiId: $visitorApiId
    ) {
      totalCount
      nodes {
        ...ConversionFragment
      }
    }
  }
  ${ConversionFragmentDoc}
`;

/**
 * __useListVisitorConversionsQuery__
 *
 * To run a query within a React component, call `useListVisitorConversionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVisitorConversionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVisitorConversionsQuery({
 *   variables: {
 *      abTestVariantId: // value for 'abTestVariantId'
 *      visitorApiId: // value for 'visitorApiId'
 *   },
 * });
 */
export function useListVisitorConversionsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    IListVisitorConversionsQuery,
    IListVisitorConversionsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListVisitorConversionsQuery,
    IListVisitorConversionsQueryVariables
  >(ListVisitorConversionsDocument, baseOptions);
}
export function useListVisitorConversionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListVisitorConversionsQuery,
    IListVisitorConversionsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListVisitorConversionsQuery,
    IListVisitorConversionsQueryVariables
  >(ListVisitorConversionsDocument, baseOptions);
}
export type ListVisitorConversionsQueryHookResult = ReturnType<
  typeof useListVisitorConversionsQuery
>;
export type ListVisitorConversionsLazyQueryHookResult = ReturnType<
  typeof useListVisitorConversionsLazyQuery
>;
export type ListVisitorConversionsQueryResult = ApolloReactCommon.QueryResult<
  IListVisitorConversionsQuery,
  IListVisitorConversionsQueryVariables
>;
export const ListVisitorsDocument = gql`
  query ListVisitors(
    $abTestVariantId: ID
    $q: String
    $name: String
    $email: String
    $first: Int
    $after: String
    $before: String
  ) {
    visitors: listVisitors(
      abTestVariantId: $abTestVariantId
      q: $q
      name: $name
      email: $email
      first: $first
      before: $before
      after: $after
    ) {
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
      totalCount
      edges {
        node {
          ...VisitorFragment
        }
      }
    }
  }
  ${VisitorFragmentDoc}
`;

/**
 * __useListVisitorsQuery__
 *
 * To run a query within a React component, call `useListVisitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVisitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVisitorsQuery({
 *   variables: {
 *      abTestVariantId: // value for 'abTestVariantId'
 *      q: // value for 'q'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useListVisitorsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    IListVisitorsQuery,
    IListVisitorsQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    IListVisitorsQuery,
    IListVisitorsQueryVariables
  >(ListVisitorsDocument, baseOptions);
}
export function useListVisitorsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    IListVisitorsQuery,
    IListVisitorsQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    IListVisitorsQuery,
    IListVisitorsQueryVariables
  >(ListVisitorsDocument, baseOptions);
}
export type ListVisitorsQueryHookResult = ReturnType<
  typeof useListVisitorsQuery
>;
export type ListVisitorsLazyQueryHookResult = ReturnType<
  typeof useListVisitorsLazyQuery
>;
export type ListVisitorsQueryResult = ApolloReactCommon.QueryResult<
  IListVisitorsQuery,
  IListVisitorsQueryVariables
>;

export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {}
};
export default result;
