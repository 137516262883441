import React from "react";
import { TIcon } from "src/definitions/TIcon";

export function IconDotsVertical({
  size = 24,
  color = "#1956DB",
  className,
  style,
  onClick
}: TIcon) {
  return (
    <svg
      fill={color}
      viewBox="0 0 20 20"
      width={size}
      height={size}
      className={`fill-current ${onClick && "cursor-pointer"} ${className} `}
      style={style}
      onClick={onClick}
    >
      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
    </svg>
  );
}
