import React from "react";
import Segment from "src/components/Segment";
import Logo from "src/components/Logo";
import { Link } from "react-router-dom";

export default function MarketingFooter() {
  return (
    <div style={{ height: "180px" }} className="w-full">
      <Segment opaque className="h-full">
        <div className="flex mx-auto max-w-screen-xl justify-between text-gray-600 px-4 md:px-12">
          <div className="flex flex-col items-start">
            <Logo width={100} className="mb-4" />
            <Link className="mb-4" to="/sign-in">
              Sign In
            </Link>
            <Link
              to="/documentation"
              rel="noopener noreferrer"
              target="_blank"
              className="mb-4"
            >
              Documentation
            </Link>
            <a
              target="blank"
              rel="noopener noreferrer"
              href="https://blog.preferr.app"
            >
              Blog
            </a>
          </div>
          <div className="flex flex-col items-end">
            <Link to="/support" className="mb-4">
              Support
            </Link>
            {/* <Link to="/company" className="mb-4">
              Company
            </Link> */}
            <Link to="/privacy" className="mb-4">
              Privacy
            </Link>
            <Link to="/terms">Terms & Conditions</Link>
          </div>
        </div>
      </Segment>
    </div>
  );
}
