import React from "react";
import { TIcon } from "src/definitions/TIcon";

export function IconInfinity({
  size = 24,
  color = "#1956DB",
  className,
  style,
  onClick
}: TIcon) {
  return (
    <svg
      fill={color}
      viewBox="0 0 24 24"
      width={size}
      height={size}
      className={`fill-current ${className}`}
      style={style}
      onClick={onClick}
    >
      <path d="M17.0797 7.00002C16.2398 7.00002 15.4398 7.20326 14.7195 7.60973C14.3195 7.85345 13.9593 8.13846 13.6398 8.46315L12 10.1303L10.3602 8.46395C10.0399 8.13844 9.68051 7.85424 9.28051 7.61052C8.5602 7.20405 7.72037 7.00081 6.92034 7.00081C4.19997 7.00002 2 9.23559 2 12C2 14.7644 4.19997 17 6.92034 17C7.76017 17 8.56016 16.7968 9.28051 16.3903C9.68051 16.1466 10.0407 15.9029 10.3602 15.5369L12 13.8705L13.6398 15.5369C13.9601 15.8624 14.3195 16.1466 14.7195 16.3903C15.4398 16.7968 16.2796 17 17.0797 17C19.8 17 22 14.7644 22 12C22 9.23559 19.8 7 17.0797 7V7.00002ZM8.52036 13.6663C8.36021 13.8291 8.20005 13.9506 8.00005 14.0728C7.63989 14.2761 7.27974 14.357 6.87974 14.357C5.60008 14.357 4.60017 13.3004 4.60017 12.0405C4.60017 10.7807 5.64 9.72407 6.87974 9.72407C7.27974 9.72407 7.63989 9.80504 8.00005 10.0083C8.20005 10.1305 8.3602 10.252 8.52036 10.4147L10.1602 12.0811L8.52036 13.6663ZM17.0797 14.3165C16.6797 14.3165 16.3195 14.2356 15.9594 14.0323C15.7594 13.9101 15.5992 13.7886 15.439 13.6259L13.8398 12L15.4797 10.3336C15.6398 10.1709 15.8 10.0494 16 9.92714C16.3601 9.7239 16.7203 9.64294 17.1203 9.64294C18.3999 9.64294 19.3999 10.6996 19.3999 11.9594C19.3999 13.3003 18.36 14.3165 17.0795 14.3165H17.0797Z" />
    </svg>
  );
}
