import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  createHttpLink,
  from
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { host, protocol } from "src/config";

const fetcher = (...args: any) => {
  return (window as any).fetch(...args);
};

const httpLink = createHttpLink({
  uri: `${protocol}://${host}/graphql`,
  fetch: fetcher
});

const authLink = setContext((_: any, { headers }: any) => {
  return {
    headers: {
      ...headers,
      "X-User-Email": localStorage.getItem("userEmail"),
      "X-User-Token": localStorage.getItem("userAuthToken")
    }
  };
});

const afterwareLink = onError(({ networkError }: any) => {
  if (networkError && networkError.message === "Failed to fetch") {
    console.warn(networkError);
  }
});

const link = ApolloLink.from([authLink, httpLink]);

const client = new ApolloClient({
  link: from([authLink, afterwareLink, link]),
  cache: new InMemoryCache()
});

export default client;
