import React from "react";
import { Field, withTypes } from "react-final-form";
import Button from "src/components/Button";
import NumberField from "src/components/Forms/NumberField";
import TextField from "src/components/Forms/TextField";
import {
  IAbTestAttributes,
  useCreateAbTestMutation
} from "src/components/graphql";

const { Form } = withTypes<IAbTestAttributes>();

interface IProps {
  onSuccess: () => void;
}

export default function CreateAbTestForm({ onSuccess }: IProps) {
  const [createAbTestMutation] = useCreateAbTestMutation();

  const onSubmit = async (values: IAbTestAttributes) => {
    const result = await createAbTestMutation({
      variables: {
        abTest: {
          name: values.name,
          targetSampleSize: values.targetSampleSize
        }
      },
      refetchQueries: ["ListAbTests"]
    });

    if (result.data?.createAbTest?.success) {
      return onSuccess();
      // history.push(Url.abTestPath(result.data.createAbTest.abTest?.id!));
    }
  };

  const required = (value: any) => (value ? undefined : "Required");

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, pristine }) => {
        return (
          <form onSubmit={handleSubmit} autoComplete="off" className="w-full">
            <Field
              name="name"
              type="text"
              autoFocus
              validate={required}
              render={({ input, meta }) => (
                <TextField label="Name" {...input} />
              )}
            />
            <Field
              name="targetSampleSize"
              type="number"
              parse={(value) => parseInt(value)}
              render={({ input, meta }) => (
                <NumberField
                  label="Target Sample Size"
                  {...input}
                  helpText="The minimum number of visitors you'd like for this test. Set to 1,000 by default."
                  placeholder="1,000"
                />
              )}
            />
            <Button type="submit" disabled={submitting || pristine}>
              Create A/B Test
            </Button>
          </form>
        );
      }}
    />
  );
}
