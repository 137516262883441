import React from "react";
import Segment from "src/components/Segment";
import Circles from "src/components/Circles";

export default function NotFound() {
  return (
    <div className="w-full sm:w-2/3 mx-auto">
      <Segment className="flex flex-col justify-center items-center">
        <Circles size={96} color1="#F05252" color2="#E02523" />
        <div className="font-poppins text-2xl text-gray-800 mb-4">
          Uh oh! Looks like that page doesn't exist.
        </div>
      </Segment>
    </div>
  );
}
