import React from "react";

interface IProps {
  errors: string[];
  className?: string;
}

export default function Errors({ errors, className }: IProps) {
  const errorsList = errors.map((e, idx) => (
    <li key={idx} className={idx === 0 ? "" : "mt-1"}>
      {e}
    </li>
  ));

  return (
    <div className={`rounded-md bg-red-50 p-4 uh-uh-shake ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <svg
            className="h-5 w-5 text-red-400"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <div className="ml-3">
          <h3 className="text-sm leading-5 font-medium text-red-800">
            There {errors.length === 1 ? "was an" : "were"} error
            {errors.length === 1 ? "" : "s"} with your submission
          </h3>
          {errors && (
            <div className="mt-2 text-sm leading-5 text-red-700">
              <ul className="list-disc pl-5">{errorsList}</ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
