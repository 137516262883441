import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { Stack } from "@cohubinc/blueprint";
import TextField from "src/components/Forms/TextField";
import Button from "src/components/Button";
import Segment from "src/components/Segment";
import Logo from "src/components/Logo";
import Errors from "src/components/Errors";
import { protocol, host } from "src/config";
import { useLocation, useHistory } from "react-router-dom";

export default function NewPassword() {
  const [errors, setErrors] = useState<string[]>([]);
  const [resetToken, setResetToken] = useState<string | undefined>();
  const { search } = useLocation();
  const history = useHistory();
  const required = (value: any) => (value ? undefined : "Required");

  useEffect(() => {
    setResetToken(search.split("=")[1]);
  }, [search]);

  const onSubmit = async (values: any) => {
    const url = `${protocol}://${host}/api/users/new_password`;

    const payload = {
      password: values.password,
      reset_password_token: resetToken
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });

    const result = await response.json();

    if (result.user) {
      // const { authentication_token } = result.user;
      // localStorage.setItem("userAuthToken", authentication_token);
      history.push("/sign-in?pwr=true");
    }
  };

  return (
    <div className="mb-12">
      <Form
        initialValues={{}}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <div className="flex items-center h-3/4">
              <div className="w-full md:w-1/3 max-w-sm  px-4 md:mx-auto">
                <Segment>
                  {!!errors.length && <Errors errors={errors} />}
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="flex justify-center items-center mt-6 mb-12">
                      <Logo width={200} />
                    </div>
                    <Stack fullWidth>
                      <Field
                        name="password"
                        type="password"
                        autoFocus
                        validate={required}
                        render={({ input, meta }) => (
                          <TextField label="New Password" {...input} />
                        )}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        disabled={submitting || pristine}
                      >
                        Set Password
                      </Button>
                    </Stack>
                  </form>
                </Segment>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
