import React, { ReactNode } from "react";

interface IProps {
  children: ReactNode;
  className?: string;
  onClick?: () => void;
}

export default function Well({ children, className, onClick }: IProps) {
  return (
    <div
      className={`overflow-hidden rounded-lg ${className}`}
      onClick={onClick}
    >
      <div className="px-4 py-5 sm:p-6">{children}</div>
    </div>
  );
}
