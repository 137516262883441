import Tippy from "@tippyjs/react";
import capitalize from "lodash/capitalize";
import React, { Fragment, useEffect, useState } from "react";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import {
  IVisitorFragment,
  useListVisitorConversionsLazyQuery,
  useScrubVisitorMutation
} from "src/components/graphql";
import Modal from "src/components/Modal";
import { dateAtTime } from "src/utils/dateAtTime";

interface IProps {
  visitor: IVisitorFragment;
  abTestVariantId?: string;
}

export default function VisitorCard({ visitor, abTestVariantId }: IProps) {
  const [showConfirmScrubData, setShowConfirmScrubData] = useState(false);
  const [scrubVisitorMutation] = useScrubVisitorMutation();
  // const { data } = useListVisitorConversionsQuery({
  //   variables: {
  //     visitorApiId: visitor.apiId,
  //     abTestVariantId: abTestVariantId
  //   }
  // });

  const [getVisitorConversionsData, { data: visitorConversionsData }] =
    useListVisitorConversionsLazyQuery({
      variables: {
        visitorApiId: visitor.apiId,
        abTestVariantId: abTestVariantId
      }
    });

  useEffect(() => {
    if (abTestVariantId) {
      getVisitorConversionsData();
    }
  }, [abTestVariantId, getVisitorConversionsData]);

  const hasConversions = visitorConversionsData?.conversions?.totalCount! > 0;

  const scrubVisitor = async () => {
    const result = await scrubVisitorMutation({
      variables: { visitorId: visitor.id }
    });

    if (result.data?.scrubVisitor?.success) {
      setShowConfirmScrubData(false);
    }
  };

  const visitorMetaMarkup = Object.keys(visitor.meta).map((item) => {
    return (
      <div key={item} className="text-gray-700">
        <span className="text-sm">{capitalize(item)}:&nbsp;</span>
        <span className="text-sm">{visitor.meta[item].toString()}</span>
      </div>
    );
  });

  return (
    <Fragment>
      <div>
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <div className="text-base leading-5 font-medium text-gray-600 truncate">
                  {visitor.name}
                </div>
                <div className="mt-2 flex items-center text-sm leading-5 text-gray-500">
                  <span className="truncate">{visitor.email}</span>
                </div>
              </div>
              <div className="hidden md:block">
                <div>
                  <div className="text-sm leading-5 text-gray-800">
                    Last visited on&nbsp;
                    <time dateTime={dateAtTime(visitor.lastVisitedDate)}>
                      {dateAtTime(visitor.lastVisitedDate)}
                    </time>
                  </div>
                  <div className="mt-2 flex text-sm leading-5 text-gray-500 justify-between">
                    {abTestVariantId ? (
                      <div className="flex items-center">
                        Converted:{" "}
                        <div
                          className={`font-medium ml-2 ${
                            hasConversions ? "text-green-500" : "text-red-500"
                          }`}
                        >
                          {hasConversions ? "Yes" : "No"}
                        </div>
                      </div>
                    ) : (
                      <Fragment>
                        <div>Impressions: {visitor.impressionCount}</div>
                        <div>Conversions: {visitor.conversionCount}</div>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
              {visitor.meta && (
                <div>
                  <div className="text-base text-gray-600">Meta</div>
                  <div>{visitorMetaMarkup}</div>
                </div>
              )}
              {!visitor.scrubbed && (
                <div className="flex items-end justify-end">
                  <div
                    onClick={() => setShowConfirmScrubData(true)}
                    className="text-xs uppercase text-center font-medium text-gray-400 bg-gray-50 hover:bg-red-100 hover:text-red-700 rounded px-2 py-1 cursor-pointer transition ease-in-out duration-150"
                  >
                    Scrub Data
                  </div>
                </div>
              )}
              {visitor.scrubbed && (
                <div className="flex items-end justify-end">
                  <Tippy
                    content="This visitor's data has been anonymized."
                    placement="right"
                    maxWidth={200}
                    className="text-center"
                  >
                    <div>
                      <Badge
                        size="sm"
                        text="SCRUBBED"
                        className="bg-blue-50 text-blue-400"
                      />
                    </div>
                  </Tippy>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showConfirmScrubData}
        onClose={() => setShowConfirmScrubData(false)}
        title={{ title: "Are you sure?" }}
      >
        <div>
          Scrubbing data will erase all information that could be used to
          personally identify this visitor and prevent any future updates to
          this visitor that could personally identify them. A/B test data for
          tests that this visitor has interacted with will not be effected.
        </div>
        <div className="flex justify-around items-center mt-6">
          <Button
            version="plain"
            onClick={() => setShowConfirmScrubData(false)}
          >
            Cancel
          </Button>
          <Button version="danger" onClick={scrubVisitor}>
            Yes, scrub data
          </Button>
        </div>
      </Modal>
    </Fragment>
  );
}
