import React, { Fragment } from "react";

interface IProps {
  name: string;
  label: string;
  labelHidden?: boolean;
  helpText?: string;
  placeholder?: string;
  autoFocus?: boolean;
}

export default function NumberField({
  name,
  label,
  labelHidden = false,
  helpText,
  placeholder,
  autoFocus = false,
  ...rest
}: IProps) {
  return (
    <Fragment>
      <label
        htmlFor={name}
        className={`block text-sm font-medium leading-5 text-gray-700 ${
          labelHidden ? "sr-only" : ""
        }`}
      >
        {label}
      </label>
      <div className="w-full mt-1 rounded-md shadow-sm">
        <input
          id={name}
          name={name}
          className="focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          placeholder={placeholder}
          type="number"
          autoFocus={autoFocus}
          {...rest}
        />
      </div>
      {helpText && <p className="mt-2 text-sm text-gray-500">{helpText}</p>}
      <div className="mb-4" />
    </Fragment>
  );
}
