import React, { Fragment, useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "src/components/Modal";

export default function Pricing() {
  const history = useHistory();
  const [showActiveExplainer, setShowActiveExplainer] = useState(false);

  return (
    <Fragment>
      <div className="relative overflow-hidden">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6">
          <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
            <div className="relative mx-auto w-full sm:w-1/3">
              <div
                className="rounded-lg shadow-lg overflow-hidden"
                style={{ gridColumnStart: "2" }}
              >
                <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6 text-center">
                  <div>
                    <h3
                      className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-blue-100 text-blue-600"
                      id="tier-standard"
                    >
                      Early Adopter Price
                    </h3>
                  </div>
                  <div className="mt-2">
                    <h5
                      className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase text-gray-800"
                      id="tier-standard"
                    >
                      7 day free trial
                    </h5>
                  </div>
                  <div className="mt-4 flex items-baseline text-gray-900 text-6xl leading-none font-extrabold justify-center">
                    $49
                    <span className="ml-1 text-2xl leading-8 font-medium text-gray-500">
                      /mo
                    </span>
                  </div>
                  <p className="mt-5 text-lg leading-7 text-gray-600">
                    + $1 per active A/B test
                  </p>
                  <p
                    className="mt-4 text-sm leading-5 ont-medium text-gray-500 underline cursor-pointer"
                    onClick={() => setShowActiveExplainer(true)}
                  >
                    What is an active A/B test?
                  </p>
                </div>
                <div className="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
                  {/* <ul>
                    <li className="flex items-start">
                      <div className="flex-shrink-0">
                        <IconCheck className="text-green-500" />
                      </div>
                      <p className="ml-3 text-base leading-6 text-gray-700">
                        Unlimited users
                      </p>
                    </li>
                    <li className="mt-4 flex items-start">
                      <div className="flex-shrink-0">
                        <IconCheck className="text-green-500" />
                      </div>
                      <p className="ml-3 text-base leading-6 text-gray-700">
                        Set up in minutes
                      </p>
                    </li>
                    <li className="mt-4 flex items-start">
                      <div className="flex-shrink-0">
                        <IconCheck className="text-green-500" />
                      </div>
                      <p className="ml-3 text-base leading-6 text-gray-700">
                        Real time data
                      </p>
                    </li>
                  </ul> */}
                  <div
                    className="mt-6 rounded-md shadow cursor-pointer"
                    onClick={() => history.push("/sign-up")}
                  >
                    <span
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-blue-900 hover:bg-blue-600 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                      aria-describedby="tier-standard"
                    >
                      Get Started
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={showActiveExplainer}
        showCloseIcon
        onClose={() => setShowActiveExplainer(false)}
        title={{ title: "Active A/B tests", alignment: "text-center" }}
      >
        <div className="max-w-lg">
          <p>
            An active A/B test is any test that has seen at least one visitor
            during the current billing period.
          </p>
          <p />
        </div>
      </Modal>
    </Fragment>
  );
}
