import React, { useState, useEffect, Fragment } from "react";
import copyToClipboard from "src/utils/copyToClipboard";
import CodeSnippet from "src/components/CodeSnippet";
import Button from "src/components/Button";

interface IProps {
  snippet: string;
}

export default function AbTestCodeSnippet({ snippet }: IProps) {
  const [copiedSnippet, setCopiedSnippet] = useState(false);

  useEffect(() => {
    if (copiedSnippet) {
      setTimeout(() => {
        setCopiedSnippet(false);
      }, 3_000);
    }
  }, [copiedSnippet]);

  function handleCopyToClipBoard() {
    setCopiedSnippet(true);
    copyToClipboard(snippet);
  }

  const copyButton = () => (
    <Button
      type="button"
      version="secondary"
      size="sm"
      onClick={() => handleCopyToClipBoard()}
    >
      {copiedSnippet ? "Copied!" : "Copy"}
    </Button>
  );

  return (
    <Fragment>
      <div className="cursor-pointer" onClick={() => copyToClipboard(snippet)}>
        <CodeSnippet code={snippet} language="jsx" />
      </div>
      <div className="mt-4 flex justify-end items-center">{copyButton()}</div>
    </Fragment>
  );
}
