import React from "react";
import { Switch, Route } from "react-router-dom";
import DocumentationLink from "src/components/DocumentationLink";
import GettingStarted from "./GettingStarted";
import UsingPreferr from "./UsingPreferr";
import IdentifyingVisitors from "./IdentifyingVisitors";
import CustomConversions from "./CustomConversions";
import DosDonts from "./DosDonts";
import HowItWorks from "./HowItWorks";
import Url from "src/utils/Url";
import Troubleshooting from "./Troubleshooting";
import LocalStorageInfo from "./LocalStorageInfo";

export default function Documentation() {
  return (
    <div className="flex mb-4">
      <div className="w-1/6">
        <nav className="sticky" style={{ top: "104px" }}>
          <DocumentationLink
            linkTo={Url.documentationHowItWorksPath()}
            title="How it works"
          />
          <DocumentationLink
            linkTo="/documentation/getting-started"
            title="Getting started"
          />
          <DocumentationLink
            linkTo="/documentation/using-preferr"
            title="Using Preferr"
          />
          <DocumentationLink
            linkTo="/documentation/identifying-visitors"
            title="Identifying visitors"
          />
          <DocumentationLink
            linkTo={Url.documentationConversionsPath()}
            title="Conversions"
          />
          <DocumentationLink
            linkTo="/documentation/dos-and-donts"
            title="Do's and Don'ts"
          />
          <DocumentationLink
            linkTo="/documentation/troubleshooting"
            title="Troubleshooting"
          />
          <DocumentationLink
            linkTo="/documentation/local-storage"
            title="Use of localStorage"
          />
        </nav>
      </div>
      <div className="w-5/6 ml-10">
        <Switch>
          <Route exact path="/documentation">
            <HowItWorks />
          </Route>
          <Route path="/documentation/getting-started">
            <GettingStarted />
          </Route>
          <Route path="/documentation/using-preferr">
            <UsingPreferr />
          </Route>
          <Route path="/documentation/identifying-visitors">
            <IdentifyingVisitors />
          </Route>
          <Route path="/documentation/conversions">
            <CustomConversions />
          </Route>
          <Route path="/documentation/dos-and-donts">
            <DosDonts />
          </Route>
          <Route path="/documentation/troubleshooting">
            <Troubleshooting />
          </Route>
          <Route path="/documentation/local-storage">
            <LocalStorageInfo />
          </Route>
        </Switch>
      </div>
    </div>
  );
}
