import React from "react";

interface IProps {
  text?: string;
  className?: string;
  size?: "sm" | "md" | "lg";
}

export default function Badge({ text, className, size = "md" }: IProps) {
  const paddingSize = () => {
    switch (size) {
      case "sm":
        return "px-2 py-0.5 text-xs";
      case "md":
        return "px-3 py-0.5 text-sm leading-5";
      case "lg":
        return "px-4 py-0.5 text-base leading 6";
      default:
        return "px-3 py-0.5";
    }
  };

  return (
    <span
      className={`inline-flex items-center rounded font-medium ${className} ${paddingSize()}`}
    >
      {text}
    </span>
  );
}
