import moment from "moment";

interface IProps {
  dateTime: any;
  onlyTime?: boolean;
  onlyDate?: boolean;
}

export function dateAtTime(
  dateTime: any,
  onlyTime?: boolean,
  onlyDate?: boolean
) {
  const datePart = moment(dateTime).format("l");
  const timePart = moment(dateTime).format("h:mm a");

  const parsedDateTime = () => {
    if (onlyTime) {
      return timePart;
    } else if (onlyDate) {
      return datePart;
    } else {
      return `${datePart} at ${timePart}`;
    }
  };

  return parsedDateTime();
}
