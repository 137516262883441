import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "src/components/Logo";
import Transition from "src/components/Transition";

export default function MarketingNav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <Fragment>
      <div className="max-w-screen-xl px-4 pt-6 pb-2 mx-auto bg-transparent sm:px-6">
        <nav className="relative flex items-center justify-between p-4 rounded-lg sm:h-16">
          <div className="flex items-center justify-between w-full md:w-auto">
            <Link to={"/"} aria-label="Home">
              <div
                className="px-4 py-2 rounded-lg"
                style={{
                  backgroundColor: "rgba(255,255,255, 0.15)",
                  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)"
                }}
              >
                <Logo width={120} color="#F9FAFB" />
              </div>
            </Link>
            <div className="flex items-center md:-mr-2 md:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 text-gray-100 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                id="main-menu"
                aria-label="Main menu"
                aria-haspopup="true"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <svg
                  className="w-6 h-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="hidden md:block">
            {/* <HashLink
              smooth
              to="/#features"
              className="px-4 py-2 mr-5 font-medium transition duration-150 ease-in-out rounded-md text-gray-50 hover:bg-blue-600"
            >
              Features
            </HashLink> */}
            <Link
              to="/pricing"
              className="px-4 py-2 mx-5 font-medium transition duration-150 ease-in-out rounded-md text-gray-50 hover:bg-blue-600"
            >
              Pricing
            </Link>
            <Link
              to="/demo"
              className="px-4 py-2 mx-5 font-medium transition duration-150 ease-in-out rounded-md text-gray-50 hover:bg-blue-600"
            >
              Demo
            </Link>
            <Link
              to="/about"
              className="px-4 py-2 mx-5 font-medium transition duration-150 ease-in-out rounded-md text-gray-50 hover:bg-blue-600"
            >
              About
            </Link>
            {/* <a
              target="blank"
              rel="noopener noreferrer"
              href="https://blog.preferr.app"
              className="px-4 py-2 mx-5 font-medium transition duration-150 ease-in-out rounded-md text-gray-50 hover:bg-blue-600"
            >
              Blog
            </a> */}
            <Link
              to="/documentation"
              className="px-4 py-2 ml-5 font-medium transition duration-150 ease-in-out rounded-md text-gray-50 hover:bg-blue-600"
              rel="noopener noreferrer"
              target="_blank"
            >
              Docs
            </Link>
          </div>
          <div className="hidden md:flex md:items-center md:justify-end">
            <span className="inline-flex rounded-md shadow">
              <Link
                to="/sign-up"
                className="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-blue-500 border border-transparent rounded-md hover:bg-blue-400 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-blue-700"
              >
                Get Started
              </Link>
            </span>
          </div>
        </nav>
      </div>
      {/* <!--
      Mobile menu, show/hide based on menu open state.

      Entering: "duration-150 ease-out"
        From: "opacity-0 scale-95"
        To: "opacity-100 scale-100"
      Leaving: "duration-100 ease-in"
        From: "opacity-100 scale-100"
        To: "opacity-0 scale-95"
    --> */}
      <Transition
        show={mobileMenuOpen}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div className="absolute inset-x-0 top-0 z-10 p-2 transition origin-top-right transform md:hidden">
          <div className="rounded-lg shadow-md">
            <div
              className="overflow-hidden bg-white rounded-lg shadow-xs"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="main-menu"
            >
              <div className="flex items-center justify-between px-5 pt-4">
                <div>
                  <Logo width={120} color="#374151" />
                </div>
                <div className="md:-mr-2">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500"
                    aria-label="Close menu"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <svg
                      className="w-6 h-6"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3">
                {/* <HashLink
                  smooth
                  to="/#features"
                  className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  role="menuitem"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Features
                </HashLink> */}
                <Link
                  to="/pricing"
                  className="block px-3 py-2 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  role="menuitem"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Pricing
                </Link>
                <Link
                  to="/demo"
                  className="block px-3 py-2 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  role="menuitem"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Demo
                </Link>
                <Link
                  to="/about"
                  className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  role="menuitem"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  About
                </Link>
                {/* <a
                  href="https://blog.preferr.app"
                  className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  role="menuitem"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Blog
                </a> */}
                <Link
                  to="/documentation"
                  className="block px-3 py-2 mt-1 text-base font-medium text-gray-700 transition duration-150 ease-in-out rounded-md hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50"
                  role="menuitem"
                  onClick={() => setMobileMenuOpen(false)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Docs
                </Link>
              </div>
              <div>
                <Link
                  to="/sign-up"
                  className="block w-full px-5 py-3 font-medium text-center text-blue-600 transition duration-150 ease-in-out bg-gray-50 hover:bg-gray-100 hover:text-blue-700 focus:outline-none focus:bg-gray-100 focus:text-blue-700"
                  role="menuitem"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Fragment>
  );
}
