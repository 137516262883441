import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { Stack } from "@cohubinc/blueprint";
import TextField from "src/components/Forms/TextField";
import Button from "src/components/Button";
import Segment from "src/components/Segment";
import Logo from "src/components/Logo";
import Errors from "src/components/Errors";
import { protocol, host } from "src/config";
import { useLocation, useHistory } from "react-router-dom";
import { authenticate } from "src/utils/authentication";
import useSession from "src/hooks/useSession";

export default function AcceptInvitation() {
  const [errors, setErrors] = useState<string[]>([]);
  const [resetToken, setResetToken] = useState<string>("");
  const { logIn } = useSession();
  const { search } = useLocation();
  const history = useHistory();
  const required = (value: any) => (value ? undefined : "Required");

  useEffect(() => {
    setResetToken(search.split("=")[1]);
  }, [search]);

  // const onSubmit = async (values: any) => {
  //   const result = await acceptInvitationMutation({
  //     variables: {
  //       password: values.password,
  //       token: resetToken
  //     },
  //     refetchQueries: ["ListAbTests"]
  //   });

  //   if (
  //     result.data?.acceptInvitation?.success &&
  //     result.data?.acceptInvitation?.user
  //   ) {
  //     localStorage.setItem(
  //       "userEmail",
  //       result.data?.acceptInvitation?.user.email
  //     );
  //     localStorage.setItem(
  //       "userAuthToken",
  //       result.data?.acceptInvitation?.user?.authenticationToken
  //     );
  //     history.push("/");
  //   }
  // };
  const checkCredentials = React.useCallback(
    async ({ email, password }) => {
      setErrors([]);
      const { user, errors } = await authenticate(email, password);

      if (errors) {
        setErrors(errors);
        return;
      }

      logIn(user!);
      history.push("/ab-tests");
      window.location.reload();
    },
    [history, logIn]
  );

  const onSubmit = async (values: any) => {
    const url = `${protocol}://${host}/api/users/accept_invitation`;

    const payload = {
      password: values.password,
      reset_password_token: resetToken
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });

    const result = await response.json();

    if (result.user) {
      checkCredentials({ email: result.user.email, password: values.password });
    } else if (result.errors) {
      setErrors(result.errors);
    }
  };

  return (
    <div className="mb-12">
      <Form
        initialValues={{}}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <div className="flex items-center h-3/4">
              <div className="w-full md:w-1/3 max-w-sm  px-4 md:mx-auto">
                <Segment>
                  {!!errors.length && <Errors errors={errors} />}
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="flex justify-center items-center mt-6 mb-8">
                      <Logo width={140} />
                    </div>
                    <div className="flex items-center justify-center text-2xl text-gray-700 mb-6">
                      Welcome to Preferr!
                    </div>
                    <Stack fullWidth>
                      <Field
                        name="password"
                        type="password"
                        autoFocus
                        validate={required}
                        render={({ input, meta }) => (
                          <TextField label="New Password" {...input} />
                        )}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        disabled={submitting || pristine}
                      >
                        Set Password
                      </Button>
                    </Stack>
                  </form>
                </Segment>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
