import React, { useState } from "react";
import {
  IUserFragment,
  useResendInvitationMutation
} from "src/components/graphql";
import Card from "src/components/Card";
import { IconEdit } from "src/components/Icons/IconEdit";
import Button from "src/components/Button";

interface IProps {
  user: Omit<IUserFragment, "organization">;
  onAction: () => void;
}

export default function UserCard({ user, onAction }: IProps) {
  const [inviteResent, setInviteResent] = useState(false);

  const [resendInvitationMutation] = useResendInvitationMutation();
  const resendInvitation = async (userId: string) => {
    const result = await resendInvitationMutation({
      variables: {
        userId
      }
    });

    if (result.data?.resendInvitation?.success) {
      setInviteResent(true);
    }
  };

  return (
    <Card
      key={user.id}
      title={user.nameOrEmail}
      titleBadge={`${user.invited ? "Invited" : ""}`}
      description={user.email}
      className="mb-8"
      titleAction={{
        content: (
          <div className="flex items-start cursor-pointer">
            <div className="text-xs font-bold text-gray-500 uppercase">
              Change
            </div>
            <IconEdit size={18} className="ml-1 text-gray-400" />
          </div>
        ),
        onAction: () => {
          onAction();
        }
      }}
    >
      {user.invited && (
        <div className="flex justify-end mb-4">
          <Button
            version="secondary"
            onClick={() => resendInvitation(user.id)}
            disabled={inviteResent}
          >
            {inviteResent ? "Invite Resent!" : "Resend Invite"}
          </Button>
        </div>
      )}
    </Card>
  );
}
