import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "src/components/Button";
import {
  useFindAbTestVariantQuery,
  useListVisitorsLazyQuery
} from "src/components/graphql";
import { IconChevronLeft } from "src/components/Icons/IconChevronLeft";
import Loader from "src/components/Loader";
import Segment from "src/components/Segment";
import Url from "src/utils/Url";
import { useDebounce } from "use-debounce/lib";
import VisitorCard from "./VisitorCard";

export default function AbTestVariant() {
  const [searchValue, setSearchValue] = useState<undefined | string>();
  const [q] = useDebounce(searchValue, 500);
  const { apiId } = useParams<any>();
  // const { path } = useRouteMatch();

  const { loading, data } = useFindAbTestVariantQuery({
    variables: {
      apiId
    }
  });

  const [
    getVisitorData,
    { loading: visitorsLoading, data: visitorsData, fetchMore }
  ] = useListVisitorsLazyQuery({
    variables: {
      abTestVariantId: data?.abTestVariant?.id,
      q,
      first: 10
    }
  });

  useEffect(() => {
    if (!loading && data?.abTestVariant?.id) {
      getVisitorData();
    }
  }, [data, getVisitorData, loading]);

  const variant = data?.abTestVariant;

  if (loading && !data) {
    return <Loader fullScreen />;
  }

  if (!data) {
    return null;
  }

  if (!visitorsData) {
    return null;
  }

  if (visitorsLoading) {
    return null;
  }

  if (!fetchMore) {
    return null;
  }

  // if (!called) {
  //   return null;
  // }

  // const variant = data.abTestVariant;

  const visitors = visitorsData?.visitors.edges.map((edge) => edge.node!) || [];

  const visitorsList = visitors?.map((v) => (
    <Segment key={v.apiId} className="mb-4">
      <VisitorCard visitor={v} abTestVariantId={variant?.id} />
    </Segment>
  ));

  return (
    <div className="max-w-2xl mr-auto ml-auto">
      <div className="mb-4">
        <h2 className="text-2xl font-bold leading-7 text-gray-600 sm:text-3xl sm:leading-9 sm:truncate mb-2">
          {variant?.variantDesignation}
        </h2>
        <div className="flex items-center">
          <IconChevronLeft className="text-gray-400" />
          <Link
            to={Url.abTestPath(variant?.abTestApiId!)}
            className="text-gray-400 font-semibold"
          >
            Back to test
          </Link>
        </div>
      </div>
      <div className="mb-4">
        <label
          htmlFor="visitorSearch"
          className={`block text-sm font-medium leading-5 text-gray-700  sr-only`}
        >
          Search
        </label>
        <div className="mt-1 rounded-md shadow-sm w-full">
          <input
            id={"visitorSearch"}
            className="focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
            placeholder="Find visitors by name, email, or uid"
            type="text"
            aria-describedby="visitor-search"
            autoComplete="off"
            // value={searchValue}
            onChange={(evt) => setSearchValue(evt.target.value)}
          />
        </div>
      </div>
      {/* <List items={[{}]} /> */}
      {visitorsList}
      <nav className="px-4 py-3 flex items-center justify-center sm:px-6">
        {/* <div className="hidden sm:block">
          <p className="text-sm leading-5 text-gray-700">
            Showing&nbsp;
            <span className="font-medium">1&nbsp;</span>
            to&nbsp;
            <span className="font-medium">10&nbsp;</span>
            of&nbsp;
            <span className="font-medium">
              {data.visitors.totalCount}&nbsp;
            </span>
            results
          </p>
        </div> */}
        <div className="flex-1 flex justify-center items-center">
          <Button
            version="plain"
            className="mr-4"
            disabled={!visitorsData.visitors.pageInfo.hasPreviousPage}
            onClick={() =>
              fetchMore({
                variables: {
                  before: visitorsData.visitors.pageInfo.startCursor
                },
                updateQuery: (previousResult: any, { fetchMoreResult }) => {
                  const newEdges = fetchMoreResult!.visitors.edges;
                  const pageInfo = fetchMoreResult!.visitors.pageInfo;

                  return newEdges.length
                    ? {
                        // Put the new comments at the end of the list and update `pageInfo`
                        // so we have the new `endCursor` and `hasNextPage` values
                        visitors: {
                          __typename: previousResult.visitors.__typename,
                          edges: [...newEdges],
                          pageInfo
                        }
                      }
                    : previousResult;
                }
              })
            }
          >
            Previous
          </Button>
          <Button
            version="plain"
            disabled={!visitorsData.visitors.pageInfo.hasNextPage}
            onClick={() =>
              fetchMore({
                variables: {
                  after: visitorsData.visitors.pageInfo.endCursor
                },
                updateQuery: (previousResult: any, { fetchMoreResult }) => {
                  const newEdges = fetchMoreResult!.visitors.edges;
                  const pageInfo = fetchMoreResult!.visitors.pageInfo;

                  return newEdges.length
                    ? {
                        // Put the new comments at the end of the list and update `pageInfo`
                        // so we have the new `endCursor` and `hasNextPage` values
                        visitors: {
                          __typename: previousResult.visitors.__typename,
                          edges: [...newEdges],
                          pageInfo
                        }
                      }
                    : previousResult;
                }
              })
            }
          >
            Next
          </Button>
        </div>
        {/* <div className="flex-1 flex justify-between items-center sm:justify-end">
          <Button
            version={"plain"}
            onClick={() =>
              fetchMore({
                variables: {
                  after: data.visitors.pageInfo.endCursor
                },
                updateQuery: (previousResult: any, { fetchMoreResult }) => {
                  const newEdges = fetchMoreResult!.visitors.edges;
                  const pageInfo = fetchMoreResult!.visitors.pageInfo;

                  return newEdges.length
                    ? {
                        // Put the new comments at the end of the list and update `pageInfo`
                        // so we have the new `endCursor` and `hasNextPage` values
                        visitors: {
                          __typename: previousResult.visitors.__typename,
                          edges: [
                            ...previousResult.visitors.edges,
                            ...newEdges
                          ],
                          pageInfo
                        }
                      }
                    : previousResult;
                }
              })
            }
          >
            Load More
          </Button>
        </div> */}
      </nav>
    </div>
  );
}
// yarn add -D tailwindcss@npm:@tailwindcss/postcss7-compat postcss@^7 autoprefixer@^9
