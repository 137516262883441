import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Tippy from "@tippyjs/react";
import capitalize from "lodash/capitalize";
import React, { Fragment, useContext, useState } from "react";
import NumberFormat from "react-number-format";
import Alert from "src/components/Alert";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import Segment from "src/components/Segment";
import SideSheet from "src/components/SideSheet";
import { AuthContext } from "src/contexts/AuthContext";
import { dateAtTime } from "src/utils/dateAtTime";
import BillingEmailForm from "./BillingEmailForm";
import PaymentMethodUpdateForm from "./PaymentMethodUpdateForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

export default function Billing() {
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [showBillingEmailForm, setShowBillingEmailForm] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const { currentOrganization } = useContext(AuthContext);

  const cardDetails = `${capitalize(
    currentOrganization?.billingSubscription?.creditCardBrand
  )} - ${currentOrganization?.billingSubscription?.creditCardLast4}`;

  return (
    <Fragment>
      {showAlert && (
        <div className="w-2/3 mx-auto mb-4">
          <Alert
            version="success"
            title="Payment method updated"
            onDismiss={() => setShowAlert(false)}
          />
        </div>
      )}
      <div className="flex items-center justify-between w-2/3 mx-auto mb-8">
        <div className="flex-1 min-w-0">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div>
                <h2 className="mr-4 text-2xl font-bold leading-7 text-gray-400 sm:text-3xl sm:leading-9 sm:truncate">
                  Billing
                </h2>
              </div>
              {currentOrganization?.earlyAdopter && (
                <Tippy
                  content="You're an early adopter of Preferr! Thanks for helping test it out."
                  placement="right"
                  maxWidth={200}
                  className="text-center"
                >
                  <div>
                    <Badge
                      size="md"
                      text="Early Adopter"
                      className="text-green-500 bg-green-100"
                    />
                  </div>
                </Tippy>
              )}
            </div>
            <Button
              version="plain"
              onClick={() => setShowBillingEmailForm(!showBillingEmailForm)}
            >
              Update Billing Email
            </Button>
          </div>
          <div className="mt-2 text-xs text-gray-500">
            Billing related emails will go to{" "}
            {currentOrganization?.billingEmail ||
              "no one! Please update your billing email."}
          </div>
        </div>
      </div>
      <div className="w-2/3 mx-auto mb-8">
        <Segment>
          <div className="flex items-start justify-between">
            <div>
              <div className="text-xs font-semibold text-gray-400 uppercase">
                Current Plan
              </div>
              <div className="text-xl text-gray-800">
                {currentOrganization?.billingSubscription?.planName}
              </div>
            </div>
            <div>
              <div className="text-xs font-semibold text-gray-400 uppercase">
                Current Card
              </div>
              <div
                className="text-xl text-gray-800 cursor-pointer"
                onClick={() => setShowPaymentForm(!showPaymentForm)}
              >
                {cardDetails}
                <div className="text-xs font-bold text-gray-400 uppercase transition duration-75 ease-in-out hover:text-gray-700">
                  Change
                </div>
              </div>
            </div>
            <div>
              <div className="text-xs font-semibold text-gray-400 uppercase">
                Current Billing Period
              </div>
              <div className="text-xl text-gray-800">
                {dateAtTime(
                  currentOrganization?.billingSubscription?.currentPeriodStart,
                  false,
                  true
                )}
                &nbsp;to&nbsp;
                {dateAtTime(
                  currentOrganization?.billingSubscription?.currentPeriodEnd,
                  false,
                  true
                )}
              </div>
            </div>
          </div>
        </Segment>
      </div>
      <div className="grid w-2/3 grid-cols-3 gap-8 mx-auto">
        <Segment className="mb-8">
          <div className="text-xs font-semibold text-gray-400 uppercase">
            Active Tests This Period
          </div>
          <div className="text-xl text-gray-800">
            {currentOrganization?.activeAbTestsCount}
          </div>
        </Segment>
        <Segment className="mb-8">
          <div className="text-xs font-semibold text-gray-400 uppercase">
            Upcoming Invoice Amount
          </div>
          <div className="text-xl text-gray-800">
            <NumberFormat
              value={
                currentOrganization?.billingSubscription?.upcomingInvoiceTotal
              }
              prefix="$"
              displayType="text"
            />
          </div>
        </Segment>
        {/* <Segment className="mb-8">
          <div className="text-xs font-semibold text-gray-400 uppercase">
            Billing Email
          </div>
          <div className="text-xl text-gray-800">
            {currentOrganization?.billingEmail}
          </div>
        </Segment> */}
      </div>
      <SideSheet
        open={showPaymentForm}
        onClose={() => setShowPaymentForm(false)}
      >
        <Elements stripe={stripePromise}>
          <PaymentMethodUpdateForm
            onSuccess={() => {
              setShowPaymentForm(false);
              setShowAlert(true);
            }}
          />
        </Elements>
      </SideSheet>
      <SideSheet
        open={showBillingEmailForm}
        onClose={() => setShowBillingEmailForm(false)}
      >
        <BillingEmailForm onSuccess={() => setShowBillingEmailForm(false)} />
      </SideSheet>
    </Fragment>
  );
}
