import React, { useContext, Fragment, useState } from "react";
import { AuthContext } from "src/contexts/AuthContext";
import { Space } from "@cohubinc/blueprint";
import Segment from "src/components/Segment";
import { IconEdit } from "src/components/Icons/IconEdit";
import SideSheet from "src/components/SideSheet";
import UserForm from "./UserForm";

export default function Account() {
  const { currentUser } = useContext(AuthContext);
  const [showUserForm, setShowUserForm] = useState(false);

  const hasName = currentUser?.name;

  return (
    <Fragment>
      <div className="w-2/3 flex justify-between ml-auto mr-auto">
        <div className="w-2/3 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <div className="flex items-center">
              <h2 className="text-2xl font-bold leading-7 text-gray-400 sm:text-3xl sm:leading-9 sm:truncate">
                Account
              </h2>
              {/* <IconEdit className="ml-4 text-blue-600" /> */}
            </div>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            {/* <Button version={"plain"} onClick={() => setConfirmDelete(true)}>
              Delete
            </Button> */}
          </div>
        </div>
      </div>
      <Space height={2} />
      <div className="flex justify-center">
        <div className="w-2/3 ml-auto mr-auto">
          <Segment className="w-full">
            <div className="flex justify-between">
              <div>
                <h3
                  className={`text-xl mb-2 ${
                    !hasName && "text-gray-400 cursor-pointer"
                  }`}
                  onClick={() => setShowUserForm(true)}
                >
                  {hasName || "Add your name"}
                </h3>
                <h4 className="">{currentUser?.email}</h4>
              </div>
              <div
                className="flex items-start cursor-pointer"
                onClick={() => setShowUserForm(true)}
              >
                <div className="uppercase font-bold text-xs text-gray-500">
                  Change
                </div>
                <IconEdit size={18} className="ml-1 text-gray-400" />
              </div>
            </div>
          </Segment>
        </div>
      </div>
      <SideSheet
        open={showUserForm}
        title="Update user"
        onClose={() => setShowUserForm(false)}
      >
        <UserForm
          onSuccess={() => setShowUserForm(false)}
          user={currentUser!}
        />
      </SideSheet>
    </Fragment>
  );
}
