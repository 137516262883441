import React from "react";
import { TIcon } from "src/definitions/TIcon";

export function IconCheckCircle({
  size = 24,
  color = "#1956DB",
  className,
  style,
  onClick
}: TIcon) {
  return (
    <svg
      fill={color}
      viewBox="0 0 20 20"
      width={size}
      height={size}
      className={`fill-current ${className}`}
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      />
    </svg>
  );
}
