import React from "react";
import { TIcon } from "src/definitions/TIcon";

export function IconTrendingUp({
  size = 24,
  color = "#1956DB",
  className,
  style,
  onClick
}: TIcon) {
  return (
    <svg
      fill={color}
      viewBox="0 0 20 20"
      width={size}
      height={size}
      className={`fill-current ${className}`}
      style={style}
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        d="M12 7a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0V8.414l-4.293 4.293a1 1 0 01-1.414 0L8 10.414l-4.293 4.293a1 1 0 01-1.414-1.414l5-5a1 1 0 011.414 0L11 10.586 14.586 7H12z"
        clipRule="evenodd"
      />
    </svg>
  );
}
