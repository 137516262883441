import React, { Fragment } from "react";
import Well from "src/components/Well";
import Circle from "src/components/Circle";
import CodeSnippet from "src/components/CodeSnippet";

export default function Troubleshooting() {
  return (
    <Fragment>
      <h4 className="text-3xl mb-8">Troubleshooting</h4>
      <div className="mb-10">
        <div className="text-2xl mb-4 flex items-center">
          <Circle diameter={10} color="#2E7FEC" className="mr-2" />
          Variants aren't appearing when viewing an A/B test page
        </div>
        <div className="ml-5">
          <p className="mb-4">
            When viewing your A/B test, one or both of your variants may show a
            message like the one below instead of what the variant looks like.
          </p>
          <Well className="mb-4">
            "Looks like we had trouble determining the HTML for this variant.
            For more information about how to avoid this, please see the
            troubleshooting section of the docs."
          </Well>
          <p className="mb-4">
            This means that Preferr was unable to find the DOM node that
            corresponds with the variant rendered to one of your visitors.
          </p>
          <p className="font-semibold mb-4">
            Data is still being recorded for this test and the presentation to
            your visitor is unaffected.
          </p>
          <p className="mb-4">
            To correct this problem, you may be able to add a{" "}
            <CodeSnippet bare code="rest" inline /> prop on the component you're
            trying to test, and then spreading that rest prop at the root parent
            node in your component.
          </p>
          <p className="mb-4">
            Some React components may prevent extra props from being added, and
            Preferr relies on these extra props to identify the DOM node that
            React rendered. Without these props, the styling and markup for your
            component won't be recorded, but the conversion data will be.
          </p>
        </div>
      </div>
    </Fragment>
  );
}
