import React, { ReactNode } from "react";
import Transition from "./Transition";
import { IconX } from "./Icons/IconX";

interface IProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
}

export default function SideSheet({ open, title, children, onClose }: IProps) {
  return (
    <div className={open ? "fixed inset-0 overflow-hidden" : ""}>
      <Transition
        show={open}
        enter="ease-in-out duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in-out duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div
          className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          onClick={onClose}
        />
      </Transition>

      <section className="absolute inset-y-0 right-0 max-w-full flex">
        <Transition
          show={open}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="w-screen h-screen max-w-md">
            <div className="h-full flex flex-col space-y-6 py-6 bg-white shadow-xl overflow-y-scroll">
              <header className="px-4 sm:px-6">
                <div className="flex items-start justify-between space-x-3">
                  <h2 className="text-lg leading-7 font-medium text-gray-900">
                    {title}
                  </h2>
                  <div
                    className="h-7 flex items-center cursor-pointer"
                    onClick={onClose}
                  >
                    <button
                      aria-label="Close panel"
                      className="text-gray-400 hover:text-gray-500 transition ease-in-out duration-150 cursor-pointer"
                    >
                      <IconX />
                    </button>
                  </div>
                </div>
              </header>
              <div className="relative flex-1 px-4 sm:px-6">
                {/* <!-- Replace with your content -->
            <div className="absolute inset-0 px-4 sm:px-6">
              <div className="h-full border-2 border-dashed border-gray-200"></div>
            </div>
            <!-- /End replace --> */}
                {children}
              </div>
            </div>
          </div>
        </Transition>
      </section>
    </div>
  );
}
