import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { IconChevronRight } from "src/components/Icons/IconChevronRight";
import Url from "src/utils/Url";
import CodeSnippet from "src/components/CodeSnippet";

export default function HowItWorks() {
  return (
    <Fragment>
      <h4 className="text-3xl mb-8">How it works</h4>
      <div className="mb-10">
        <div className="text-xl font-semibold mb-4">A/B tests</div>
        <p className="mb-4">
          Each time you create an A/B Test in Preferr, a React component is
          generated. You'll use this component to wrap two child components,
          which serve as the variants for your test.
        </p>
        <p className="mb-4">
          The A/B test component randomly renders one of its two children, and
          then begins recording impressions and conversions for that component.
        </p>
        <p className="mb-4">
          Even through React rerenders frequently, Preferr will persist the
          "winning" component for each of your visitors so that they don't see a
          UI that is constantly changing.
        </p>
      </div>
      <div className="mb-10">
        <div className="text-xl font-semibold mb-4">Visitors</div>
        <p className="mb-4">
          A visitor will be presented with one of the variants of your A/B Tests
          as they navigate and interact with your site or app.
        </p>
        <p className="mb-4">
          It's strongly recommended that you identify your visitors with extra
          information like a name and email address using Preferr's identify
          function. Doing so will allow you to glean better insights into what
          your visitors prefer in your UI.
        </p>
      </div>
      <div className="mb-10">
        <div className="text-xl font-semibold mb-4">Impressions</div>
        <p className="mb-4">
          An impression is recorded everytime a visitor encounters one of your
          A/B Tests. The variant that they see increments its impressions and
          that impression is tied to the visitor.
        </p>
      </div>
      {/* <div className="mb-10">
        <div className="text-xl font-semibold mb-4">Clicks</div>
        <p className="mb-4">
          Clicks are one of the most commonly measured types of conversions when
          it comes to A/B testing. Preferr records a click any time a visitor
          clicks on a rendered variant from an A/B Test.
        </p>
        <p className="mb-4">
          Preferr won't override any click events or actions that you may be
          using on your components. It works alongside them so you're free to
          use click events as you normally would.
        </p>
      </div> */}
      <div className="mb-10">
        <div className="text-xl font-semibold mb-4">Conversions</div>
        <p className="mb-4">
          A/B testing isn't only about impressions and clicks. Preferr lets you
          record conversions any time you'd like using the function{" "}
          <CodeSnippet bare inline code="Preferr.convert()" />. This allows you
          to handle more complex testing scenarios like how much time a visitor
          spends on a page. Of course, you can also call convert to track button
          clicks or form submissions as well.
        </p>
      </div>
      <div className="flex justify-end items-center">
        <Link
          to={Url.documentationGettingStartedPath()}
          className="bg-blue-100 py-2 px-4 rounded-lg"
        >
          <div className="flex items-center text-blue-900">
            Getting started
            <IconChevronRight className="ml-2" />
          </div>
        </Link>
      </div>
    </Fragment>
  );
}
