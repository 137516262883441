import React, { Fragment } from "react";
import Features from "./Features";
import HomeHero from "./HomeHero";
import SideBySide from "./SideBySide";
import CTA from "./CTA";

export default function Home() {
  return (
    <Fragment>
      <div className="relative overflow-hidden">
        {/* <div className="relative h-full max-w-screen-xl mx-auto" /> */}

        <div className="mx-auto max-w-screen-xl px-4 sm:px-6">
          <HomeHero />
          <Features />
          <SideBySide />
          <CTA />
        </div>
      </div>
    </Fragment>
  );
}
