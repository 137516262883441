import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { Fragment, useState } from "react";
import Modal from "src/components/Modal";
import Segment from "src/components/Segment";
import Well from "src/components/Well";
import PaymentSetupForm from "../Billing/PaymentSetupForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

interface IProps {
  onSuccess: () => void;
}

export default function PaymentSetup({ onSuccess }: IProps) {
  const [showActiveExplainer, setShowActiveExplainer] = useState(false);

  return (
    <Fragment>
      <div className="w-1/3 mx-auto mb-8">
        <Segment>
          <div className="flex items-center justify-center">
            <div className="text-2xl text-gray-500">Preferr Subscription</div>
          </div>
          <div className="flex items-center justify-around mt-8 mb-2">
            <Well className={`border-blue-200 border-solid border-2 `}>
              <div className="flex items-baseline text-2xl text-gray-700 leading-none font-extrabold justify-center">
                $49
                <span className="ml-1 text-2xl leading-8 font-medium text-gray-500">
                  /mo
                </span>
              </div>
              <p className="mt-5 text-base leading-7 text-gray-500">
                + $1 per active A/B test
              </p>
            </Well>
          </div>
          <p
            className="mb-8 text-sm leading-5 ont-medium text-center text-gray-500 underline cursor-pointer"
            onClick={() => setShowActiveExplainer(true)}
          >
            What is an active A/B test?
          </p>
          <Elements stripe={stripePromise}>
            <PaymentSetupForm onSuccess={onSuccess} />
          </Elements>
        </Segment>
      </div>
      <Modal
        open={showActiveExplainer}
        onClose={() => setShowActiveExplainer(false)}
        title={{ title: "Active A/B tests", alignment: "text-center" }}
      >
        <div className="max-w-lg">
          <p>
            An active A/B test is any test that has seen at least one visitor
            during the current billing period.
          </p>
          <p />
        </div>
      </Modal>
    </Fragment>
  );
}
