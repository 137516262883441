import React, { Fragment, useState } from "react";
import { Field, withTypes } from "react-final-form";
import {
  useUpdateUserMutation,
  IUserAttributes,
  IUserFragment
} from "src/components/graphql";
import TextField from "src/components/Forms/TextField";
import Button from "src/components/Button";
import Toggle from "src/components/Toggle";
import { Space } from "@cohubinc/blueprint";
import Errors from "src/components/Errors";

const { Form } = withTypes<IUserAttributes>();

interface IProps {
  onSuccess: () => void;
  user: IUserFragment;
}

export default function UserForm({ onSuccess, user }: IProps) {
  const [errors, setErrors] = useState<string[]>([]);

  const [updateUserMutation] = useUpdateUserMutation();

  const onSubmit = async (values: IUserAttributes) => {
    const result = await updateUserMutation({
      variables: {
        id: user.id!,
        user: {
          name: values.name,
          email: values.email,
          role: values.role ? "owner" : "member"
        }
      }
    });

    if (result.data?.updateUser?.success) {
      onSuccess();
      setErrors([]);
    }

    if (!!result.data?.updateUser?.errors.length) {
      setErrors(result.data?.updateUser?.errors);
    }
  };

  return (
    <Form
      initialValues={user}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, invalid }) => {
        return (
          <Fragment>
            {!!errors.length && <Errors errors={errors} />}
            <form onSubmit={handleSubmit} autoComplete="off" className="w-full">
              <Field
                name="name"
                type="text"
                autoFocus
                render={({ input, meta }) => (
                  <TextField label="Name" {...input} />
                )}
              />
              <Field
                name="email"
                type="email"
                render={({ input, meta }) => (
                  <TextField label="Email" {...input} />
                )}
              />
              {user.role === "owner" && (
                <Field
                  name="role"
                  type="checkbox"
                  render={({ input, meta }) => (
                    <Toggle
                      label="Owner"
                      helpText="Owners can change billing settings and add other users"
                      checked={user.role === "owner"}
                      onChange={input.onChange}
                    />
                  )}
                />
              )}
              <Space height={2} />
              <Button type="submit" disabled={pristine || invalid}>
                Update
              </Button>
            </form>
          </Fragment>
        );
      }}
    />
  );
}
