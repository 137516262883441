import { useAuthContext } from "src/contexts/AuthContext";

export default function useIsOwner() {
  const { currentUser } = useAuthContext();

  if (currentUser) {
    return currentUser.role === "owner";
  } else {
    return undefined;
  }
}
