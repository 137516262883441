import React, { ReactNode, CSSProperties } from "react";
import NumberFormat from "react-number-format";

interface IProps {
  metric: string;
  value: string | number | undefined;
  elevated?: boolean;
  size?: "sm" | "md" | "lg" | "xl";
  meta?: ReactNode;
  icon?: ReactNode;
  valueDescriptor?: ReactNode;
  className?: string;
  style?: CSSProperties;
  valueFormat?: string | number;
}

export default function Statistic({
  metric,
  value,
  elevated = false,
  size = "md",
  meta,
  icon,
  valueDescriptor,
  className,
  style
}: IProps) {
  const elevatedClassNames = elevated ? "shadow" : "";

  const formattedValue = () => {
    switch (typeof value) {
      case "string":
        return value;
      case "number":
        return (
          <NumberFormat
            className="text-3xl font-semibold leading-9 text-gray-900"
            thousandSeparator
            value={value}
            displayType="text"
            decimalScale={5}
          />
        );
      default:
        return value;
    }
  };

  return (
    <div
      className={`bg-white overflow-hidden rounded-lg ${elevatedClassNames} ${className}`}
      style={style}
    >
      <div className="px-4 py-5 sm:p-6">
        <dl>
          <dt className="text-sm font-medium leading-5 text-gray-500 truncate">
            {metric}
          </dt>
          <dd className="flex items-center mt-1 font-semibold leading-9 text-gray-900">
            <div className="mr-2 text-3xl">{formattedValue()}</div>
            {icon && icon}
            {valueDescriptor && valueDescriptor}
          </dd>
        </dl>
        {meta && <div className="mt-2 text-xs text-gray-400">{meta}</div>}
      </div>
    </div>
  );
}
