import React from "react";
import { useHistory } from "react-router-dom";
import Button from "src/components/Button";
import Segment from "src/components/Segment";

export default function About() {
  const history = useHistory();

  return (
    <main className="mx-auto min-h-screen max-w-screen-xl px-4 sm:px-6">
      <div className="w-5/6 md:w-2/3 mx-auto">
        <Segment className="mt-6 mb-24">
          <div className="mb-8">
            <div className="text-3xl font-poppins text-gray-700 font-bold mb-4">
              Why React?
            </div>
            <div className="text-lg text-gray-600">
              <p>
                React and other JavaScript frameworks have allowed designers and
                developers to build interfaces and experiences that bring users
                the "delight" that we're always chasing.
              </p>
              <p className="mt-4">
                But figuring out if those interfaces actually translate into
                usability and delight is difficult. The single page nature of
                many JS frameworks means that traditional split testing doesn't
                cut it.
              </p>
              <p className="mt-4">
                So what is a designer or developer to do? There's plenty of
                optimization software out there, but it's all geared towards
                marketing and sales teams and is frequently bloated with
                features that are difficult to understand and hard to use.
              </p>
            </div>
          </div>
          <div className="my-8">
            <div className="text-3xl font-poppins text-gray-700 font-bold mb-4">
              Preferr is for designers and developers
            </div>
            <div className="text-lg text-gray-600">
              {/* <p>
                It's not for marketing and sales teams, though the insights it
                provides will no doubt excite them.
              </p> */}
              <p className="mt-4">
                Because Preferr is made with developer and designer experience
                in mind, it's easy to craft an experiment that actually
                addresses a specific need or answers a particular question.
                Since you know the code and the design, you know best when a
                conversion event should be recorded.
              </p>
              <p className="mt-4">
                It takes only a few minutes to sign up, create an A/B test, and
                start learning more about how users interact with your websites
                and apps.
              </p>
            </div>
          </div>
          <div className="my-8">
            <div className="text-3xl font-poppins text-gray-700 font-bold mb-4">
              It's hard to guess what people want
            </div>
            <div className="text-lg text-gray-700">
              Most people aren't sure what makes an interaction with a website,
              app, or other piece of technology enjoyable. They can't explain
              it; it just feels right.
            </div>
            <div className="text-lg text-gray-700 mt-4">
              <p>
                So what's the best way to find out what makes it feel right?
              </p>
              <p className="mt-4">
                Experiment. Try different variations. See what sticks. And find
                out what users are drawn to.
              </p>
            </div>
          </div>
          <div className="my-8 flex justify-center">
            <Button size={"xl"} onClick={() => history.push("/sign-up")}>
              Start Testing
            </Button>
          </div>
        </Segment>
      </div>
      {/* <div className="w-5/6 md:w-2/3 mx-auto">
        <Segment className="mt-12">
          <div className="text-3xl font-poppins text-gray-700">Team of one</div>
        </Segment>
      </div> */}
    </main>
  );
}
