import React, { Fragment, useState } from "react";
import { Field, Form } from "react-final-form";
import { useUpdateOrganizationMutation } from "src/components/graphql";
import TextField from "src/components/Forms/TextField";
import Button from "src/components/Button";
import { Space } from "@cohubinc/blueprint";
import Errors from "src/components/Errors";
import { useAuthContext } from "src/contexts/AuthContext";

interface IProps {
  onSuccess: () => void;
}

export default function BillingEmailForm({ onSuccess }: IProps) {
  const { currentOrganization } = useAuthContext();
  const [errors, setErrors] = useState<string[]>([]);

  const [updateOrganizationMutation] = useUpdateOrganizationMutation();

  const onSubmit = async (values: any) => {
    const result = await updateOrganizationMutation({
      variables: {
        billingEmail: values.billingEmail
      }
    });

    if (result.data?.updateOrganization?.success) {
      onSuccess();
      setErrors([]);
    }

    if (!!result.data?.updateOrganization?.errors.length) {
      setErrors(result.data?.updateOrganization?.errors);
    }
  };

  return (
    <Form
      initialValues={currentOrganization}
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine, invalid }) => {
        return (
          <Fragment>
            {!!errors.length && <Errors errors={errors} />}
            <form onSubmit={handleSubmit} autoComplete="off" className="w-full">
              <Field
                name="billingEmail"
                type="email"
                render={({ input, meta }) => (
                  <TextField
                    label="Billing Email"
                    {...input}
                    helpText="All billing related communication will be sent to this email"
                  />
                )}
              />
              <Space height={1} />
              <div className="flex justify-end">
                <Button type="submit" disabled={pristine || invalid}>
                  Update
                </Button>
              </div>
            </form>
          </Fragment>
        );
      }}
    />
  );
}
