import React, { Fragment } from "react";

export default function LocalStorageInfo() {
  return (
    <Fragment>
      <h4 className="text-3xl mb-8">Local Storage</h4>
      <div className="mb-10">
        <div className="text-xl font-semibold mb-4">
          {/* <Circle diameter={10} color="#2E7FEC" className="mr-2" /> */}
          Use of localStorage and SSR
        </div>
        <p className="mb-4">
          Preferr makes extensive use of localStorage in order to recognize your
          component on a visitor's page and to identify visitors.
        </p>
        <p className="mb-4">
          This pattern was chosen to eliminate the need for extra network
          requests that would be needed to determine which variant of an A/B
          test to render. The possibilty of a failed network request and the
          additional time necessary to process such a request would result in a
          possibly lengthy delay.
        </p>
        <p className="mb-4">
          If you plan to use SSR with React, you may run into trouble because
          window and localStorage are not available at the time when your React
          files are run and rendered.
        </p>
        <p className="mb-4">
          While we'd like to be able to fully support SSR, at this time there no
          plans to change the library to accommodate it.
        </p>
      </div>
    </Fragment>
  );
}
