import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Stack, Divider } from "@cohubinc/blueprint";
import TextField from "src/components/Forms/TextField";
import Button from "src/components/Button";
import Segment from "src/components/Segment";
import Logo from "src/components/Logo";
import useSession from "src/hooks/useSession";
import { authenticate } from "src/utils/authentication";
import Errors from "src/components/Errors";
import Alert from "src/components/Alert";

interface ISignInFormValues {
  email: string;
  password: string;
}

export default function SignIn() {
  const history = useHistory();
  const { logIn } = useSession();
  const [errors, setErrors] = useState<string[]>([]);
  const [cameFromNewPassword, setCameFromNewPassword] = useState(false);
  const { search } = useLocation();
  useEffect(() => {
    if (search.includes("passwordReset")) {
      setCameFromNewPassword(true);
    }
  }, [search]);

  // const onSubmit = async (values: ISignInFormValues) => {
  //   const url = `${protocol}://${host}/api/sessions`;
  //   const userPayload = pick(values, ["email", "password"]);

  //   const payload = {
  //     user: userPayload
  //   };

  //   const response = await fetch(url, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify(payload)
  //   });

  //   const result = await response.json();

  //   if (result.user) {
  //     const { authentication_token } = result.user;
  //     localStorage.setItem("userAuthToken", authentication_token);
  //     history.push("/dashboard");
  //   }
  // };

  const checkCredentials = React.useCallback(
    async ({ email, password }) => {
      setErrors([]);
      const { user, errors } = await authenticate(email, password);

      if (errors) {
        setErrors(errors);
        return;
      }

      logIn(user!);
      history.push("/");
      window.location.reload();
    },
    [history, logIn]
  );

  return (
    <div className="mb-12">
      <Form
        initialValues={{}}
        onSubmit={checkCredentials}
        render={({ handleSubmit }) => {
          return (
            <div className="flex items-center h-3/4">
              <div className="w-full md:w-1/3 max-w-sm px-4 md:mx-auto">
                <Segment>
                  {!!errors.length && <Errors errors={errors} />}
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="flex justify-center items-center mt-6 mb-12">
                      <Logo width={200} />
                    </div>
                    {cameFromNewPassword && (
                      <Alert
                        version="success"
                        title="Password reset successful!"
                        message="Please sign in again"
                        className="mb-4"
                      />
                    )}
                    <Stack fullWidth>
                      <Field
                        name="email"
                        type="email"
                        autoFocus
                        render={({ input, meta }) => (
                          <TextField label="Email" {...input} />
                        )}
                      />
                      <Field
                        name="password"
                        type="password"
                        render={({ input, meta }) => (
                          <TextField label="Password" {...input} />
                        )}
                      />
                      <Button type="submit" fullWidth>
                        Sign In
                      </Button>
                    </Stack>
                  </form>
                  <Divider marginSize={1} />
                  <div className="flex justify-center items-center">
                    <Link
                      to="/forgot-password"
                      className="text-gray-600 uppercase text-sm font-medium"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                  <Divider marginSize={1} />
                  <div className="flex justify-center items-center">
                    <Link
                      to="/sign-up"
                      className="text-gray-600 uppercase text-sm font-medium"
                    >
                      Sign Up
                    </Link>
                  </div>
                </Segment>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
