import React, { useState, useEffect } from "react";
import Segment from "src/components/Segment";
import { motion } from "framer-motion";

export default function DemoSwitcher() {
  const [userExample, setUserExample] = useState(true);

  useEffect(() => {
    const dsInterval = setInterval(() => {
      setUserExample(!userExample);
    }, 5000);
    return () => {
      clearInterval(dsInterval);
    };
  }, [userExample]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="flex justify-center relative"
    >
      <Segment shadow="shadow-md" className="w-full">
        <div className="text-2xl mb-4 text-gray-800 font-semibold">
          Split test React components
        </div>
        <p className="mb-8">
          We'll split your app's users into two groups and render one of your
          A/B test variants to them. So...
        </p>
        <div className={`flex justify-between`}>
          <motion.div
            className="text-xl text-gray-800 font-medium"
            key={1}
            animate={userExample ? { opacity: 1 } : { opacity: 0 }}
            transition={userExample ? { duration: 1 } : { duration: 0.5 }}
          >
            Some users see <span className="text-blue-600">variant A</span>
          </motion.div>
          <motion.div
            className="text-xl text-gray-800 font-medium"
            key={2}
            initial={{ opacity: 0 }}
            animate={userExample ? { opacity: 0 } : { opacity: 1 }}
            transition={userExample ? { duration: 0.5 } : { duration: 1 }}
          >
            Others see <span className="text-blue-600">variant B</span>
          </motion.div>
        </div>

        <div className="mt-4 flex justify-between">
          <motion.div
            key={1}
            animate={
              userExample ? { opacity: 1, x: 10 } : { opacity: 0, x: -10 }
            }
            transition={userExample ? { duration: 1 } : { duration: 0.5 }}
            className="w-60 text-xl text-center px-6 py-4 bg-blue-100
                text-blue-900 rounded-md"
            style={{
              boxShadow:
                "0 1px 3px hsla(0, 0%, 0%, 0.1), inset 0px -2px 0px #CBD9FC"
            }}
          >
            Add to cart
          </motion.div>
          <motion.div
            key={2}
            initial={{ opacity: 0 }}
            animate={
              userExample ? { opacity: 0, x: 10 } : { opacity: 1, x: -10 }
            }
            transition={userExample ? { duration: 0.5 } : { duration: 1 }}
            className="w-60 text-xl text-center px-6 py-4 bg-blue-700 text-blue-100 rounded-md"
            style={{
              boxShadow:
                "0 1px 3px hsla(0, 0%, 0%, 0.1), inset 0px -2px 0px #76A9FA"
            }}
          >
            Add to bag
          </motion.div>
        </div>
      </Segment>
    </motion.div>
  );
}
