import React, { Fragment } from "react";
import CodeSnippet from "src/components/CodeSnippet";
import { Link } from "react-router-dom";
import { IconChevronRight } from "src/components/Icons/IconChevronRight";
import { IconChevronLeft } from "src/components/Icons/IconChevronLeft";
import Badge from "src/components/Badge";
import { Divider } from "@cohubinc/blueprint";

export default function CustomConversions() {
  return (
    <Fragment>
      <h4 className="text-3xl mb-8">Conversions</h4>
      <div className="mb-10">
        <div className="text-xl mb-4">Not all conversions are clicks</div>
        <p className="mb-4">
          Most A/B testing software considers a click a conversion, and
          sometimes that's correct. But Preferr gives you the power to decide
          when a conversion should be recorded.
        </p>
        <p className="mb-4">
          You may have an entire landing page that you're A/B testing and want
          to know if your visitors spend at least X amount of time on the page
          or if they navigated to a certain page.
        </p>
      </div>
      <div className="mb-10">
        <div className="text-xl mb-4">Preferr.convert()</div>
        <p className="mb-4">
          The
          <CodeSnippet inline bare code="Preferr.convert()" /> function allows
          you to create a conversion for your test at exactly the right time.
        </p>
        <p className="mb-4">
          <CodeSnippet inline bare code="Preferr.convert()" /> takes 1 argument:
        </p>
        <ul className="mb-4 ml-8">
          <li className="mb-2">
            <CodeSnippet inline bare code="abTestId" />{" "}
            <Badge className="bg-blue-100 text-blue-800" text="string" /> - the
            ID of the A/B Test that you want to convert{" "}
            <Badge className="bg-red-100 text-red-800" text="REQUIRED" />
          </li>
        </ul>
        <p className="mb-4">
          You may be wondering why you don't pass the variant ID to{" "}
          <CodeSnippet inline bare code="Preferr.convert()" />. Because an A/B
          Test's children are rendered randomly, it's impossible to know ahead
          of time which child will render. This would prevent you from recording
          conversions based on events that happen outside of an A/B Test
          component, like in a useEffect() hook. Preferr will find the rendered
          child for you based on the <CodeSnippet bare inline code="abTestId" />
        </p>
      </div>
      <div className="mb-10">
        <div className="text-xl mb-4">Convert at any time</div>
        <p className="mb-4">
          <CodeSnippet inline bare code="Preferr.convert()" /> doesn't have to
          be called inside an A/B test component!
        </p>
        <p className="mb-4">
          You may want to record a conversion for a form submission once an API
          responds with data successfully, rather than just on the click of the
          submit button. Instead of just assuming that a conversion occurred
          because the form submit button was clicked, which is a big assumption
          since a validation or server error could occur if the form has missing
          or incorrect data, you can capture a true conversion event.
        </p>
      </div>
      <div className="mb-10">
        <div className="text-xl mb-4">Examples</div>
        <p className="mb-4">
          In this example, we're converting whenever the Button component is
          clicked, which is probably the most common type of conversion.
        </p>
        <CodeSnippet
          className="mb-4"
          code={`<ABTest
  abTestId="abt_e8ed892b652c835bb785988aa4464fbf"
  variantAId="abv_323823556cd3a6453f8e54c18d65f41c"
  variantBId="abv_9d407d4c470ced71f53677ec89732c1c"
>
  <Button
    onClick={() => {
      Preferr.convert("abt_e8ed892b652c835bb785988aa4464fbf");
      setCounter(counter + 1);
    }}
  >
    Check it out!
  </Button>
  <Button
    onClick={() => {
      Preferr.convert("abt_e8ed892b652c835bb785988aa4464fbf");
      setCounter(counter - 1);
    }}
  >
    Take a look!
  </Button>
</ABTest>`}
        />

        <Divider marginSize={3} />
        <p className="mb-4">
          In this example, we're recording a custom conversion when the Button
          component is hovered.
        </p>
        <CodeSnippet
          className="mb-4"
          code={`<ABTest
  abTestId="abt_e8ed892b652c835bb785988aa4464fbf"
  variantAId="abv_323823556cd3a6453f8e54c18d65f41c"
  variantBId="abv_9d407d4c470ced71f53677ec89732c1c"
>
  <Button
    onClick={() => {
      setCounter(counter + 1);
    }}
    onMouseLeave={() => Preferr.convert("abt_e8ed892b652c835bb785988aa4464fbf")}
  >
    Check it out!
  </Button>
  <Button
    onClick={() => setCounter(counter - 1)}
    onMouseLeave={() => Preferr.convert("abt_e8ed892b652c835bb785988aa4464fbf")}
  >
    Take a look!
  </Button>
</ABTest>`}
        />

        <Divider marginSize={3} />
        <p className="mb-4">
          In this example, we're recording a custom conversion when the rendered
          component has been on the page for 10 seconds.
        </p>
        <CodeSnippet
          className="mb-4"
          code={`useEffect(() => {
  setTimeout(() => {
    Preferr.convert("abt_e8ed892b652c835bb785988aa4464fbf")
  }, 10_000);
}, []);

<ABTest
  abTestId="abt_e8ed892b652c835bb785988aa4464fbf"
  variantAId="abv_323823556cd3a6453f8e54c18d65f41c"
  variantBId="abv_9d407d4c470ced71f53677ec89732c1c"
>
  <p>
    Dolore dolor proident tempor nostrud sunt qui exercitation commodo ex duis duis pariatur sint incididunt
  </p>
  <p>
    Eiusmod ipsum nulla exercitation sunt duis dolore.
  </p>
</ABTest>`}
        />
      </div>
      {/* <Divider marginSize={3} />
      <p className="mb-4">
        There are limitless ways to come up with your own criteria for a
        conversion.
      </p> */}
      <div className="flex justify-between items-center">
        <Link
          to="/documentation/identifying-visitors"
          className="bg-blue-100 py-2 px-4 rounded-lg"
        >
          <div className="flex items-center text-blue-900">
            <IconChevronLeft className="mr-2" />
            Identifying visitors
          </div>
        </Link>
        <Link
          to="/documentation/dos-and-donts"
          className="bg-blue-100 py-2 px-4 rounded-lg"
        >
          <div className="flex items-center text-blue-900">
            Do's and Don'ts
            <IconChevronRight className="ml-2" />
          </div>
        </Link>
      </div>
    </Fragment>
  );
}
