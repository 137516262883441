import React from "react";
import { useNotification } from "src/contexts/NotificationContext";
import { TNotification } from "src/definitions/TNotification";
import Button, { TButtonVersion } from "./Button";
import { IconCheckCircle } from "./Icons/IconCheckCircle";
import { IconExclamation } from "./Icons/IconExclamation";
import { IconInfo } from "./Icons/IconInfo";
import { IconX } from "./Icons/IconX";
import { IconXCircle } from "./Icons/IconXCircle";
import Transition from "./Transition";

export default function Notification({
  open,
  version = "plain",
  title,
  message,
  action,
  duration = 5000
}: TNotification) {
  const { notificationOpen, setNotificationOpen } = useNotification();
  let calculatedIcon;
  let actionButtonVersion: TButtonVersion = "plain";

  // useEffect(() => {
  //   if (notificationOpen) {
  //     setTimeout(() => {
  //       setNotificationOpen(false);
  //     }, duration);
  //   }
  // });

  switch (version) {
    case "success":
      calculatedIcon = <IconCheckCircle className="text-green-500" />;
      actionButtonVersion = "success";
      break;
    case "info":
      calculatedIcon = <IconInfo className="text-blue-500" />;
      actionButtonVersion = "primary";
      break;
    case "plain":
      actionButtonVersion = "plain";
      break;
    case "error":
      calculatedIcon = <IconXCircle className="text-red-500" />;
      actionButtonVersion = "danger";
      break;
    case "warning":
      calculatedIcon = <IconExclamation className="text-yellow-500" />;
      actionButtonVersion = "warning";
      break;
    default:
      break;
  }

  return (
    <div
      className="fixed bottom-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-6 sm:items-start sm:justify-end"
      style={{ left: "50%", transform: "translateX(-50%)" }}
    >
      {/* <!--
    Notification panel, show/hide based on alert state.

    Entering: "transform ease-out duration-300 transition"
      From: "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      To: "translate-y-0 opacity-100 sm:translate-x-0"
    Leaving: "transition ease-in duration-100"
      From: "opacity-100"
      To: "opacity-0"
  --> */}
      <Transition
        show={notificationOpen}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        containerWidth={360}
      >
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto">
          <div className="rounded-lg shadow-xs overflow-hidden">
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">{calculatedIcon}</div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  {title && (
                    <p className="text-sm leading-5 font-medium text-gray-900">
                      {title}
                    </p>
                  )}
                  {message && (
                    <p className="mt-1 text-sm leading-5 text-gray-500">
                      {message}
                    </p>
                  )}
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    onClick={() => setNotificationOpen(false)}
                    className="inline-flex text-gray-400 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150"
                  >
                    <IconX />
                  </button>
                </div>
              </div>
              {action && (
                <div className="flex justify-end">
                  <Button
                    version={actionButtonVersion}
                    onClick={action.onAction}
                  >
                    {action.content}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  );
}
