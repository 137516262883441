import React, { Fragment } from "react";
import { Link, useLocation } from "react-router-dom";
import Card from "src/components/Card";
import { IAbTestVariantFragment } from "src/components/graphql";
import Statistic from "src/components/Statistic";
import Well from "src/components/Well";

interface IProps {
  variant: IAbTestVariantFragment;
  uniqueStats?: boolean;
  isDemo?: boolean;
}

export default function AbTestVariantCard({
  variant,
  uniqueStats,
  isDemo = false
}: IProps) {
  const { pathname } = useLocation();
  // const [showHighlight, setShowHighlight] = useState(false);

  const externalSheets = variant.externalStyleSheets
    .map((es) => `<link rel="stylesheet" href="${es}" />`)
    .toString()
    .replace(/,/g, "");

  const srcDoc = `${externalSheets} <style>${variant.styles} * { user-select: none !important; }</style> ${variant.markup}`;

  return (
    <Fragment>
      <Card>
        <div className="flex justify-between items-center mb-4">
          <div className="text-lg">
            Variant {variant.variantLetter} - {variant.variantDesignation}
          </div>
          {!isDemo && (
            <Link
              to={`${pathname}/variants/${variant.apiId}`}
              className="text-xs font-bold text-blue-500 uppercase"
            >
              Visitor Details
            </Link>
          )}
        </div>
        <Well className="bg-gray-50">
          <iframe
            title={variant.apiId}
            srcDoc={srcDoc}
            style={{ height: "200px" }}
            className="w-full select-none"
          />
          {/* <div className="flex justify-end">
            <Button
              size="xs"
              version="secondary"
              onClick={() => setShowHighlight(true)}
            >
              <svg
                fill="currentcolor"
                viewBox="0 0 20 20"
                width="20"
                height="20"
                className="mr-2"
              >
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                <path
                  fillRule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clipRule="evenodd"
                />
              </svg>
              Inspect
            </Button>
          </div> */}
        </Well>
        <div className="flex justify-between items-center">
          <Statistic
            metric="Unique Visitors"
            value={variant.uniqueVisitorsCount}
          />
          <Statistic metric="Impressions" value={variant.impressionCount} />
          {/* <Statistic
            metric="Clicks"
            value={uniqueStats ? variant.uniqueClickCount : variant.clickCount}
          /> */}
          <Statistic
            metric="Conversions"
            value={
              uniqueStats
                ? variant.uniqueConversionCount
                : variant.conversionCount
            }
          />
        </div>
        <div className="flex justify-between items-center">
          <Statistic
            metric="Conversion Rate"
            value={`${
              uniqueStats
                ? variant.uniqueConversionRatePercentage
                : variant.conversionRatePercentage
            }%`}
          />
        </div>
      </Card>
      {/* <Modal open={showHighlight} onClose={() => setShowHighlight(false)}>
        <iframe title={variant.apiId} srcDoc={srcDoc} className="w-full" />
      </Modal> */}
    </Fragment>
  );
}
