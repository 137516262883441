import React, { ReactNode } from "react";
import AltLoader from "./AltLoader";

export type TButtonVersion =
  | "primary"
  | "secondary"
  | "plain"
  | "danger"
  | "success"
  | "warning";

interface IProps {
  children: ReactNode;
  version?: TButtonVersion;
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
  fullWidth?: boolean;
  className?: string;
  loading?: boolean;
}

type TButtonsProps = IProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button(props: TButtonsProps) {
  const {
    children,
    version = "primary",
    size = "md",
    fullWidth = false,
    className,
    loading,
    ...restProps
  } = props;

  let calculatedClassNames = "";

  switch (version) {
    case "primary":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-blue-600 text-white hover:bg-blue-500 focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 "
      );
      break;
    case "secondary":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-blue-100 text-blue-700 bg-blue-100 hover:bg-blue-50 focus:border-blue-300 focus:shadow-outline-blue active:bg-blue-200"
      );
      break;
    case "plain":
      calculatedClassNames = calculatedClassNames.concat(
        " border border-transparent shadow text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 active:bg-gray-50 "
      );
      break;
    case "danger":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-red-100 text-red-700 bg-red-100 hover:bg-red-50 focus:border-red-300 focus:shadow-outline-red active:bg-red-200"
      );
      break;
    case "success":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-green-100 text-green-700 bg-green-100 hover:bg-green-50 focus:border-green-300 focus:shadow-outline-green active:bg-green-200"
      );
      break;
    case "warning":
      calculatedClassNames = calculatedClassNames.concat(
        " bg-yellow-100 text-yellow-700 bg-yellow-100 hover:bg-yellow-50 focus:border-yellow-300 focus:shadow-outline-yellow active:bg-yellow-200"
      );
      break;
    default:
      break;
  }

  if (fullWidth) {
    calculatedClassNames = calculatedClassNames.concat(" w-full text-center ");
  }

  switch (size) {
    case "xs":
      calculatedClassNames = calculatedClassNames.concat(
        " text-xs px-2.5 py-1.5 leading-4 rounded "
      );
      break;
    case "sm":
      calculatedClassNames = calculatedClassNames.concat(
        " text-sm px-3 py-2 leading-4 rounded-md "
      );
      break;
    case "md":
      calculatedClassNames = calculatedClassNames.concat(
        " text-sm px-4 py-2 leading-5 rounded-md "
      );
      break;
    case "lg":
      calculatedClassNames = calculatedClassNames.concat(
        " text-sm px-4 py-2 leading-6 rounded-md "
      );
      break;
    case "xl":
      calculatedClassNames = calculatedClassNames.concat(
        " text-base px-6 py-3 leading-6 rounded-md "
      );
      break;
    case "2xl":
      calculatedClassNames = calculatedClassNames.concat(
        " text-base px-10 py-5 leading-8 rounded-lg "
      );
      break;
    default:
      break;
  }

  if (props.disabled) {
    calculatedClassNames = calculatedClassNames.concat(
      " opacity-50 cursor-not-allowed "
    );
  }

  return (
    <button
      {...restProps}
      className={`${calculatedClassNames} shadow cursor-pointer font-medium inline-flex justify-center items-center border border-transparent focus:outline-none transition ease-in-out duration-150 ${className}`}
    >
      {loading && <AltLoader className="mr-2" dotSize={16} />}
      {children}
    </button>
  );
}
