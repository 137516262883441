import React, { Fragment } from "react";
import { Switch, Route, useHistory, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import SignUp from "./screens/SignUp";
import SignIn from "./screens/SignIn";
import Layout from "./screens/Layout";
import AbTests from "./screens/AbTests";
import AbTest from "./screens/AbTests/AbTest";
import Url from "./utils/Url";
import Home from "./screens/Marketing/Home";
import AbTestVariant from "./screens/AbTests/AbTestVariants/AbTestVariant";
import MarketingLayout from "./screens/Marketing/MarketingLayout";
import Documentation from "./screens/Documentation";
import Account from "./screens/Account/Account";
import Pricing from "./screens/Marketing/Pricing";
import useSession from "./hooks/useSession";
import Billing from "./screens/Billing/Billing";
import Organization from "./screens/Organization/Organization";
import About from "./screens/Marketing/About";
import Visitors from "./screens/Visitors/Visitors";
import Privacy from "./screens/Marketing/Legal/Privacy";
import Terms from "./screens/Marketing/Legal/Terms";
import PaymentSetup from "./screens/PaymentSetup";
import ForgotPassword from "./screens/Passwords/ForgotPassword";
import NewPassword from "./screens/Passwords/NewPassword";
import AcceptInvitation from "./screens/Passwords/AcceptInvitation";
import NotFound from "./screens/NotFound";
import Support from "./screens/Marketing/Support";
import Company from "./screens/Marketing/Company";
import DocumentationLayout from "./screens/Documentation/DocumentationLayout";
import Demo from "./screens/Marketing/Demo/Demo";
import DemoLayout from "./screens/DemoLayout/DemoLayout";

const Routes: React.FC = () => {
  const { loggedIn } = useSession();
  const history = useHistory();
  const { pathname } = useLocation();

  if (!loggedIn) {
    if (pathname.includes("/documentation")) {
      return (
        <DocumentationLayout>
          <Switch>
            <Route path="/documentation">
              <Documentation />
            </Route>
          </Switch>
        </DocumentationLayout>
      );
    }

    if (pathname.includes("/demo")) {
      return (
        <DemoLayout>
          <Switch>
            <Route path="/demo">
              <Demo />
            </Route>
          </Switch>
        </DemoLayout>
      );
    }

    return (
      <Fragment>
        <MarketingLayout>
          <Switch>
            <Route exact path="/sign-in">
              <SignIn />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/new-password">
              <NewPassword />
            </Route>
            <Route path="/accept-invitation">
              <AcceptInvitation />
            </Route>
            <Route exact path="/sign-up">
              <SignUp />
            </Route>
            <Route exact path="/pricing">
              <Pricing />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/privacy">
              <Privacy />
            </Route>
            <Route exact path="/terms">
              <Terms />
            </Route>
            <Route exact path="/support">
              <Support />
            </Route>
            <Route exact path="/company">
              <Company />
            </Route>
            <Route path="/home">
              <Home />
            </Route>
            {/* <Route path="/demo">
              <Demo />
            </Route> */}
            <Route exact path="/">
              <Home />
            </Route>
          </Switch>
        </MarketingLayout>
      </Fragment>
    );
  }

  return (
    <Layout>
      <Switch>
        <Route path={Url.accountPath()}>
          <Account />
        </Route>
        <Route path="/organization">
          <Organization />
        </Route>
        <Route path="/billing">
          <Billing />
        </Route>
        <Route path={`/ab-tests/:apiId/variants/:apiId`}>
          <AbTestVariant />
        </Route>
        <Route path="/ab-tests/:apiId">
          <AbTest />
        </Route>
        <Route exact path={Url.abTestsPath()}>
          <AbTests />
        </Route>
        <Route exact path={Url.visitorsPath()}>
          <Visitors />
        </Route>
        <Route path="/documentation">
          <Documentation />
        </Route>
        <Route exact path="/">
          <AbTests />
        </Route>
        <Route path={Url.paymentSetupPath()}>
          <PaymentSetup onSuccess={() => history.push(Url.abTestsPath())} />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </Layout>
  );
};

export default observer(Routes);
