import React, { useContext, Fragment, useState } from "react";
import { AuthContext } from "src/contexts/AuthContext";
import { Space } from "@cohubinc/blueprint";
import SideSheet from "src/components/SideSheet";
import UserForm from "../Account/UserForm";
import NewUserForm from "../Account/NewUserForm";
import { useListUsersQuery, IUserFragment } from "src/components/graphql";
import Loader from "src/components/Loader";
import Button from "src/components/Button";
import Tippy from "@tippyjs/react";
import Badge from "src/components/Badge";
import useIsOwner from "src/hooks/useIsOwner";
import UserCard from "./UserCard";
import { useNotification } from "src/contexts/NotificationContext";

type TUserWithoutOrg = Omit<IUserFragment, "organization">;

export default function Organization() {
  const { currentOrganization } = useContext(AuthContext);
  const [showNewUserForm, setShowNewUserForm] = useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [selectedUser, setSelectedUser] = useState<
    TUserWithoutOrg | undefined
  >();
  const isOwner = useIsOwner();
  const { setNotificationOpen, setCurrentNotification } = useNotification();

  const { loading, data } = useListUsersQuery();

  if (loading && !data) {
    return <Loader fullScreen />;
  }

  const userList = data?.users.map((u) => (
    <UserCard
      key={u.id}
      user={u}
      onAction={() => {
        setSelectedUser(u);
        setShowUserForm(true);
      }}
    />
  ));

  return (
    <Fragment>
      <div className="flex justify-between w-2/3 ml-auto mr-auto">
        <div className="w-2/3 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <div className="flex items-center">
              <h2 className="mr-2 text-2xl font-bold leading-7 text-gray-400 sm:text-3xl sm:leading-9 sm:truncate">
                {currentOrganization?.name}
              </h2>
              {currentOrganization?.earlyAdopter && (
                <Tippy
                  content="You're an early adopter of Preferr! Thanks for helping test it out."
                  placement="right"
                  maxWidth={200}
                  className="text-center"
                >
                  <div>
                    <Badge
                      size="md"
                      text="Early Adopter"
                      className="text-green-500 bg-green-100"
                    />
                  </div>
                </Tippy>
              )}
            </div>
          </div>
        </div>
      </div>

      <Space height={2} />
      <div className="flex justify-center">
        <div className="w-2/3 ml-auto mr-auto">
          <div className="flex items-center justify-between mb-4">
            <div className="text-xl font-medium text-gray-400">Users</div>
            {isOwner && (
              <Button size="md" onClick={() => setShowNewUserForm(true)}>
                Invite User
              </Button>
            )}
          </div>
          {userList}
        </div>
      </div>
      <SideSheet
        open={showNewUserForm}
        title="New user"
        onClose={() => setShowNewUserForm(false)}
      >
        <NewUserForm onSuccess={() => setShowNewUserForm(false)} />
      </SideSheet>
      <SideSheet
        open={showUserForm}
        title="Update user"
        onClose={() => setShowUserForm(false)}
      >
        <UserForm
          onSuccess={() => {
            setShowUserForm(false);
            setNotificationOpen(true);
            setCurrentNotification({
              open: true,
              version: "success",
              title: "Successfully updated user!"
            });
          }}
          user={selectedUser as IUserFragment}
        />
      </SideSheet>
      {/* <Notification
        version="success"
        open={showNotification || true}
        title="Success!"
      /> */}
    </Fragment>
  );
}
