import { observable, action } from "mobx";

// https://github.com/mobxjs/mobx-react-lite/#observer-batching
import "mobx-react-lite/batchingOptOut";

import { createContext } from "react";
import { IAuthenticatedUser } from "src/utils/authentication";

class SessionStore {
  constructor() {
    const userEmail = localStorage.getItem("userEmail");
    const userAuthToken = localStorage.getItem("userAuthToken");
    this.loggedIn = !!(userEmail && userAuthToken);
    this.userEmail = userEmail;
    this.userAuthToken = userAuthToken;
  }

  @observable
  loggedIn: boolean;

  @observable
  userEmail: string | null;

  @observable
  userAuthToken: string | null;

  @action
  logIn = (user: IAuthenticatedUser) => {
    localStorage.setItem("userEmail", user.email);
    localStorage.setItem("userAuthToken", user.authentication_token);

    this.userEmail = user!.email;
    this.userAuthToken = user!.authentication_token;
    this.loggedIn = true;
  };

  @action
  logOut = () => {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userAuthToken");
    this.userEmail = null;
    this.userAuthToken = null;
    this.loggedIn = false;
  };
}

export const sessionStore = new SessionStore();
export const SessionStoreContext = createContext(sessionStore);
