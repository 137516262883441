import React, { Fragment, useContext } from "react";
import { Link } from "react-router-dom";
import CodeSnippet from "src/components/CodeSnippet";
import { IconChevronLeft } from "src/components/Icons/IconChevronLeft";
import { IconChevronRight } from "src/components/Icons/IconChevronRight";
import { IconKey } from "src/components/Icons/IconKey";
import { AuthContext } from "src/contexts/AuthContext";
import Url from "src/utils/Url";

export default function GettingStarted() {
  const { currentOrganization } = useContext(AuthContext);

  const apiKeyDescriptor = currentOrganization?.apiKey
    ? "That's your API key!"
    : "After signing up, you'll have an API key";

  return (
    <Fragment>
      <h4 className="text-3xl mb-8">Getting started</h4>
      <div className="mb-10">
        <div className="text-xl mb-4">Installation</div>
        <p className="mb-4">Install the preferr-client using NPM or Yarn</p>
        <CodeSnippet
          language="bash"
          code="npm install @preferr-app/preferr-client"
          className="mb-4"
        />
        <CodeSnippet
          language="bash"
          code="yarn add @preferr-app/preferr-client"
        />
      </div>
      <div className="mb-10">
        <div className="text-xl mb-4">Initializing the client</div>
        <p className="mb-4">
          To authenticate Preferr and start tracking your A/B tests, you'll need
          to activate Preferr using your API key.
        </p>
        <div className="flex items-center text-lg mb-4">
          <IconKey size={20} className="mr-2 text-gray-400" /> Your API Key
        </div>
        <CodeSnippet
          bare
          code={`${currentOrganization?.apiKey || "XXXXXXXX"}`}
          className="mb-4"
        />
        <p className="mb-4">
          Somewhere in the root of your app, initialize Preferr like this:
        </p>
        <CodeSnippet
          code={`import { Preferr } from "@preferr-app/preferr-client";`}
          language="javascript"
          className="mb-4"
        />
        <CodeSnippet
          code={`Preferr.init("${
            currentOrganization?.apiKey || "XXXXXXXX"
          }") <--- ${apiKeyDescriptor}`}
          language="javascript"
        />
        <p className="mt-6">Now you're ready to create an A/B test!</p>
      </div>
      <div className="flex justify-between items-center">
        <Link
          to={Url.documentationHowItWorksPath()}
          className="bg-blue-100 py-2 px-4 rounded-lg"
        >
          <div className="flex items-center text-blue-900">
            <IconChevronLeft className="mr-2" />
            How it works
          </div>
        </Link>
        <Link
          to={Url.documentationUsingPreferrPath}
          className="bg-blue-100 py-2 px-4 rounded-lg"
        >
          <div className="flex items-center text-blue-900">
            Using Preferr
            <IconChevronRight className="ml-2" />
          </div>
        </Link>
      </div>
    </Fragment>
  );
}
