import React, { ReactNode, ReactNodeArray } from "react";
import styled, { keyframes } from "styled-components";
import MarketingFooter from "./MarketingFooter";
import MarketingNav from "./MarketingNav";

const colorSwap = keyframes`
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
`;

const Container = styled.div`
  background: linear-gradient(120deg, #1c64f2 5%, #f9fafb 55%);
  /* background: linear-gradient(120deg, #1c64f2 5%, #ac94fa); */
  background-size: 120% 120%;
  animation: ${colorSwap} 10s infinite ease;
`;

interface IProps {
  children: ReactNode | ReactNodeArray;
}

export default function MarketingLayout({ children }: IProps) {
  return (
    <Container
      className="min-h-screen"
      style={
        {
          // backgroundImage: "linear-gradient(120deg, #1c64f2 5%, #F9FAFB 55%)"
          // display: "grid",
          // gridTemplateRows: "1fr auto"
        }
      }
    >
      <div className="relative" style={{ minHeight: "calc(100vh - 180px)" }}>
        <div className="pb-20">
          <MarketingNav />
          {/* <HomeHero /> */}
        </div>
        <main className="px-4 md:px-8">{children}</main>
      </div>
      <MarketingFooter />
    </Container>
  );
}
