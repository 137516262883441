import React from "react";

interface IProps {
  dotSize?: number;
  className?: string;
}

export default function AltLoader({ dotSize = 20, className }: IProps) {
  return (
    <div
      className={`flex items-center justify-center ${className} rounded-full`}
    >
      <div
        className="bg-white opacity-25 grow-shrink"
        style={{ borderRadius: "50%", width: dotSize, height: dotSize }}
      />
      <div
        className="absolute bg-white opacity-50 grow-shrink-2"
        style={{ borderRadius: "50%", width: dotSize, height: dotSize }}
      />
    </div>
  );
}
