import React, { ReactNode, CSSProperties } from "react";

type TShadow =
  | "shadow-xs"
  | "shadow-sm"
  | "shadow"
  | "shadow-md"
  | "shadow-lg"
  | "shadow-xl"
  | "shadow-2xl";

interface IProps {
  children: ReactNode;
  shadow?: TShadow;
  className?: string;
  style?: CSSProperties;
  opaque?: boolean;
}

export default function Segment({
  children,
  shadow = "shadow",
  className,
  style,
  opaque
}: IProps) {
  const opaqueStyles = {
    backdropFilter: "opacity(20%) blur(6px)",
    backgroundColor: "rgba(0, 0, 0, 0.03)"
  };

  return (
    <div
      className={`p-4 rounded-lg ${
        !opaque && "bg-white"
      } ${shadow} ${className}`}
      style={opaque ? { ...style, ...opaqueStyles } : style}
    >
      {children}
    </div>
  );
}
