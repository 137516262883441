import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { Stack } from "@cohubinc/blueprint";
import TextField from "src/components/Forms/TextField";
import Button from "src/components/Button";
import Segment from "src/components/Segment";
import Logo from "src/components/Logo";
import Errors from "src/components/Errors";
import { protocol, host } from "src/config";
import Alert from "src/components/Alert";

interface IForgotPasswordFormValues {
  email: string;
}

export default function ForgotPassword() {
  const [errors, setErrors] = useState<string[]>([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const onSubmit = async (values: IForgotPasswordFormValues) => {
    const url = `${protocol}://${host}/api/users/reset_password`;

    const payload = {
      email: values.email
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });

    const result = await response.json();

    if (result.user) {
      setFormSubmitted(true);
      // const { authentication_token } = result.user;
      // localStorage.setItem("userAuthToken", authentication_token);
      // history.push("/dashboard");
    }
  };

  return (
    <div className="mb-12">
      <Form
        initialValues={{}}
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting, pristine }) => {
          return (
            <div className="flex items-center h-3/4">
              <div className="w-full md:w-1/3 max-w-sm  px-4 md:mx-auto">
                <Segment>
                  {!!errors.length && <Errors errors={errors} />}
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="flex justify-center items-center mt-6 mb-12">
                      <Logo width={200} />
                    </div>
                    {formSubmitted && (
                      <Alert
                        version="success"
                        title="Check your email for reset instructions"
                        className="mb-4"
                      />
                    )}
                    <Stack fullWidth>
                      <Field
                        name="email"
                        type="email"
                        autoFocus
                        render={({ input, meta }) => (
                          <TextField label="Email" {...input} />
                        )}
                      />
                      <Button
                        type="submit"
                        fullWidth
                        disabled={submitting || pristine || formSubmitted}
                      >
                        Send Reset Instructions
                      </Button>
                    </Stack>
                  </form>
                </Segment>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
