import React from "react";
import { motion } from "framer-motion";

interface IProps {
  text?: string;
  fullScreen?: boolean;
}

export default function Loader({ text, fullScreen = false }: IProps) {
  if (fullScreen) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <motion.div
          className={`w-10 h-10 bg-blue-500 absolute top-1/2 translate-x-1/2`}
          animate={{
            scale: [1, 1.5, 1.5, 1, 1],
            rotate: [0, 0, 270, 270, 0],
            borderRadius: ["20%", "20%", "50%", "50%", "20%"]
          }}
          transition={{
            duration: 1.5,
            ease: "easeInOut",
            times: [0, 0.2, 0.4, 0.6, 1],
            loop: Infinity,
            repeatDelay: 0.5
          }}
        />
      </div>
    );
  } else {
    return (
      <motion.div
        className={`w-10 h-10 bg-blue-500`}
        animate={{
          scale: [1, 1.5, 1.5, 1, 1],
          rotate: [0, 0, 270, 270, 0],
          borderRadius: ["20%", "20%", "50%", "50%", "20%"]
        }}
        transition={{
          duration: 1.5,
          ease: "easeInOut",
          times: [0, 0.2, 0.4, 0.6, 1],
          loop: Infinity,
          repeatDelay: 0.5
        }}
      />
    );
  }

  // <Fragment>
  //   <div className="flex justify-center items-center">
  //     <Circle className="mr-6" diameter={40} color="#3f83f8" />
  //     <Circle className="ml-6" diameter={40} color="#1c64f2" />
  //   </div>
  //   {text && <div className="text-center">{text}</div>}
  // </Fragment>
}
