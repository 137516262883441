import React, { Fragment } from "react";
import CodeSnippet from "src/components/CodeSnippet";
import { Link } from "react-router-dom";
import { IconChevronRight } from "src/components/Icons/IconChevronRight";
import { IconChevronLeft } from "src/components/Icons/IconChevronLeft";
import Badge from "src/components/Badge";
import Url from "src/utils/Url";

export default function IdentifyingVisitors() {
  return (
    <Fragment>
      <h4 className="text-3xl mb-8">Identifying visitors</h4>
      <div className="mb-10">
        <div className="text-xl mb-4">Is this required?</div>
        <p className="mb-4">
          Not at all! Preferr will work just fine without visitor information,
          but it's often more meaningful to be able to see which of your
          users/visitors has interacted with an A/B test.
        </p>
        <p className="mb-4">
          Your A/B tests will continue to record interaction data, there just
          won't be any association to a particular visitor.
        </p>
      </div>
      <div className="mb-10">
        <div className="text-xl mb-4">Visitor data</div>
        <p className="mb-4">
          In order to know who is interacting with your A/B tests, you'll
          probably want to provide some basic identifiers to Preferr.
        </p>
        <p className="mb-4">
          Somewhere near the root of your app, typically at a point where you'll
          have specific visitor/user information available, you'll use the{" "}
          <CodeSnippet inline bare code="Preferr.identify()" /> function.
        </p>
        <p className="mb-4">
          <CodeSnippet inline bare code="Preferr.identify()" /> takes 4
          arguments:
        </p>
        <ul className="mb-4 ml-8">
          <li className="mb-2">
            <CodeSnippet inline bare code="uid" />{" "}
            <Badge className="bg-blue-100 text-blue-800" text="string" /> - a
            unique identifier that you provide{" "}
            <Badge className="bg-red-100 text-red-800" text="REQUIRED" />
          </li>
          <li className="mb-2">
            <CodeSnippet inline bare code="name" />{" "}
            <Badge className="bg-blue-100 text-blue-800" text="string" /> - a
            name for the visitor
          </li>
          <li className="mb-2">
            <CodeSnippet inline bare code="email" />{" "}
            <Badge className="bg-blue-100 text-blue-800" text="string" /> - an
            email for the visitor
          </li>
          <li className="mb-2">
            <CodeSnippet inline bare code="meta" />{" "}
            <Badge className="bg-blue-100 text-blue-800" text="object" /> - an
            object of keys and values that you'd like to associate with the
            current visitor
          </li>
        </ul>
        <p className="mb-4">In the real world, it looks something like this:</p>
        <CodeSnippet
          language="javascript"
          code={`Preferr.identify(\n  currentUser.id,\n  currentUser.name,\n  currentUser.email,\n  {\n    phoneNumber: currentUser.phone,\n    referredBy: "google"\n  }\n);`}
          className="mb-4"
        />
      </div>
      <div className="flex justify-between items-center">
        <Link
          to="/documentation/using-preferr"
          className="bg-blue-100 py-2 px-4 rounded-lg"
        >
          <div className="flex items-center text-blue-900">
            <IconChevronLeft className="mr-2" />
            Using Preferr
          </div>
        </Link>
        <Link
          to={Url.documentationConversionsPath()}
          className="bg-blue-100 py-2 px-4 rounded-lg"
        >
          <div className="flex items-center text-blue-900">
            Conversions
            <IconChevronRight className="ml-2" />
          </div>
        </Link>
      </div>
    </Fragment>
  );
}
