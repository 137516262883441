import React, { ReactNode } from "react";

interface IProps {
  icon: ReactNode;
  title: string;
  body: string;
}

export default function Feature({ icon, title, body }: IProps) {
  return (
    <li className="mt-10">
      <div className="flex">
        <div className="flex-shrink-0">
          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
            {icon}
          </div>
        </div>
        <div className="ml-4">
          <h5 className="text-lg leading-6 font-semibold text-gray-900">
            {title}
          </h5>
          <p className="mt-2 text-base leading-6 text-gray-700">{body}</p>
        </div>
      </div>
    </li>
  );
}
