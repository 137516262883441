import React from "react";
import { useHistory } from "react-router-dom";
import headshotSquare from "src/assets/images/headshot-square.jpg";
import Segment from "src/components/Segment";

export default function Company() {
  const history = useHistory();

  return (
    <main className="mx-auto min-h-screen max-w-screen-xl px-4 sm:px-6">
      <div className="w-5/6 md:w-2/3 mx-auto">
        <Segment className="mt-6 mb-24">
          <div className="mb-8">
            <div className="text-3xl font-poppins text-gray-700 font-bold mb-4">
              One man team
            </div>
            <div className="text-lg text-gray-600">
              <div className="flex items-center">
                <img
                  className="inline-block rounded-full mr-4 h-32 w-32 object-scale-down"
                  src={headshotSquare}
                  alt="headshot"
                />
                <p>
                  Preferr is designed, developed, and managed by me, Elliott
                  Roche. I'm a developer and designer with an entrepreneurial
                  streak that goes back several generations.
                </p>
              </div>
              <p className="mt-4">
                Preferr exists because I've often found myself wanting to know
                whether my designs actually translated to meaningful user
                experiences. I started looking for A/B testing software, and
                couldn't find something that was quick to set up and didn't
                require a lot of background knowledge. Most of the existing
                offerings are built with marketing teams or sales people in
                mind, and many of them are also prohibitively expensive and
                cumbersome to use. They're great for larger organizations, but
                not for smaller teams.
              </p>
              <p className="mt-4">
                So I made my own A/B testing tool, and called it Preferr.
              </p>
            </div>
            <div className="border-gray-200 border-b-2 w-full my-8" />
            <div className="text-lg text-gray-600">
              <p className="mt-4">
                In addition to Preferr, I also work on{" "}
                <a
                  href="https://cohub.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border-blue-400 border-b-2"
                >
                  Cohub
                </a>{" "}
                and{" "}
                <a
                  href="https://billfixers.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border-blue-400 border-b-2"
                >
                  BillFixers
                </a>
                , and I'm the Director of Operations at{" "}
                <a
                  href="https://nashvilledesignweek.org"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border-blue-400 border-b-2"
                >
                  Nashville Design Week
                </a>
                .
              </p>
            </div>
          </div>
        </Segment>
      </div>
      {/* <div className="w-5/6 md:w-2/3 mx-auto">
        <Segment className="mt-12">
          <div className="text-3xl font-poppins text-gray-700">Team of one</div>
        </Segment>
      </div> */}
    </main>
  );
}
