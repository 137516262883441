import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import Alert from "src/components/Alert";
import DemoTopBar from "src/components/DemoTopBar";

interface IProps {
  children: JSX.Element[] | JSX.Element | string | Element[];
  pageTitle?: string;
}

export default function DemoLayout({ children, pageTitle }: IProps) {
  const history = useHistory();

  return (
    <Fragment>
      <div className="h-screen bg-gray-50">
        <DemoTopBar />
        <Alert
          version="info"
          title={`This A/B test is currently running live in the sign up flow for our friends at BillFixers.`}
          action={{
            content: "Exit demo",
            onAction: () => history.push("/")
          }}
        />
        {pageTitle && (
          <header className="bg-white shadow">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <h1 className="text-2xl font-semibold text-gray-900">
                {pageTitle}
              </h1>
            </div>
          </header>
        )}
        <main className="px-4 bg-gray-50 md:px-0">
          <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="py-4">{children}</div>
          </div>
        </main>
      </div>
    </Fragment>
  );
}
