import React from "react";
import lightenDarkenColor from "src/utils/lightenDarkenColor";

interface IProps {
  diameter: number;
  color: string;
  text?: string;
  className?: string;
}

export default function Circle({ diameter, color, text, className }: IProps) {
  return (
    <div
      className={`flex items-center justify-center ${className}`}
      style={{
        width: `${diameter}px`,
        height: `${diameter}px`,
        borderRadius: "50%",
        backgroundColor: color
      }}
    >
      <span
        style={{
          fontSize: `${diameter / 3}px`,
          color: lightenDarkenColor(color, 100)
        }}
      >
        {text}
      </span>
    </div>
  );
}
