import { useFindCurrentUserQuery } from "src/components/graphql";

export default function useCurrentUser() {
  // const authenticationToken = localStorage.getItem("userAuthToken") || "";

  const { data, loading } = useFindCurrentUserQuery();

  console.log({ data });

  return { currentUser: data?.currentUser, loading };
}
