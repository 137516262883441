import React, { useState, Fragment } from "react";
import TopBarLink from "./TopBarLink";
import Transition from "./Transition";
import { Link, useHistory } from "react-router-dom";
import Logo from "./Logo";
import Url from "src/utils/Url";
import { useAuthContext } from "src/contexts/AuthContext";
import { IconChevronDown } from "./Icons/IconChevronDown";
import useSession from "src/hooks/useSession";

interface IProps {
  docsOnly?: boolean;
}

export default function TopBar({ docsOnly = false }: IProps) {
  const [profileOpen, setProfileOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { currentUser } = useAuthContext();
  const history = useHistory();
  const { logOut } = useSession();

  return (
    <Fragment>
      <nav className="bg-white shadow-sm sticky top-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Link to={Url.rootPath()}>
                  {/* <img className="w-20 h-auto" src={logo} alt="preferr" /> */}
                  <Logo width={120} />
                </Link>
              </div>
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline">
                  {!docsOnly && (
                    <Fragment>
                      <TopBarLink
                        title="A/B Tests"
                        linkTo="/ab-tests"
                        className="mr-4"
                      />
                      <TopBarLink
                        title="Visitors"
                        linkTo="/visitors"
                        className="mr-4"
                      />
                    </Fragment>
                  )}
                  <TopBarLink
                    title="Documentation"
                    linkTo={Url.documentationHowItWorksPath()}
                  />
                </div>
              </div>
            </div>
            {!docsOnly && (
              <Fragment>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* <!-- Profile dropdown --> */}
                    <div className="ml-3 relative">
                      <div>
                        <button
                          className="max-w-xs flex items-center text-sm text-gray-800 focus:outline-none"
                          id="user-menu"
                          aria-label="User menu"
                          aria-haspopup="true"
                          onClick={() => setProfileOpen(!profileOpen)}
                        >
                          {currentUser?.nameOrEmail}
                          <IconChevronDown className="text-gray-400" />
                        </button>
                      </div>
                      <Transition
                        show={profileOpen}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg">
                          <div
                            className="py-1 rounded-md bg-white shadow-xs"
                            role="menu"
                            aria-orientation="vertical"
                            aria-labelledby="user-menu"
                          >
                            <Link
                              to={Url.accountPath()}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              role="menuitem"
                              onClick={() => setProfileOpen(false)}
                            >
                              Account
                            </Link>
                            {currentUser?.role === "owner" && (
                              <Link
                                to={Url.billingPath()}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                                onClick={() => setProfileOpen(false)}
                              >
                                Billing
                              </Link>
                            )}
                            {currentUser?.role === "owner" && (
                              <Link
                                to={Url.organizationPath()}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                role="menuitem"
                                onClick={() => setProfileOpen(false)}
                              >
                                Organization
                              </Link>
                            )}
                            <a
                              href="/"
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                              role="menuitem"
                              onClick={() => {
                                logOut();
                                history.push("/sign-in");
                              }}
                            >
                              Sign out
                            </a>
                          </div>
                        </div>
                      </Transition>
                    </div>
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* <!-- Mobile menu button --> */}
                  <button
                    className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-200 focus:outline-none focus:bg-gray-200 focus:text-white"
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                  >
                    {/* <!-- Menu open: "hidden", Menu closed: "block" --> */}
                    <svg
                      className={`${
                        mobileMenuOpen ? "hidden" : "block"
                      } h-6 w-6`}
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                    {/* <!-- Menu open: "block", Menu closed: "hidden" --> */}
                    <svg
                      className={`${
                        mobileMenuOpen ? "block" : "hidden"
                      } h-6 w-6`}
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        </div>

        {/* <!--
      Mobile menu, toggle classNamees based on menu state.

      Open: "block", closed: "hidden"
    --> */}
        <div className={`${mobileMenuOpen ? "block" : "hidden"} md:hidden`}>
          <div className="px-2 pt-2 pb-3 sm:px-3">
            {!docsOnly && (
              <Fragment>
                <TopBarLink title="A/B Tests" linkTo={Url.rootPath()} />
                <TopBarLink title="Visitors" linkTo={Url.visitorsPath()} />
              </Fragment>
            )}
            <TopBarLink
              title="Documentation"
              linkTo={Url.documentationPath()}
            />
          </div>
          {!docsOnly && (
            <div className="pt-4 pb-3 border-t border-gray-200">
              <div className="flex items-center px-5">
                <div className="mt-1 text-sm font-medium leading-none text-gray-400">
                  {currentUser?.nameOrEmail}
                </div>
              </div>
              <div className="mt-3 px-2">
                <Link
                  to={Url.accountPath()}
                  className="block px-3 py-2 rounded-md text-sm font-medium text-gray-400 hover:text-white hover:bg-gray-100 focus:outline-none focus:text-white focus:bg-gray-100"
                >
                  Account
                </Link>
                <a
                  href="/"
                  className="mt-1 block px-3 py-2 rounded-md text-sm font-medium text-gray-400 hover:text-white hover:bg-gray-100 focus:outline-none focus:text-white focus:bg-gray-100"
                  onClick={() => {
                    logOut();
                    history.push("/sign-in");
                  }}
                >
                  Sign out
                </a>
              </div>
            </div>
          )}
        </div>
      </nav>
    </Fragment>
  );
}
