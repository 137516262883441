import React, {
  ReactNodeArray,
  ReactNode,
  ReactElement,
  Fragment,
  useState,
  useRef
} from "react";
import Transition from "./Transition";
import { useOnClickOutside } from "src/hooks/useOnClickOutside";

interface IProps {
  children: ReactNode | ReactNodeArray;
  trigger: ReactElement;
}

export default function PopupMenu({ children, trigger }: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  useOnClickOutside(ref, () => setIsOpen(false));

  const newProps = {
    ...trigger.props,
    onClick: () => {
      setIsOpen(true);
    }
  };
  const clonedTrigger = React.cloneElement(trigger, newProps);

  return (
    <Fragment>
      {clonedTrigger}
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <div className="relative" ref={ref}>
          <div className="origin-top-right right-0 absolute mt-6 w-48 rounded-md shadow-lg">
            <div
              className="py-1 rounded-md bg-white shadow-xs"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              {children}
            </div>
          </div>
        </div>
      </Transition>
    </Fragment>
  );
}
