import { Space } from "@cohubinc/blueprint";
import Tippy from "@tippyjs/react";
import truncate from "lodash/truncate";
import React, { Fragment, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useHistory, useParams } from "react-router-dom";
import Badge from "src/components/Badge";
import Button from "src/components/Button";
import {
  useArchiveAbTestMutation,
  useDeleteAbTestMutation,
  useFindAbTestQuery
} from "src/components/graphql";
import { IconDotsVertical } from "src/components/Icons/IconDotsVertical";
import { IconQuestionMarkCircle } from "src/components/Icons/IconQuestionMarkCircle";
import Loader from "src/components/Loader";
import Modal from "src/components/Modal";
import PopupMenu from "src/components/PopupMenu";
import SideSheet from "src/components/SideSheet";
import Statistic from "src/components/Statistic";
import { useNotification } from "src/contexts/NotificationContext";
import { dateAtTime } from "src/utils/dateAtTime";
import Url from "src/utils/Url";
import "tippy.js/dist/tippy.css";
import AbTestCodeSnippet from "./AbTestCodeSnippet";
import AbTestVariantCard from "./AbTestVariantCard";
import TestHealthPod from "./TestHealthPod";
import UpdateAbTestForm from "./UpdateAbTestForm";

export default function AbTest() {
  const { apiId } = useParams<any>();
  const history = useHistory();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [showVariants, setShowVariants] = useState(false);
  const [uniqueStats, setUniqueStats] = useState(true);
  const [showAbTestForm, setShowAbTestForm] = useState(false);
  const { setNotificationOpen, setCurrentNotification } = useNotification();

  const { loading, data } = useFindAbTestQuery({
    variables: {
      apiId
    },
    pollInterval: 60_000
  });

  useEffect(() => {
    if (data?.abTest) {
      const hasVariants = data.abTest.variants.every(
        (variant) => variant.impressionCount > 0
      );

      if (hasVariants) {
        setShowVariants(true);
      }
    }
  }, [data]);

  const [deleteAbTestMutation] = useDeleteAbTestMutation();
  const [archiveAbTestMutation] = useArchiveAbTestMutation();

  if (loading && !data) {
    return <Loader fullScreen />;
  }

  if (!data) {
    return null;
  }

  if (data && !data.abTest) {
    setNotificationOpen(true);
    setCurrentNotification({
      open: true,
      version: "info",
      title: "That A/B test doesn't exist"
    });

    history.push(Url.abTestsPath());
    return null;
  }

  const deleteAbTest = async () => {
    const result = await deleteAbTestMutation({
      variables: { abTestId: abTest.id },
      refetchQueries: ["ListAbTests"]
    });

    if (result.data?.deleteAbTest?.success) {
      setNotificationOpen(true);
      setCurrentNotification({
        open: true,
        version: "success",
        title: "A/B test deleted"
      });
      history.push("/ab-tests");
    }
  };

  const archiveAbTest = async () => {
    const result = await archiveAbTestMutation({
      variables: { abTestId: abTest.id },
      refetchQueries: ["ListAbTests"]
    });

    if (result.data?.archiveAbTest?.success) {
      setNotificationOpen(true);
      setCurrentNotification({
        open: true,
        version: "success",
        title: "A/B test archived"
      });
      history.push("/ab-tests");
    }
  };

  const abTest = data.abTest!;

  const variants = abTest.variants;

  const pValueTooltip = (
    <Tippy content="The p-value represents how statistically significant the result of this test is. A p-value below 0.05 indicates that your test is statistically significant and you can be confident in your results.">
      <div>
        <IconQuestionMarkCircle className="text-gray-400" />
      </div>
    </Tippy>
  );

  const conversionLiftMeta = uniqueStats
    ? `(${variants[1].uniqueConversionRatePercentage}% -
    ${variants[0].uniqueConversionRatePercentage}%) /
    ${variants[0].uniqueConversionRatePercentage}%`
    : `(${variants[1].conversionRatePercentage}% -
    ${variants[0].conversionRatePercentage}%) /
    ${variants[0].conversionRatePercentage}%`;

  const targetSampleSizeMeta = abTest.targetSampleSize ? (
    <div>
      <NumberFormat
        thousandSeparator
        displayType="text"
        value={abTest.targetSampleSize}
      />{" "}
      visitors needed
    </div>
  ) : undefined;

  return (
    <Fragment>
      <div className="flex justify-between w-full">
        <div className="w-full md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <div className="flex items-center">
              <div>
                <div className="flex items-center">
                  <h2 className="mb-2 mr-4 text-2xl font-bold leading-7 text-gray-600 sm:text-3xl sm:leading-9 sm:truncate">
                    {abTest.name}
                  </h2>
                  {abTest.archived && (
                    <Badge
                      size="md"
                      text="Archived"
                      className="text-blue-500 bg-blue-100"
                    />
                  )}
                </div>
                <div className="mb-2 text-sm font-medium text-gray-400">
                  Active since {dateAtTime(abTest.createdAt)}
                </div>
                <div className="text-sm font-medium text-gray-400">
                  Running on{" "}
                  <a
                    href={abTest.recordedUrl || "No URL recorded yet"}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {truncate(abTest.recordedUrl || "", { length: 40 })}
                  </a>
                </div>
              </div>
              {/* <IconEdit className="ml-4 text-blue-600" /> */}
            </div>
          </div>
          <div className="flex items-center mt-4 md:mt-0 md:ml-4">
            <Tippy
              content="Unique stats normalize your data to one click/conversion per visitor. This is the standard way of viewing data, but you can toggle it off if you'd like to see stats that include all conversions for each visitor."
              placement="bottom"
              maxWidth={300}
              className="text-center"
            >
              <div>
                <IconQuestionMarkCircle className="mr-2 text-gray-400" />
              </div>
            </Tippy>
            <Button
              version={"plain"}
              onClick={() => setUniqueStats(!uniqueStats)}
              className="flex items-center justify-between mr-4"
            >
              Unique Stats{" "}
              <div
                className={`font-bold ml-2 ${
                  uniqueStats ? "text-green-500" : "text-red-500"
                }`}
              >
                {uniqueStats ? "ON" : "OFF"}
              </div>
            </Button>
            <PopupMenu trigger={<IconDotsVertical className="text-gray-600" />}>
              <span
                className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                role="menuitem"
                onClick={() => setShowAbTestForm(true)}
              >
                Edit
              </span>
              <span
                className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                role="menuitem"
                onClick={() => setConfirmArchive(true)}
              >
                Archive
              </span>
              <span
                className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                role="menuitem"
                onClick={() => setConfirmDelete(true)}
              >
                Delete
              </span>
            </PopupMenu>
          </div>
        </div>
      </div>
      <Space height={2} />
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
        <Statistic
          metric="Total Visitors"
          value={abTest.totalVisitors}
          elevated
          valueDescriptor={
            <TestHealthPod
              visitorCount={abTest.totalVisitors}
              targetSampleSize={abTest.targetSampleSize}
            />
          }
          meta={targetSampleSizeMeta}
        />
        <Statistic
          metric="Conversion Lift"
          value={`${
            uniqueStats
              ? abTest.uniqueConversionLiftPercentage
              : abTest.conversionLiftPercentage
          }%`}
          elevated
          meta={conversionLiftMeta}
        />
        {/* <Statistic
          metric="Click Lift"
          value={`${
            uniqueStats
              ? abTest.uniqueClickLiftPercentage
              : abTest.clickLiftPercentage
          }%`}
          elevated
          meta={clickLiftMeta}
        /> */}
        <Statistic
          metric="p-value"
          value={abTest.pValue}
          elevated
          valueDescriptor={pValueTooltip}
          meta={abTest.pValueDescription}
        />
      </div>
      <Space height={3} />
      {showVariants && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {variants.map((v) => (
            <AbTestVariantCard
              key={v.apiId}
              variant={v}
              uniqueStats={uniqueStats}
            />
          ))}
        </div>
      )}
      <Space height={3} />
      <div className="text-lg font-medium">Code</div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="">
          <div className="text-base text-gray-500">
            Use this code snippet to wrap the two components you want to test.
          </div>
          <Space height={1} />
          <AbTestCodeSnippet snippet={abTest.snippet} />
        </div>
        <div className="">
          <div className="text-base text-gray-500">
            Convert the A/B Test with this code snippet.
          </div>
          <Space height={1} />
          <AbTestCodeSnippet snippet={`Preferr.convert("${abTest.apiId}")`} />
        </div>
      </div>
      <Modal
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        title={{ title: "Are you sure?" }}
      >
        <div>Deleting this A/B Test cannot be undone.</div>
        <div className="flex items-center justify-around mt-6">
          <Button version="plain" onClick={() => setConfirmDelete(false)}>
            Cancel
          </Button>
          <Button version="danger" onClick={deleteAbTest}>
            Yes, delete it
          </Button>
        </div>
      </Modal>
      <Modal
        open={confirmArchive}
        onClose={() => setConfirmArchive(false)}
        title={{ title: "Are you sure?" }}
      >
        <div>
          Archiving this A/B test will hide it when viewing your list of A/B
          tests. You can always unarchive it later.
        </div>
        <div className="flex items-center justify-around mt-6">
          <Button version="plain" onClick={() => setConfirmArchive(false)}>
            Cancel
          </Button>
          <Button version="primary" onClick={archiveAbTest}>
            Yes, archive it
          </Button>
        </div>
      </Modal>
      <SideSheet
        open={showAbTestForm}
        title="New A/B Test"
        onClose={() => setShowAbTestForm(false)}
      >
        <UpdateAbTestForm
          onSuccess={() => setShowAbTestForm(false)}
          abTest={abTest}
        />
      </SideSheet>
    </Fragment>
  );
}
