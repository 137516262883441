import React from "react";
import { Link } from "react-router-dom";
import Button from "src/components/Button";
import sideBySide from "../../../assets/images/preferr-side-by-side-bf.png";

export default function SideBySide() {
  return (
    <div className="py-8 md:py-12 bg-white mt-8 md:mt-20 mb-12 rounded-lg shadow-xl">
      <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h3 className="font-poppins mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            See your users preferences
          </h3>
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
            Preferr keeps track of what your rendered variants look like so that
            you can quickly see what's working and what isn't.
          </p>
        </div>

        <div className="mt-10">
          <img src={sideBySide} alt="side-by-side" />
        </div>
      </div>
      <Link to="/demo" className="flex justify-center mt-8">
        <Button version="primary" size="xl">
          View Live Demo
        </Button>
      </Link>
    </div>
  );
}
