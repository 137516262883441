import Tippy from "@tippyjs/react";
import React from "react";
import CodeSnippet from "src/components/CodeSnippet";
import { IconBulb } from "src/components/Icons/IconBulb";
import { IconCode } from "src/components/Icons/IconCode";
import { IconCursorClick } from "src/components/Icons/IconCursorClick";
import { IconHand } from "src/components/Icons/IconHand";
import { IconInfinity } from "src/components/Icons/IconInfinity";
import { IconQuestionMarkCircle } from "src/components/Icons/IconQuestionMarkCircle";
import { IconTrendingUp } from "src/components/Icons/IconTrendingUp";
import Statistic from "src/components/Statistic";
import TestHealthPod from "src/screens/AbTests/TestHealthPod";
import Feature from "./Feature";

export default function Features() {
  return (
    <div className="overflow-hidden mb-20 md:mb-60" id="features">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        {/* <div className="relative">
          <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Refine your UI
          </h3>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
            Get closer to the ideal user interface by testing two components
            simultaneously.
          </p>
        </div> */}
      </div>
      <div
        className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"
        id="features"
      >
        <div className="mt-10 -mx-4 relative lg:mt-0 sm:px-8 mb-8">
          <div className="flex justify-center">
            <CodeSnippet
              code={`
<ABTest
  abTestId="abt_e91f9f007bd2096875ebe6ec2943fd6d"
  variantAId="abv_e0bad4b1f1fb69d0a531359043d9fe1a"
  variantBId="abv_b9b5cabcf7ccabe12301bce31e270b84"
>
  <button className="light-blue">Add to cart</button>
  <button className="deep-blue">Add to bag</button>
</ABTest>
                `}
              language="jsx"
            />
          </div>
        </div>
        <div
          className="relative py-4 px-6 rounded-lg"
          style={{
            backgroundColor: "rgba(255,255,255, 0.3)",
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.05)"
          }}
        >
          {/* <h4 className="font-poppins text-2xl leading-8 font-extrabold text-gray-800 tracking-tight sm:text-3xl sm:leading-9">
            Made for devs and designers
          </h4> */}
          <h4 className="font-poppins text-2xl leading-8 font-extrabold text-gray-800 tracking-tight sm:text-3xl sm:leading-9">
            Test components, not pages
          </h4>
          {/* <p className="mt-3 text-lg leading-7 text-gray-600">
              Most A/B testing software is built for marketing and sales people.
              Preferr is for developers, designers, UX pros, and anyone else
              looking to refine their users' experience.
            </p> */}
          {/* <p className="mt-3 text-lg leading-7 text-gray-600">
              Most A/B testing software is built to take the developer or
              designer out of the equation. An add-on here, a plugin there, and
              now there's an extra layer of abstraction between the design being
              tested, and the software running the test. Preferr puts the test
              where it belongs: directly into the code.
            </p> */}
          {/* <p className="mt-3 text-lg leading-7 text-gray-600">
            Most A/B testing software is added to a website or app last, slapped
            on top right at the end. That means the test itself is kept separate
            from what's really being tested, the design and code. Preferr puts
            the test where it belongs: directly into the code.
          </p> */}
          <p className="mt-3 text-lg leading-7 text-gray-600">
            A/B tests are usually run by creating two variations of a page and
            splitting visitor traffic between them. That's fine if you're
            testing landing pages, but when you're testing the way users
            interact with your site or app, you can't always send them to a
            different URL.
          </p>

          <ul className="mt-10">
            <Feature
              icon={<IconCursorClick />}
              title="Not all clicks are conversions"
              body="A single function call is all it takes to create a
                conversion, allowing you to convert based on events like
                successful API calls, time on screen, and anything else
                you can dream up."
            />
            <Feature
              icon={<IconInfinity />}
              title="Persisted rendering"
              body="We'll make sure that your users see a consistent UI. Once
                      they've been presented with a variant of your component,
                      they'll see it forever."
            />
            <Feature
              icon={<IconCode />}
              title="No extra props"
              body="Your components will work exactly as you code them.
                Preferr simply wraps the components and listens for
                conversion events in the background."
            />
          </ul>
        </div>
      </div>
      <div
        className="relative mt-12 lg:mt-32 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"
        id="features"
      >
        <div
          className="relative py-4 px-6 rounded-lg m-1"
          style={{
            backgroundColor: "rgba(255,255,255, 0.3)",
            boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)"
          }}
        >
          <h4 className="font-poppins text-2xl leading-8 font-extrabold text-gray-800 tracking-tight sm:text-3xl sm:leading-9">
            Keep it simple
          </h4>
          <p className="mt-3 text-lg leading-7 text-gray-600">
            You don't need to be a statistician or marketing maverick to get
            useful information out of an A/B test.
          </p>

          <ul className="mt-10">
            <Feature
              icon={<IconTrendingUp />}
              title="Numbers you want"
              body="Get meaningful stats like conversion lift, conversion rates, and a p-value calculation so you know how statistically significant your data is."
            />
            <Feature
              icon={<IconBulb />}
              title="What learning curve?"
              body="There are no lengthy tutorials, excessively featured user interfaces, or confusing terminology. Documentation is short and sweet and you'll be up and running in minutes."
            />
            <Feature
              icon={<IconHand />}
              title="On your terms"
              body="Enrich your tests by optionally identifying visitors
                      with your own data and terminology."
            />
          </ul>
        </div>
        <div className="mt-10 -mx-4 relative lg:mt-0 sm:px-8 mb-8">
          <div className="grid grid-rows-3 gap-4">
            <div className="flex items-center justify-start">
              <Statistic
                metric="Total Visitors"
                value="2,304"
                elevated
                valueDescriptor={<TestHealthPod visitorCount={2304} />}
                meta="&nbsp;"
                className="w-1/2"
              />
            </div>
            <div className="flex items-center justify-center">
              <Statistic
                metric="Conversion Lift"
                value="25.8%"
                elevated
                meta="(40.5% - 32.19%) / 32.19%"
                className="w-1/2"
              />
            </div>
            <div className="flex items-center justify-end">
              <Statistic
                metric="p-value"
                value="0.0327"
                elevated
                valueDescriptor={
                  <Tippy content="The p-value represents how statistically significant the result of this test is. A p-value below 0.05 indicates that your test is statistically significant and you can be confident in your results.">
                    <div>
                      <IconQuestionMarkCircle className="text-gray-400" />
                    </div>
                  </Tippy>
                }
                meta="Computed using chi square test of independence"
                className="w-1/2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
