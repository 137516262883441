import React from "react";
import Segment from "src/components/Segment";

export default function Support() {
  return (
    <div className="relative overflow-hidden">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6">
        <div className="mt-8 pb-16 sm:mt-12 sm:pb-20 lg:pb-28">
          <div className="relative mx-auto px-4 sm:px-6 lg:px-8 w-full sm:w-1/3">
            <Segment>
              <div className="text-center font-poppins text-2xl text-gray-800">
                Need some help?
              </div>
              <div className="text-center">
                Send a message to
                <a
                  className="block text-center text-blue-600"
                  href="mailto:info@preferr.app"
                >
                  info@preferr.app
                </a>
              </div>
            </Segment>
          </div>
        </div>
      </div>
    </div>
  );
}
