import React, { CSSProperties, useEffect, useState } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { xonokai } from "react-syntax-highlighter/dist/esm/styles/prism";
import styled from "styled-components";
import Button from "./Button";
import copyToClipboard from "src/utils/copyToClipboard";

const BareSnippet = styled.span<Partial<ICodeSnippetProps>>`
  border-radius: 4px;
  padding: ${(props) => (props.inline ? "0.25rem" : "1rem")};
  background-color: #f0f5f9;
  font-size: 14px;
  display: ${(props) => (props.inline ? "inline-block" : "block")};
`;

interface ICodeSnippetProps {
  code: string;
  bare?: boolean;
  inline?: boolean;
  language?: string;
  style?: CSSProperties;
  className?: string;
  copy?: boolean;
}

export default function CodeSnippet({
  code,
  bare = false,
  inline = false,
  language,
  style,
  className,
  copy
}: ICodeSnippetProps) {
  const [copiedSnippet, setCopiedSnippet] = useState(false);

  useEffect(() => {
    if (copiedSnippet) {
      setTimeout(() => {
        setCopiedSnippet(false);
      }, 3_000);
    }
  }, [copiedSnippet]);

  function handleCopyToClipBoard() {
    setCopiedSnippet(true);
    copyToClipboard(code);
  }

  const copyButton = (
    <Button
      type="button"
      version="secondary"
      size="sm"
      onClick={() => handleCopyToClipBoard()}
    >
      {copiedSnippet ? "Copied!" : "Copy"}
    </Button>
  );

  if (bare) {
    return (
      <BareSnippet
        inline={inline}
        style={style}
        className={`${className} ${inline ? "" : "w-full"}`}
      >
        <code className="text-gray-800">{code}</code>
      </BareSnippet>
    );
  } else {
    return (
      <div className={`${className} w-full`}>
        <SyntaxHighlighter
          language={language || "js"}
          style={xonokai}
          customStyle={{
            width: "inherit",
            border: "none",
            borderRadius: "10px",
            background: "hsla(212, 25%, 20%, 1)"
          }}
        >
          {code}
        </SyntaxHighlighter>
        {copy && copyButton}
      </div>
    );
  }
}
