import React, { createContext, ReactNode } from "react";
import useCurrentUser from "src/hooks/useCurrentUser";
import { IUserFragment, IOrganizationFragment } from "src/components/graphql";

export interface IAuthState {
  currentUser: IUserFragment | undefined;
  currentOrganization: IOrganizationFragment | undefined;
}

export const AuthContext = createContext<IAuthState>({
  currentUser: undefined,
  currentOrganization: undefined
});

interface Props {
  children: ReactNode;
}

export function AuthProvider({ children }: Props) {
  const { currentUser } = useCurrentUser();

  const context: IAuthState = {
    currentUser: currentUser,
    currentOrganization: currentUser?.organization
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
}

export function useAuthContext() {
  return React.useContext(AuthContext);
}
