import { Divider, Stack } from "@cohubinc/blueprint";
import { Preferr } from "@preferr-app/preferr-client";
import pick from "lodash/pick";
import React, { useState } from "react";
import { Field, Form } from "react-final-form";
import { Link, useHistory } from "react-router-dom";
import Button from "src/components/Button";
import Errors from "src/components/Errors";
import TextField from "src/components/Forms/TextField";
import { IconEye } from "src/components/Icons/IconEye";
import Logo from "src/components/Logo";
import Segment from "src/components/Segment";
import { host, protocol } from "src/config";

const SignUp: React.FC = () => {
  const history = useHistory();
  const [errors, setErrors] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  const onSubmit = async (values: any) => {
    const url = `${protocol}://${host}/api/users`;
    const userPayload = pick(values, [
      "email",
      "password",
      "organization_name"
    ]);

    const payload = {
      user: userPayload
    };

    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });

    const result = await response.json();

    if (result.user) {
      const { email, authentication_token } = result.user;
      localStorage.setItem("userEmail", email);
      localStorage.setItem("userAuthToken", authentication_token);
      history.push("/ab-tests");
      Preferr.convert("abt_c33c6cea9ceb02c186c802d846f5d40d");
      window.location.reload();
    } else if (result.errors) {
      setErrors(result.errors);
    }
  };

  return (
    <div className="mb-12">
      <Form
        initialValues={{}}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => {
          return (
            <div className="flex items-center h-3/4">
              <div className="w-full md:w-1/3 max-w-sm px-4 md:mx-auto">
                <Segment>
                  {!!errors.length && <Errors errors={errors} />}
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <div className="flex justify-center items-center mt-6 mb-6">
                      <Logo width={200} />
                    </div>
                    <div className="flex justify-center items-center mb-6">
                      <h3
                        className="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-blue-100 text-blue-600"
                        id="tier-standard"
                      >
                        Try it free for 7 days
                      </h3>
                    </div>
                    <Stack fullWidth>
                      <Field
                        name="organization_name"
                        type="text"
                        autoFocus
                        render={({ input, meta }) => (
                          <TextField
                            label="Organization or Company"
                            {...input}
                          />
                        )}
                      />
                      <Field
                        name="email"
                        type="email"
                        autoFocus
                        render={({ input, meta }) => (
                          <TextField label="Email" {...input} />
                        )}
                      />
                      <Field
                        name="password"
                        type={showPassword ? "text" : "password"}
                        render={({ input, meta }) => (
                          <div className="w-full relative">
                            <TextField label="Password" {...input} />
                            <IconEye
                              onClick={() => setShowPassword(!showPassword)}
                              className="text-gray-400 cursor-pointer"
                              style={{
                                position: "absolute",
                                top: "58%",
                                right: "12px",
                                transform: "translateY(-58%)"
                              }}
                            />
                          </div>
                        )}
                      />
                      <Button type="submit" fullWidth className="mt-8">
                        Sign Up
                      </Button>
                    </Stack>
                  </form>
                  <Divider marginSize={1} />
                  <div className="flex justify-center items-center">
                    <Link
                      to="/sign-in"
                      className="text-gray-600 uppercase text-sm font-medium"
                    >
                      Sign In
                    </Link>
                  </div>
                </Segment>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};

export default SignUp;
