import React from "react";
import { IAbTestFragment } from "src/components/graphql";
import Card from "src/components/Card";
import { useHistory } from "react-router-dom";
import Url from "src/utils/Url";
import Statistic from "src/components/Statistic";

interface IProps {
  abTest: Omit<IAbTestFragment, "variants" | "pValue" | "pValueDescription">;
}

export default function AbTestCard({ abTest }: IProps) {
  const history = useHistory();

  return (
    <Card
      title={abTest.name}
      onTitleClick={() => history.push(Url.abTestPath(abTest.apiId))}
      titleAction={{
        content: (
          <span className="text-xs font-bold text-blue-500 uppercase">
            View
          </span>
        ),
        onAction: () => history.push(Url.abTestPath(abTest.apiId))
      }}
      className="mb-4"
      titleBadge={abTest.archived ? "Archived" : ""}
    >
      <div className="flex justify-between">
        <Statistic metric="Total Visitors" value={abTest.totalVisitors} />
        <Statistic
          metric="Conversion Lift"
          value={`${abTest.uniqueConversionLiftPercentage}%`}
        />
        <Statistic metric="Winner" value={abTest.winner} />
      </div>
    </Card>
  );
}
