import React from "react";
import DemoSwitcher from "./DemoSwitcher";
import Button from "src/components/Button";
import { useHistory } from "react-router-dom";
import { ABTest } from "@preferr-app/preferr-client";

export default function HomeHero() {
  const history = useHistory();

  return (
    <div className="mt-10 mb-20 lg:grid lg:grid-cols-12 lg:gap-8 px-4 sm:px-6 lg:px-8">
      <div className="sm:text-center md:max-w-2xl md:mx-auto lg:col-span-6 lg:text-left">
        {/* <div className="text-sm font-semibold uppercase tracking-wide text-gray-50 sm:text-base lg:text-sm xl:text-base">
          Coming soon
        </div> */}
        <h1 className="font-poppins mt-1 text-4xl text-blue-100 tracking-tight leading-10 font-extrabold sm:leading-none sm:text-6xl lg:text-5xl xl:text-6xl">
          A/B Testing <br className="hidden md:inline" />
          <span className="text-blue-900">for React</span>
        </h1>
        <p className="mt-3 text-base text-blue-100 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
          Make informed design decisions based on real interaction data. Minimal
          set up, maximum insight.
        </p>
        {/* <p className="mt-3 text-blue-100 sm:mt-5 sm:text-md">
          Created by a designer and developer, for other designers and
          developers.
        </p> */}
        <div className="mt-5 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
          {/* <p className="text-base font-medium text-blue-200">
            Sign up to get notified when it’s ready.
          </p>
          <form action="#" method="POST" className="mt-3 sm:flex">
            <input
              aria-label="Email"
              className="appearance-none block w-full px-3 py-3 border border-gray-300 text-base leading-6 rounded-md placeholder-gray-500 shadow-sm focus:outline-none focus:placeholder-gray-400 focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:flex-1"
              placeholder="Enter your email"
            />
            <button
              type="submit"
              className="mt-3 w-full px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-800 shadow-sm hover:bg-gray-700 focus:outline-none focus:border-gray-900 focus:shadow-outline-gray active:bg-gray-900 transition duration-150 ease-in-out sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
            >
              Notify me
            </button>
          </form> */}
          <ABTest
            abTestId="abt_c33c6cea9ceb02c186c802d846f5d40d"
            variantAId="abv_aa23cd73685077a3c62e6202dc701123"
            variantBId="abv_54db8f668934917e943abfae38742647"
          >
            <Button
              size="2xl"
              version="primary"
              onClick={() => history.push("/sign-up")}
              className="bg-blue-900"
            >
              Try it free for 7 days
            </Button>
            <Button
              size="2xl"
              version="secondary"
              onClick={() => history.push("/sign-up")}
            >
              Start your 7 day free trial
            </Button>
          </ABTest>
        </div>
      </div>
      <div className="mt-12 relative sm:max-w-lg sm:mx-auto lg:mt-0 lg:max-w-none lg:mx-0 lg:col-span-6 lg:flex lg:items-center">
        {/* <svg
          className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-8 scale-75 origin-top sm:scale-100 lg:hidden"
          width="640"
          height="784"
          fill="none"
          viewBox="0 0 640 784"
        >
          <defs>
            <pattern
              id="4f4f415c-a0e9-44c2-9601-6ded5a34a13e"
              x="118"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            y="72"
            width="640"
            height="640"
            className="text-gray-50"
            fill="currentColor"
          />
          <rect
            x="118"
            width="404"
            height="784"
            fill="url(#4f4f415c-a0e9-44c2-9601-6ded5a34a13e)"
          />
        </svg> */}
        <div className="relative mx-auto w-full lg:max-w-md">
          <DemoSwitcher />
        </div>
      </div>
    </div>
  );
}
