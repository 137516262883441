import React, { useState, Fragment } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { useUpdatePaymentMethodMutation } from "src/components/graphql";
import Button from "src/components/Button";
import { Form } from "react-final-form";
import Errors from "src/components/Errors";
import Segment from "src/components/Segment";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

interface IProps {
  onSuccess: () => void;
}

export default function PaymentMethodUpdateForm({ onSuccess }: IProps) {
  const [formErrors, setFormErrors] = useState<string[]>([]);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const [updatePaymentMethodMutation] = useUpdatePaymentMethodMutation();

  const updatePaymentMethod = async (stripePaymentMethodId: string) => {
    const result = await updatePaymentMethodMutation({
      variables: {
        stripePaymentMethodId
      },
      refetchQueries: ["FindCurrentUser", "FindOrganization"]
    });

    if (result.data?.updatePaymentMethod?.success) {
      onSuccess();
      // handlePaymentThatRequiresCustomerAction(stripePaymentMethodId);
    }
  };

  // This is for 3D secure payment stuff that I don't fully understand
  // function handlePaymentThatRequiresCustomerAction(paymentMethodId: string) {
  //   let setupIntent = stripeSubscription.pending_setup_intent;

  //   // Do 3D secure stuff if the setupIntent requiresAction
  //   // As far as I can tell, the setupIntent returns an ID and not an object like the docs say
  //   if (setupIntent && setupIntent.status === "requires_action") {
  //     return stripe!
  //       .confirmCardSetup(setupIntent.client_secret, {
  //         payment_method: paymentMethodId
  //       })
  //       .then((result) => {
  //         if (result.error) {
  //           // start code flow to handle updating the payment details
  //           // Display error message in your UI.
  //           // The card was declined (i.e. insufficient funds, card has expired, etc)
  //           setFormErrors([result.error.message!]);
  //           throw result;
  //         } else {
  //           if (result.setupIntent?.status === "succeeded") {
  //             // There's a risk of the customer closing the window before callback
  //             // execution. To handle this case, set up a webhook endpoint and
  //             // listen to setup_intent.succeeded.
  //             return {
  //               priceId: priceId,
  //               subscription: stripeSubscription,
  //               paymentMethodId: paymentMethodId
  //             };
  //           }
  //         }
  //       });
  //   } else {
  //     onSuccess();
  //   }
  // }

  const createPaymentMethod = async () => {
    const cardElement = elements!.getElement(CardElement)!;

    const paymentMethodResult = await stripe!.createPaymentMethod({
      type: "card",
      card: cardElement
    });
    // .then((result) => {
    //   if (result.error) {
    //     displayError(error);
    //   } else {
    //     createSubscription({
    //       customerId: customerId,
    //       paymentMethodId: result.paymentMethod.id,
    //       priceId: priceId,
    //     });
    //   }
    // });
    if (paymentMethodResult.error) {
      setFormErrors([paymentMethodResult.error.message!]);
    } else {
      await updatePaymentMethod(paymentMethodResult.paymentMethod!.id);
    }
  };

  return (
    <Fragment>
      {formErrors.length > 0 && <Errors errors={formErrors} className="mb-1" />}
      <Form
        onSubmit={createPaymentMethod}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Segment>
              <CardElement
                onChange={(evt) => {
                  if (evt.complete) {
                    setSubmitEnabled(true);
                  }
                }}
                options={{
                  style: {
                    base: {
                      color: "#32325d",
                      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                      fontSmoothing: "antialiased",
                      fontSize: "16px",
                      "::placeholder": {
                        color: "#aab7c4"
                      }
                    },
                    invalid: {
                      color: "#fa755a",
                      iconColor: "#fa755a"
                    }
                  }
                }}
              />
            </Segment>
            <div className="flex justify-end items-center mt-4">
              <Button
                version="primary"
                type="submit"
                disabled={submitting || !submitEnabled}
              >
                Confirm
              </Button>
            </div>
          </form>
        )}
      />
    </Fragment>
  );
}
