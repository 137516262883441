import React, { ReactNode, ReactNodeArray } from "react";
import { IconCheckCircle } from "src/components/Icons/IconCheckCircle";

interface IProps {
  title: string;
  children: ReactNode | ReactNodeArray;
}

export default function Do({ title, children }: IProps) {
  return (
    <div className="mb-10">
      <div className="text-xl mb-4 flex items-center">
        <IconCheckCircle className="mr-2 text-green-500" /> {title}
      </div>
      {children}
    </div>
  );
}
