import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/react";
import { Preferr } from "@preferr-app/preferr-client";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn:
      "https://f1c2771b26044538a6e6ddfff90cfed4@o421335.ingest.sentry.io/5340941"
  });

  Preferr.init("5479cf7583e15842f4f7d3bc10f6b428");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
