export default class Url {
  static rootPath(): string {
    return "/";
  }

  static dashboardPath(): string {
    return "/dashboard";
  }

  static abTestPath(abTestId: string | number): string {
    return `/ab-tests/${abTestId}`;
  }

  static abTestInfoPath(abTestId: string | number): string {
    return `/ab-tests/${abTestId}/info`;
  }

  static abTestCodePath(abTestId: string | number): string {
    return `/ab-tests/${abTestId}/code`;
  }

  static abTestsPath(): string {
    return "/ab-tests";
  }

  static visitorPath(visitorId: string | number): string {
    return `/visitors/${visitorId}`;
  }
  static visitorsPath(): string {
    return "/visitors";
  }

  static accountPath(): string {
    return "/account";
  }

  static organizationPath(): string {
    return "/organization";
  }

  static billingPath(): string {
    return "/billing";
  }

  static paymentSetupPath(): string {
    return "/payment-setup";
  }

  static documentationGettingStartedPath(): string {
    return "/documentation/getting-started";
  }

  static documentationHowItWorksPath(): string {
    return "/documentation";
  }

  static documentationPath(): string {
    return "/documentation";
  }

  static documentationUsingPreferrPath(): string {
    return "/documentation/using-preferr";
  }

  static documentationIdentifyingVisitorsPath(): string {
    return "/documentation/getting-started";
  }

  static documentationConversionsPath(): string {
    return "/documentation/conversions";
  }

  static documentationDosDontsPath(): string {
    return "/documentation/dos-and-donts";
  }
}
