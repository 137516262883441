import { useContext } from "react";
import { SessionStoreContext } from "src/stores/SessionStore";

export default function useSession() {
  const { loggedIn, logIn, logOut, userEmail, userAuthToken } = useContext(
    SessionStoreContext
  );

  return {
    loggedIn,
    logIn,
    logOut,
    userEmail,
    userAuthToken
  };
}
