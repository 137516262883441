import React, { Fragment, useContext } from "react";
import { useHistory } from "react-router-dom";
import Alert from "src/components/Alert";
import Loader from "src/components/Loader";
import Modal from "src/components/Modal";
import Notification from "src/components/Notification";
import TopBar from "src/components/TopBar";
import { AuthContext } from "src/contexts/AuthContext";
import { useNotification } from "src/contexts/NotificationContext";
import Url from "src/utils/Url";
import PaymentSetupModal from "../PaymentSetup/PaymentSetupModal";

interface IProps {
  children: JSX.Element[] | JSX.Element | string | Element[];
  pageTitle?: string;
}

export default function Layout({ children, pageTitle }: IProps) {
  const history = useHistory();
  const { currentUser, currentOrganization } = useContext(AuthContext);
  const { currentNotification, notificationOpen } = useNotification();

  console.log({ currentOrganization, currentUser });

  if (!currentOrganization) {
    return <Loader fullScreen />;
  }

  const outOfTrialWithoutBilling =
    currentOrganization.outOfTrial && !currentOrganization.billingSetup;
  const inTrial =
    !currentOrganization.outOfTrial && !currentOrganization.billingSetup;

  return (
    <Fragment>
      <div className="h-screen bg-gray-50">
        <TopBar />
        {inTrial && (
          <Alert
            version="info"
            title={`There are ${currentOrganization.trialDaysRemaining} days left in your free trial`}
            action={{
              content: "Set up payment",
              onAction: () => history.push(Url.paymentSetupPath())
            }}
          />
        )}
        {outOfTrialWithoutBilling && (
          <Alert
            version="error"
            title={`Your trial has ended!`}
            message="Your tests will not record data until payment is set up."
            action={{
              content: "Set up payment",
              onAction: () => history.push(Url.paymentSetupPath())
            }}
          />
        )}
        {pageTitle && (
          <header className="bg-white shadow">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <h1 className="text-2xl font-semibold text-gray-900">
                {pageTitle}
              </h1>
            </div>
          </header>
        )}
        <main className="px-4 bg-gray-50 md:px-0">
          <div className="py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
            {/* <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8" /> */}
            <div className="py-4">{children}</div>
          </div>
        </main>
      </div>
      <Modal open={outOfTrialWithoutBilling} onClose={() => null} width={400}>
        <PaymentSetupModal onSuccess={() => history.push(Url.abTestsPath())} />
      </Modal>
      <Notification
        // open={currentNotification?.open}
        open={notificationOpen}
        version={currentNotification?.version}
        title={currentNotification?.title}
        message={currentNotification?.message}
        action={currentNotification?.action}
        duration={currentNotification?.duration}
      />
    </Fragment>
  );
}
