import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

interface IProps {
  title: string;
  linkTo: string;
  className?: string;
}

export default function TopBarLink({ title, linkTo, className }: IProps) {
  const { pathname } = useLocation();

  const active = linkTo !== "/" && pathname.includes(linkTo);

  const activeClassNames = active
    ? "text-blue-800 bg-blue-100 focus:outline-none"
    : "text-gray-700 hover:text-blue-900 hover:bg-blue-100 focus:outline-none focus:text-blue-600";

  return (
    <Link
      to={linkTo}
      className={`${activeClassNames} group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded-md transition ease-in-out duration-150 ${className}`}
    >
      {title}
    </Link>
  );
}
