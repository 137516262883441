import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AnimatePresence, motion } from "framer-motion";
import React, { Fragment, useState } from "react";
import Well from "src/components/Well";
import useCurrentOrganization from "src/hooks/useCurrentOrganization";
import PaymentSetupForm from "../Billing/PaymentSetupForm";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

interface IProps {
  onSuccess: () => void;
}

export default function PaymentSetupModal({ onSuccess }: IProps) {
  const [showActiveExplainer, setShowActiveExplainer] = useState(false);
  const { currentOrganization } = useCurrentOrganization();
  const outOfTrialWithoutBilling =
    currentOrganization?.outOfTrial && !currentOrganization?.billingSetup;

  return (
    <Fragment>
      <div className="flex flex-col items-center justify-center">
        {outOfTrialWithoutBilling ? (
          <Fragment>
            <div className="inline-flex px-4 py-1 mb-2 text-sm font-semibold leading-5 tracking-wide text-red-600 uppercase bg-red-100 rounded-full">
              Your trial has ended!
            </div>
            <div className="flex items-center justify-center w-full text-sm text-center text-gray-600">
              Your tests will not record data until payment is set up.
            </div>
          </Fragment>
        ) : (
          <div className="text-2xl text-gray-500">Preferr Subscription</div>
        )}
      </div>
      <div className="flex items-center justify-around mt-8 mb-2">
        <Well className={`border-blue-200 border-solid border-2 `}>
          <div className="flex items-baseline justify-center text-2xl font-extrabold leading-none text-gray-700">
            $49
            <span className="ml-1 text-2xl font-medium leading-8 text-gray-500">
              /mo
            </span>
          </div>
          <p className="mt-5 text-base leading-7 text-gray-500">
            + $1 per active A/B test
          </p>
        </Well>
      </div>
      <div className="mb-8">
        <p
          className="mb-2 text-sm leading-5 text-center text-gray-500 underline cursor-pointer ont-medium"
          onClick={() => setShowActiveExplainer(!showActiveExplainer)}
        >
          What is an active A/B test?
        </p>
        <AnimatePresence>
          {showActiveExplainer && (
            <motion.div
              className="max-w-lg"
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
            >
              <p className="text-sm text-gray-700">
                An active A/B test is any test that has seen at least one
                visitor during the current billing period.
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <Elements stripe={stripePromise}>
        <PaymentSetupForm onSuccess={onSuccess} />
      </Elements>
    </Fragment>
  );
}
