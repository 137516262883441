import React, { Fragment } from "react";
import CodeSnippet from "src/components/CodeSnippet";
import { Link } from "react-router-dom";
import { IconChevronRight } from "src/components/Icons/IconChevronRight";
import { IconChevronLeft } from "src/components/Icons/IconChevronLeft";

export default function UsingPreferr() {
  return (
    <Fragment>
      <h4 className="text-3xl mb-8">Using Preferr</h4>
      <div className="mb-10">
        <div className="text-xl mb-4">Create an A/B test</div>
        <p className="mb-4">
          If you haven't already, click A/B Tests above and then click "New A/B
          Test". You'll give the test a name, save it, and then see a code
          snippet that looks like this:
        </p>
        <CodeSnippet
          language="javascript"
          code={`<ABTest\n  abTestId="abt_e8ed892b652c835bb785988aa4464fbf"\n  variantAId="abv_323823556cd3a6453f8e54c18d65f41c"\n  variantBId="abv_9d407d4c470ced71f53677ec89732c1c"\n>\n  **place two child elements here**\n</ABTest>`}
          className="mb-4"
        />
        <p className="mb-2">
          Copy the code generated for your test and paste it into your project
          and place two children inside the ABTest component.
        </p>
      </div>
      <div className="flex justify-between items-center">
        <Link
          to="/documentation/getting-started"
          className="bg-blue-100 py-2 px-4 rounded-lg"
        >
          <div className="flex items-center text-blue-900">
            <IconChevronLeft className="mr-2" />
            Getting started
          </div>
        </Link>
        <Link
          to="/documentation/identifying-visitors"
          className="bg-blue-100 py-2 px-4 rounded-lg"
        >
          <div className="flex items-center text-blue-900">
            Identifying visitors
            <IconChevronRight className="ml-2" />
          </div>
        </Link>
      </div>
    </Fragment>
  );
}
