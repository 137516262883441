import React, { createContext, ReactNode, useState, useEffect } from "react";
import { TNotification } from "src/definitions/TNotification";

export interface INotificationState {
  currentNotification: TNotification | undefined;
  setCurrentNotification: (n: TNotification) => void;
  notificationOpen: boolean;
  setNotificationOpen: (open: boolean) => void;
}

export const NotificationContext = createContext<INotificationState>({
  currentNotification: undefined,
  setCurrentNotification: () => undefined,
  notificationOpen: false,
  setNotificationOpen: () => {}
});

interface IProps {
  children: ReactNode;
}

export function NotificationProvider({ children }: IProps) {
  const [currentNotification, setCurrentNotification] = useState<
    TNotification | undefined
  >(undefined);
  const [isOpen, setIsOpen] = useState(false);

  const context: INotificationState = {
    currentNotification: currentNotification,
    setCurrentNotification: (n: TNotification) => setCurrentNotification(n),
    notificationOpen: isOpen,
    setNotificationOpen: (open: boolean) => setIsOpen(open)
  };

  let duration = currentNotification?.duration || 5000;

  useEffect(() => {
    if (currentNotification?.open) {
      // Allow the notification to stay open for the duration assigned or 5 seconds, then close it
      setTimeout(() => {
        setIsOpen(false);
      }, duration);
      // One second after closing, set the currentNotification to undefined so that it's empty
      setTimeout(() => {
        setCurrentNotification(undefined);
      }, duration + 1000);
    }
  }, [currentNotification, duration]);

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
}

export function useNotification() {
  return React.useContext(NotificationContext);
}
