import React from "react";
import TopBar from "src/components/TopBar";

interface IProps {
  children: JSX.Element[] | JSX.Element | string | Element[];
  pageTitle?: string;
}

export default function DocumentationLayout({ children, pageTitle }: IProps) {
  return (
    <div className="h-screen bg-gray-50">
      <TopBar docsOnly={true} />
      {pageTitle && (
        <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              {pageTitle}
            </h1>
          </div>
        </header>
      )}
      <main className="bg-gray-50 px-4 md:px-0">
        <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
          <div className="py-4">{children}</div>
        </div>
      </main>
    </div>
  );
}
