import { host, protocol } from "src/config";

export interface IAuthenticatedUser {
  email: string;
  authentication_token: string;
}

export interface IAuthenticateResponse {
  user?: IAuthenticatedUser;
  errors?: string[];
}

export async function authenticate(email: string, password: string) {
  const url = `${protocol}://${host}/api/sessions`;

  const response: IAuthenticateResponse = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    headers: {
      "Content-type": "application/json"
    }
  }).then((r) => r.json());

  console.log({ response });

  return response;
}
